.ap-button-secondary.ap-button-casual {
  --btn-color: var(--ap-color-green2);
  --btn-hover: var(--ap-color-green0);
  --btn-disabled: var(--ap-color-grey5);
  --btn-active: var(--ap-color-lightGreen3);
  color: var(--btn-color);
  border: 1px solid var(--btn-color);
  text-transform: none;
  padding: 0 12px;
}

.ap-button-secondary.ap-button-casual > span {
  align-items: center;
}

svg.ap-button-casual__icon {
  margin-right: 5px;
}

/* we need to set color to all icons */
.ap-button-secondary.ap-button-casual > span > svg {
  fill: var(--btn-color);
}

.ap-button-secondary.ap-button-casual:disabled > span > svg {
  fill: var(--btn-disabled);
}

.ap-button-secondary.ap-button-casual:disabled {
  color: var(--btn-disabled);
  border: 1px solid var(--btn-disabled);
}

.ap-button-secondary.ap-button-casual > span:last-of-type {
  flex-grow: 1;
}

/* overide ap-button-secondary default color */
.ap-button-secondary.ap-button-casual:hover:enabled {
  background-color: var(--btn-hover);
}
.ap-button-secondary.ap-button-casual:active:enabled {
  background-color: var(--btn-active);
}
