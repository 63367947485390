.save-popup {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.1);
  transition: transform 300ms ease-in-out 100ms;
  isolation: isolate;
  z-index: 1;
}

.save-popup__inner-wrapper {
  display: flex;
  column-gap: 31px;
  justify-content: center;
  width: clamp(1000px, 100%, 1240px);
}

.save-popup__input {
  width: 40%;
  min-width: 25em;
}
.save-popup__input input::placeholder {
  font-size: 13px;
}
.save-popup__checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1em;
  flex-wrap: wrap;
}
.save-popup__btn-container {
  display: flex;
  align-items: center;
}
.save-popup__save-btn span {
  display: flex;
  gap: 6px;
  align-items: center;
}

button.ap-button-main.save-popup__save-btn > span {
  text-transform: none;
}

.save-popup[data-open='false'] {
  transform: translateY(-200px);
  transform: translateY(100%);
}

.save-popup[data-open='true'] {
  transform: translateY(0%);
}
.save-popup__comment.hasError input {
  border: 1px solid var(--ap-color-ui-red-alert);
}
