.fileupload-section {
  display: flex;
  flex-direction: column;
  border: 1px dashed #aaaaaa;
  border-radius: 5px;
  background: var(--ap-color-ui-white);
}

.fileupload-section__dragdrop {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  cursor: pointer;
}

.fileupload-section__caption {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.fileupload-section__dragging .fileupload-panel__dragdrop {
  background-color: var(--client-primary-brand-color);
}

.fileupload-section__uploading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  gap: 8px;
}
