.progress-meter {
    --progress-meter-height: 16px;
    width: 100%;
    height: var(--progress-meter-height);
    background-color: var(--ap-color-ui-grey-lighter);
}

.progress-meter__fill {
    width: 0;
    height: var(--progress-meter-height);
    background-color: var(--ap-color-ui-blue);
    transition: width var(--transition);
}
