.single-claim-changes-comments .warning {
  display: flex;
  align-items: center;
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
  font-weight: 500;
  color: #d97706;
  margin: 16px 0;
}

.single-claim-changes-comments .warning span {
  padding-left: 5px;
}

.single-claim-changes-comments__bar > :first-child {
  text-transform: none;
}

.single-claim-changes-comments__bar > :first-child button,
.single-claim-changes-comments__bar > :nth-child(2) select {
  text-transform: none;
  font-size: var(--ap-font-size-xs);
  height: 2.75em;
}

.single-claim-changes-comments__bar > :nth-child(2) select {
  min-width: 20ch;
}

.single-claim-changes-comments-sort {
  font-size: 1rem;
}

.single-claim-changes-comments__bar {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
}

.single-claim-changes-pagination {
  padding-block-start: 1em;
}
