.delete-data {
  width: 100%;
  background-color: var(--ap-color-ui-grey-bg);
  margin: 22px 40px;
}
.delete-data-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.delete-data__item-card {
  background-color: white;
  padding: 5px 15px;
  position: relative;
}
.delete-data__item-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--ap-color-grey3);
  padding: 20px 0;
}
.delete-data__item-title .ap-button-danger {
  margin-left: auto;
  text-transform: unset;
  color: var(--ap-color-red1);
  border: 1px solid var(--ap-color-red1);
}
.delete-data__item-title .ap-button-danger:disabled {
  opacity: 0.6;
}
.delete-data__item-title .ap-button-danger > span {
  display: flex;
  align-items: center;
}
.delete-data__item-title .ap-button-danger svg {
  fill: var(--ap-color-red1);
}
.delete-data__item-card h3 {
  margin: 0;
  font-weight: 400;
  color: #000;
  font-size: var(--ap-font-size-m);
  text-transform: capitalize;
}
.delete-data__item-card-content {
  display: flex;
}
.delete-data__item-card-content.withBorder {
  border-bottom: 1px solid var(--ap-color-grey3);
}

.delete-data__item-card article {
  display: flex;
  align-items: center;
}
article.file-name p {
  color: var(--ap-color-ui-grey-dark);
  padding-left: 10px;
  font-size: var(--ap-font-size-xs);
}
.published article.file-name p {
  color: var(--ap-color-ui-green-primary);
}
article.file-author {
  margin-left: auto;
  text-align: right;
}
article.file-author p {
  color: var(--ap-color-ui-grey-dark);
  font-size: var(--ap-font-size-xs);
}
.delete-data__item-card-content p {
  margin: 15px 0;
}
.delete-data__item-card-content p > span,
.delete-modal-item p > span {
  display: block;
  color: var(--ap-color-ui-grey-dark);
  margin-top: 3px;
}
.delete-data-modal .ap-modal-content {
  max-height: 90vh;
  overflow-y: auto;
}
.delete-data-modal h3 p {
  display: flex;
  align-items: center;
  font-size: var(--ap-font-size-xs);
  color: var(--ap-color-red1);
}
.delete-data-modal h3 {
  border-bottom: 1px solid var(--ap-color-grey3);
  padding-bottom: 15px;
  margin: 0;
}
.delete-data-modal h3 p svg {
  padding-right: 10px;
  width: 50px;
}
.delete-modal-item {
  display: flex;
  align-items: center;
}
.delete-modal-item.withBorder {
  border-bottom: 1px solid var(--ap-color-grey3);
}
.delete-data-modal .ap-modal-footer {
  text-align: right;
}

.delete-data-modal .ap-modal-footer .ap-button-danger {
  color: var(--ap-color-red1);
  border: 1px solid var(--ap-color-red1);
  margin-left: 20px;
}
