.headerinfo {
  display: flex;
  gap: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #737373;
}

.headerinfo > span:not(:last-child) {
  padding-right: 12px;
  border-right: 1px solid #333333;
}

.headerinfo__bold {
  font-weight: 700;
}

.headerinfo__link {
  color: var(--ap-color-ui-green-primary);
  cursor: pointer;
}

.headerinfo__link:hover {
  text-decoration: underline;
}

.headerinfo__loadinglink > .download-spinner {
  flex: 0;
}

.headerinfo__loadinglink > .download-spinner > div {
  width: 11px;
  height: 11px;
}

.headerinfo__loadinglink {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}
