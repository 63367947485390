.claims-section-tabs__container {
  padding: 0 12px 0 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  background: var(--ap-color-white);
}

.claims-section-tabs {
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-m);
  cursor: pointer;
}

.claims-section-tabs .ap-button-tab:before {
  box-sizing: border-box;
}

.claims-section-tabs__additional:not(:empty) {
  display: flex;
  justify-content: flex-end;
  padding-left: 20px;
}
.tabs-generating-info {
  margin-left: auto;
}
.tabs-generating-info .ap-loader-default {
  width: 20px;
  padding-right: 10px;
}
.tabs-generating-info p {
  font-size: var(--ap-font-size-xs);
}
