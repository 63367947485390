.help-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.help-page .help-page-header {
  display: flex;
  padding: 0.9rem 1.5rem;
  box-shadow: inset 0px -1px 0px #999999;
  align-items: center;
}
.help-page .help-page-header h3 {
  margin: 0;
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-m);
  line-height: 1.5;
}

.help-page > .help-page-body {
  flex-grow: 100;
}

.help-page-body {
  display: flex;
  background-color: #f2f2f2;
}

.help-content__exit-btn {
  width: 120px;
  margin-left: auto;
}
