.page-header-title {
  display: flex;
  padding: 0.9rem 1.5rem;
  box-shadow: inset 0px -1px 0px #999999;
  align-items: center;
}

.page-header-title > h3 {
  margin: 0;
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-m);
  line-height: 1.5;
}
