.ap-three-way-switch {
  display: flex;
  gap: 13px;
  cursor: pointer;
}

.ap-three-way-switch.disabled {
  cursor: not-allowed;
}

.ap-three-way-switch.yes .ap-three-way-switch__switch {
  border-color: var(--client-primary-brand-color);
  background-color: var(--client-primary-brand-color);
}

.ap-three-way-switch.unknown .ap-three-way-switch__switch {
  justify-content: center;
}
.ap-three-way-switch.yes .ap-three-way-switch__switch {
  justify-content: right;
}

.ap-three-way-switch .ap-three-way-switch__switch {
  border-radius: 10px;
  width: 28px;
  height: 14px;
  border: 2px solid var(--ap-color-ui-grey-base);
  background-color: var(--ap-color-ui-grey-base);
  display: flex;
  align-items: center;
}

.ap-three-way-switch .ap-three-way-switch__circle {
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 5px;
  margin: 2px;
}

.ap-three-way-switch .ap-three-way-switch__label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #333333;
  line-height: 18px;
}
