.disclosurelinkmenu {
  display: grid;
  color: var(--ap-color-ui-white);

  background-color: var(--ap-color-grey6);
  overflow-y: hidden;
}

.disclosurelinkmenu__panel {
  padding-block: 0.5rem;
  display: grid;
  row-gap: 0.5rem;
  background-color: var(--ap-color-grey7);
  max-width: 240px;
}

.disclosurelinkmenu__item {
  color: inherit;
  font: inherit;
  margin: 0;
  border: none;
  display: block;
  padding: calc(1.5rem - 2px) 1rem;
  font-size: var(--ap-font-size-s);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(51, 51, 51, 0.4);
  transition: background-color 100ms ease-in-out;
  z-index: 1;
  max-width: 240px;
}

.disclosurelinkmenu__item > svg {
  fill: var(--ap-color-ui-white);
  transition: fill 200ms ease-in-out;
}

.disclosurelinkmenu__subitem {
  padding: 0.5rem 1rem;
  font-size: var(--ap-font-size-xs);
  display: block;
  background-color: transparent;
  transition: background-color 100ms ease-in-out;
}

.disclosurelinkmenu__item-active {
  background-color: var(--ap-color-grey7);
}

.disclosurelinkmenu__chevron {
  transform: rotate(270deg);
}

.disclosurelinkmenu__chevron-open {
  transform: rotate(90deg);
}

.disclosurelinkmenu__item:focus > svg,
.disclosurelinkmenu__item:hover > svg,
.disclosurelinkmenu__item[data-headlessui-state='open'] > svg,
.disclosurelinkmenu__item-active > svg {
  fill: var(--ap-color-ui-white);
}

.disclosurelinkmenu__subitem-active {
  background-color: var(--ap-color-grey8);
  box-shadow: inset 0 -2px 0 var(--ap-color-ui-green-primary);
}

.disclosurelinkmenu__item:hover,
.disclosurelinkmenu__subitem:hover {
  background-color: rgba(51, 51, 51, 0.7);
}

.disclosurelinkmenu__subitem-disabled {
  color: var(--ap-color-ui-grey-dark);
}
.disclosurelinkmenu__subitem-disabled:hover {
  background-color: transparent;
}
