.help-module-page {
  display: flex;
  flex-direction: column;
  background: #e6e6e6;
  width: 100%;
}

.help-module-page__search {
  width: 80%;
}

.help-module-page__preview {
  overflow-y: auto;
  overflow-x: auto;
  padding: 0 22px;
}

.help-module-page__preview img {
  max-width: 100%;
}
