.claim-attachments__progress {
  position: relative;
  isolation: isolate;
  outline: 1px solid var(--ap-color-ui-green-light-semi);
  outline-offset: -1px;
}

.claim-attachments__progress-indicator::before {
  content: '';
  display: inline-block;
}

.claim-attachments__progress-indicator {
  /* How much to the left do we want to start with the progress. (It should cover the tetx label) */
  --offset: -80%;
  --claims-attachment-progress-percent: 0%;

  isolation: isolate;
  z-index: -1;
  position: absolute;
  display: inline-block;
  width: 100%;
  background-color: var(--ap-color-ui-green-primary);

  transform-origin: center left;
  transform: translateX(
    calc(
      var(--offset) +
        ((0% - var(--offset)) / 100 * var(--claims-attachment-progress-percent))
    )
  );
  transition: transform 1000ms linear;

  animation: 1500ms ease-in claim-attachments__loader;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  --min-opacity: 0.65;
}

.claim-attachments__hidden-icon {
  visibility: hidden;
}

.claim-attachments__progress-label {
  padding-inline-start: 0.5em;
  display: block;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: var(--ap-bg-surface1);
}

.claim-attachments__progress-indicator:hover {
  --claims-attachment-progress-percent: 100%;
  transform: translateX(
    calc(
      var(--offset) +
        ((0% - var(--offset)) / 100 * var(--claims-attachment-progress-percent))
    )
  );
}
