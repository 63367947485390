.ap-nice-select {
  position: relative;
  display: flex;
}
.ap-nice-select__select {
  padding-block: 4px;
  padding-inline: 8px 32px;
  width: 100%;
  min-width: 120px;
  border: 1px solid #999999;
  border-radius: 1px;
  background-color: transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1a1a1a;
  font-size: 16px;
  line-height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 8px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}

.ap-nice-select__arrow {
  position: absolute;
  width: 32px;
  padding-block: 4px;
  padding-inline-start: 4px;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tooltip {
  z-index: 400;
}
