.setup-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.setup-page__content-box {
  width: 100%;
  max-width: 1100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.setup-page__stepper-container {
  width: 100%;
}

.setup-page__step-wizard {
  flex: 7;
}

.setup-page__step-wizard,
.setup-page__step-wizard > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
