.single-view-fields-list {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.single-view-fields-list > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
