.exhibit-exporter-panel__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 100px);
  background: var(--ap-color-grey2);
  border-top: 1px solid var(--ap-color-grey4);
}

.exhibit-exporter-panel {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: clamp(1000px, 100%, 1240px);
}

.exhibit-exporter-panel > header {
  width: 100%;
  margin-bottom: 25px;
}

.exhibit-exporter-panel__top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.exhibit-exporter-panel__title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-transform: capitalize;
  margin-top: 10px;
  margin-right: auto;
}

.exhibit-exporter-panel__right-side {
  margin-left: auto;
  display: flex;
  gap: 24px;
  max-width: 500px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.exhibit-exporter-panel > section {
  margin-top: 36px;
  background: var(--ap-color-white);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.15);
  padding: 16px;
}
