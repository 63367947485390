.claim-resolution-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.claim-resolution-section .select-action {
  background-color: var(--ap-color-ui-white);
  font-size: var(--ap-font-size-xs);
  padding: 0.43em;
}
