.panel-box-shadowed--container {
  /* background: var(--ap-color-ui-white); */
  background: var(--ap-color-ui-grey-bg);
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.15);
  border: 16px solid var(--ap-color-ui-white);
  margin: 40px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.panel-box-shadowed--container > h3 {
  color: var(--ap-color-grey8);
  font-size: 23px;
  font-weight: var(--ap-font-weight-xl);
  margin: 0;
  /* font-weight: 700; */
}
