.sofacard {
  background: var(--ap-color-ui-white);
  border-right: 8px solid var(--ap-color-ui-green-primary);
  color: var(--ap-color-grey8);
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
}

.sofacard__step-label {
  background: var(--ap-color-ui-green-primary);
  border-radius: 8px;
}

.sofacard__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sofacard__body > p,
.sofacard__body > h3 {
  margin: 0;
}

.sofacard__body > h3 {
  font-weight: 700;
  font-size: 23px;
}
.sofacard__body > p {
  font-weight: var(--ap-font-weight-s);
  font-size: 13px;
}

.sofacard > section {
  flex: 1;
  display: flex;
  gap: 32px;
}

.sofacard > footer {
  padding-top: 24px;
}

.sofacard__step {
  min-width: 60px;
}

.sofacard__step-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: var(--ap-font-weight-m);
  font-size: 13px;
  color: var(--ap-color-ui-white);
  white-space: nowrap;
}
