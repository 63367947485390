.dynamicfields,
.dynamicfields .edit-fields .edit-table__header-row {
  --edit-table-grid-template-columns: 1fr 1fr 1fr 1fr minmax(4rem, auto);
}

.dynamicfields .edit-fields .edit-table__header:last-child {
  justify-self: center;
}

.dynamicfields .dynamicfields__cell:nth-child(3) > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamicfields .edit-fields .edit-table__cell-switch {
  justify-content: center;
}
.edit-fields .display-in-select.utils-clean-input {
  padding: 12px 10px;
  width: 80%;
}
