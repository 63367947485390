@import '../../../common.css';

.setup-wizard-steppanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.setup-wizard-helpbox {
  width: 30%;
  max-width: 431px;
  background-color: var(--client-primary-brand-color);
  color: white;
  padding: 32px;

  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-m);
}

.setup-wizard-box {
  flex-grow: 2;
  max-width: 550px;
  background-color: white;
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 16px 32px;
}

.setup-wizard-box__title {
  font-size: var(--ap-font-size-l);
  font-weight: var(--ap-font-weight-m);
}

.setup-wizard-box__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  border-top: 1px solid #ccc;
  padding-top: 16px;
}
