.settlements-agreement-section {
  padding: 22px 22px;

  display: flex;
  flex-direction: column;
  gap: 22px;
  background-color: var(--ap-color-ui-grey-bg);
}

.settlements-agreement-section__title {
  font-size: var(--ap-font-size-m);
  font-weight: var(--ap-font-weight-m);
}
