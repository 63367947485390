.input-with-label {
  background: #ffffff;
  display: flex;
  padding: 8px;
  align-items: center;
}

.input-with-label__label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  width: 25ch;
  margin-right: 20px;
}
.input-with-label__input {
  flex: 1;
  position: relative;
}
