.global-notes-selection-panel__inner-panel {
  min-height: unset;
}

.global-notes-selection-panel__inner-panel .claim-attachments__disappear {
  display: none;
}

.global-notes-selection-panel__inner-panel .claim-attachments__progress {
  outline-offset: -2px;
}

.global-notes-add-strip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.global-notes-current-doc-indicator {
  font-size: var(--ap-font-size-s);
}

.global-notes-current-doc-indicator__filename {
  font-weight: var(--ap-font-weight-l);
}

.action-list-loading
  .action-list-item
  .inputcheckbox__input:checked
  + label
  > .checkbox,
.action-list-loading .action-list-item .inputcheckbox__input + label > .emptybox {
  color: transparent;
}

.action-list-item .inputcheckbox .box {
  padding-inline: 0 0.25rem;
  border-inline-end: 1px solid var(--ap-color-grey2);
}

.action-list-item .inputcheckbox__input:checked + label > .checkbox {
  color: var(--ap-color-ui-green-primary);
}
