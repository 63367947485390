.scp-section {
  background: #f2f2f2;
  display: flex;
  flex-flow: column;
  padding: 16px;
}
.scp-section__title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 16px;
}

.scp-section__title,
.scp-section__content {
  padding-left: 0;
  padding-right: 0;
}
