.datepicker__input {
  border: none;
  outline: none;
  appearance: none;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 1px;
  visibility: hidden;
}

.datepicker {
  position: relative;
  width: 100%;
}

.datepicker-disabled {
  outline-color: transparent;
}

.datepicker-disabled:hover {
  outline-color: transparent;
  cursor: default;
}
