.settlements-user-management__loader {
  min-height: 15em;
}

.settlements-user-management__batch-upload {
  margin-bottom: 1.5rem;
}

.settlements-user-management__button-reset {
  flex: 1;
  display: flex;
  justify-content: center;
}

.settlements-user-management__grid {
  --edit-table-grid-template-columns: 3fr 3fr 3fr 1.2fr 64px;
}

.settlements-user-management__input-panel {
  --edit-table-grid-template-columns: 1fr 1fr 1fr 150px;
}
