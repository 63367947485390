.changes-comments-list {
  display: grid;
  grid-template-columns: 10fr 7fr;
  min-height: 34em;
  align-content: flex-start;
}

.changes-comments-list .simple-table > .simple-table-header {
  color: var(--ap-color-ui-grey-dark);
  padding-inline-start: 0;
  padding-block-end: 0.25rem;
  padding-block-end: 0;
}

.changes-comments-list .simple-table > * {
  display: flex;
  align-items: center;
  padding-block: 0.8em;
}

.changes-comments-list-empty {
  grid-template-columns: 1fr;
  align-content: stretch;
}

.changes-comments__changes.simple-table {
  grid-template-columns: 3fr 7fr;
  column-gap: 16px;
  padding-inline-end: 8px;
}

.changes-comments__comm-area.simple-table {
  grid-template-columns: 7fr;
  padding-inline-start: 8px;
  grid-template-rows: auto auto 1fr;
}

.changes-comments-list .simple-table {
  --simple-table-font-size: var(--ap-font-size-xxs);
  padding-block-start: 0.5rem;
}

.changes-comments-list .simple-table .special-row {
  text-align: end;
  background-color: transparent;
  align-items: flex-end;
  padding-inline-end: 0;
}

.simple-table .changes-comments__comm-label {
  padding-inline-end: 1em;
}

.simple-table .changes-comments-file-link {
  justify-content: flex-end;
}

.changes_comments__field-label {
  display: flex;
  justify-content: space-between;
}

.simple-table .changes-comments__card_footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0;
}
