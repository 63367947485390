.matching-toggle {
  padding: 0 32px 18px 32px;
}
.matching-toggle .ap-three-way-switch {
  margin-left: auto;
  align-items: center;
}
.matching-toggle .ap-three-way-switch .ap-three-way-switch__label {
  color: var(--ap-color-ui-grey-base);
  height: 20px;
}
.matching-toggle .ap-three-way-switch.yes .ap-three-way-switch__label {
  color: var(--ap-color-ui-green-primary);
}
.matching-toggle p {
  font-size: var(--ap-font-size-xs);
}
.matching-toggle__header p {
  font-weight: 400;
}

.matching-toggle .ap-three-way-switch__switch {
  width: 36px;
  height: 21px;
  border-radius: 100px;
}

.matching-toggle .ap-three-way-switch__label {
  line-height: 16px;
}