.upload-error-modal .ap-modal-content {
  padding: 20px 15px;
}
.upload-error-modal .ap-modal-header {
  border: none;
  padding: 0;
}
.upload-error-modal .ap-modal-header h4 {
  font-size: var(--ap-font-size-l);
}
.upload-error-modal .upload-error-modal__title {
  display: flex;
  align-items: center;
  color: #d97706;
}
.upload-error-modal .upload-error-modal__title svg {
  margin-right: 10px;
}
.upload-error-modal .ap-modal-footer {
  display: flex;
  align-items: center;
  border: none;
}
.upload-error-modal .ap-modal-footer .ap-button-group {
  margin-left: auto;
}
.upload-error-modal .ap-modal-footer .ap-button-group button {
  display: flex;
  align-items: center;
}
.upload-error-modal .ap-modal-footer .ap-button-group button span {
  display: flex;
  align-items: center;
}
