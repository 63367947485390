.popupDisplayed .single-claim__sections-container {
  margin-bottom: 7rem;
}

.c11-footer.utils-none {
  display: none;
}

.single-claim__sections-container {
  margin-block: 2.25rem 4.5rem;
  background: #ffffff;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.15);
  padding-left: 16px;
  padding: 0 16px 16px 16px;
}

.single-claim__loader {
  display: flex;
  flex-grow: 1;
}
.single-claim__not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
