.sofas-home__container {
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
}

.sofas-home__diagram {
  margin: 0 0 64px 0;
}

.sofas-home__header {
  padding: 0 0 40px 0;
  border-bottom: 1px solid white;
  gap: 8px;
}

.sofas-home__header > h3 {
  font-size: 33px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  margin: 0;
}

.sofas-home__header > h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0 0 0;
}

.sofas-home__body {
  padding: 40px 0;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: auto auto;
}
