.c11-buttonicon {
  border: 1px solid transparent;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
}

.c11-buttonicon:hover {
  background-color: rgba(72, 137, 41, 0.1);
}

.c11-buttonicon:focus {
  background-color: rgba(72, 137, 41, 0.2);
  outline: none;
}

.c11-buttonicon:active {
  background-color: rgba(51, 98, 29, 0.4);
}

.c11-buttoncolorpicker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.c11-buttoncolorpicker > input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: inherit;
  z-index: -10;
}

/* use button to increase priority class property */
button.ap-button-thin {
  height: 32px;
  padding: 0 12px;
}

button.ap-button-main-ex {
  text-transform: none;
  padding: 0 16px;
}
.c11-buttonicon:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
