.ap-modal.add-exhibit-item-modal .ap-modal-content {
  max-width: 640px;
}
.add-exhibit-item-modal .ap-modal-body {
  padding: 0;
}

.add-exhibit-item-modal .ap-modal-header {
  padding: 22px 32px;
  font-size: 23px;
  border-bottom: 0;
}

.add-exhibit-item-modal .ap-modal-header h4 {
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
}

.add-exhibit-item-popup__header-title {
  font-size: 23px;
}

.add-exhibit-item-popup__content {
  padding-left: 32px;
  padding-right: 32px;
}

.add-exhibit-item-popup__content .search-input {
  margin-bottom: 20px;
}

.add-exhibit-item-popup__content .exhibit-search-result {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #1a1a1a;
  display: none;
  max-height: 350px;
  overflow-y: auto;
}
.result-title p {
  margin: 0 0 20px;
}
.add-exhibit-item-popup__content .exhibit-search-result .result-row {
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 700;
  gap: 50px;
}
.add-exhibit-item-popup__content .exhibit-search-result .result-row > div {
  display: flex;
  align-items: center;
  min-width: 115px;
}
.add-exhibit-item-popup__content
  .exhibit-search-result
  .result-row
  > div:last-child {
  margin-left: auto;
  min-width: auto;
}

.add-exhibit-item-popup__content .exhibit-search-result.show {
  display: block;
}

.add-exhibit-item-popup__footer {
  padding: 8px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
  margin-top: 20px;
  gap: 20px;
}

.add-exhibit-item-popup__footer_button {
  font-family: 'Roboto', sans-serif;
  text-transform: initial;
  height: 32px;
}
.add-exhibit-item-popup__footer .exhibit-claim-button {
  margin: 0;
}

.add-exhibit-item-popup__content .select-exhibit-item-checkbox {
  margin-right: 10px;
}
