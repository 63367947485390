.bookmarks-page__filter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1300px;
  margin: 20px auto;
}
.bookmarks-page__filter .ap-input-select {
  width: 220px;
  margin-left: auto;
}
