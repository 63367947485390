.counterparty-page {
  --_counterparty-name--cellwidth: calc(2 * 1em + 19ch);
}

.counterparty-page .simple-table {
  padding-block-end: 3rem;
}

.simple-table.filed-claims-tally-table {
  grid-template-columns: 25% repeat(5, 2fr);
}

.simple-table.filed-claims-tally-table > * {
  text-align: end;
}

.simple-table.filed-claims-tally-table > *:nth-child(6n + 1) {
  text-align: start;
}

.simple-table.scheduled-claims-tally-table {
  grid-template-columns: 25% repeat(5, 2fr);
}

.simple-table.scheduled-claims-tally-table > * {
  text-align: end;
}

.simple-table.scheduled-claims-tally-table > *:nth-child(6n + 1) {
  text-align: start;
}

.simple-table span {
  display: block;
}

.simple-table.filed-claims-table-1 {
  grid-template-columns:
    auto var(--_counterparty-name--cellwidth) 1fr min-content repeat(2, 1fr)
    1fr 1fr 1fr;
}

.simple-table.filed-claims-table-2 {
  grid-template-columns: auto var(--_counterparty-name--cellwidth) 1fr 1fr min-content repeat(
      3,
      1fr
    );
}

.simple-table.scheduled-claims-table {
  grid-template-columns: auto var(--_counterparty-name--cellwidth) repeat(10, 1fr);
}

.filed-claims-full-table__end-padder,
.filed-claims-full-table__start-padder {
  margin-block-start: 1rem;
  padding-inline: var(--simple-table--cell-padding-inline);
  padding-inline-start: 0;
  background-color: var(--simple-table-bg);
  position: sticky;
  top: 0;
}

.filed-claims-full-table__end-padder {
  left: 100%;
}

.filed-claims-full-table__start-padder {
  right: 100%;
}

.simple-table.filed-claims-tally-table > *:nth-child(6n),
.simple-table.scheduled-claims-tally-table > *:nth-child(6n),
.simple-table.filed-claims-table-2 > *:nth-child(8n + 1),
.simple-table.scheduled-claims-table > *:nth-child(12n + 1) {
  text-align: end;
}

.simple-table.filed-claims-table-1 > *:nth-child(9n - 3),
.simple-table.filed-claims-table-1 > *:nth-child(9n - 2) {
  text-align: end;
}

.simple-table.filed-claims-table-2 > *:nth-child(8n),
.simple-table.filed-claims-table-2 > *:nth-child(8n - 1) {
  text-align: end;
}

.simple-table.filed-claims-table-2 > :first-child,
.simple-table.scheduled-claims-table > :first-child {
  text-align: start;
}

.filedclaimstable__tabgroup {
  margin-block-start: var(--simple-table-margin-block-start);
}

.filedclaimstable__swapper {
  position: relative;
  isolation: isolate;
  display: grid;
}

.filedclaimstable__table {
  margin-block-start: 2px;
  outline-offset: 3px;
  top: 0;

  grid-column: 1;
  grid-row: 1;
}

.filedclaimstable__table-showing {
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  display: grid;
  position: relative;
}

.general-page__back-button {
  margin: 0 0 16px auto;
  text-transform: initial;
}

.general-page__back-button__icon {
  margin-right: 8px;
  transform: scale(-1, 1);
}

.claims-full-table {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0 3rem;
  background-color: var(--simple-table-bg, grey);
  margin-top: 1rem;
}

.claims-full-table .simple-table-title {
  position: sticky;
  left: 0;
}

.claims-full-table__container,
.claims-full-table__columns,
.claims-full-table__column {
  display: flex;
}

.thin-scrollbar {
  overflow-x: scroll;
}

.claims-full-table__column__cell {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.claims-full-table__column__cell.empty {
  min-width: 1rem;
}

.claims-full-table__column__cell__value {
  font-size: var(--ap-font-size-xs);
  padding: 1rem;
  background-color: white;
  flex-grow: 1;
}

.claims-full-table__column .bold-title {
  font-weight: var(--ap-font-weight-s);
}

.claims-full-table__column__cell__value a {
  color: var(--ap-color-ui-green-primary);
}

.claims-full-table__column__cell__header {
  font-weight: var(--ap-font-weight-m);
  font-size: var(--simple-table-header-fs, 11px);
  padding: 0.55rem 1rem;
  height: 1.625rem;
  min-width: 5.125rem;
  text-transform: capitalize;
}

.claims-full-table__columns {
  display: flex;
  flex-grow: 1;
}
