.warning {
  display: flex;
  align-items: center;
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
  font-weight: 500;
  color: #d97706;
  margin: 15px 0;
}
.warning span {
  padding-left: 5px;
}
