.claims-settlement-preview {
  --document-width: 800px;
  --document-height: 1035px;

  display: flex;
  flex-direction: column;
  gap: 1em;
}

.claims-settlement-preview__name {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-l);
}

.claims-settlement-preview__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.claims-settlement-preview__nav-space {
  flex: 1;
}

.claims-settlement-preview-preview {
  --preview-height: calc(var(--document-height) * 1.5);
  min-height: var(--document-height);
  max-height: var(--preview-height);
  animation: showPreview 0.1s;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.claims-settlement-preview-preview div.react-pdf__Page {
  margin-bottom: 1rem;
}

@keyframes showPreview {
  from {
    min-height: 0;
  }
  to {
    min-height: var(--document-height);
  }
}
