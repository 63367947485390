.multiselect--container {
  width: 100%;
  height: fit-content;
  border: 1px solid var(--ap-color-ui-grey-base);
  height: fit-content;
  position: relative;
  min-width: 6rem;
}

.multiselect--container:active {
  border-color: var(--ap-color-green3);
}

.multiselect--container-shadow {
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 25%);
}

.multiselect--container :active {
  border-color: var(--ap-color-green3);
}

.multiselect--container :focus,
.multiselect--container :hover {
  border-color: var(--ap-color-green2);
}

.multiselect--button {
  width: 100%;
  height: 38px;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: var(--ap-color-grey8);
  border: none;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ap-color-ui-white);
  outline: none;
  cursor: pointer;
}

.multiselect--title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding: 8px 16px;
  color: var(--grey5);
}

.multiselect--search {
  margin: 8px 16px;
}

/* workaround. Component name is added to the class to increase rule priority */
div.multiselect--menu {
  padding-top: 8px;
  min-width: 250px;
  max-width: none;
  background-color: var(--ap-color-white);
  max-height: 40vh;
  padding-bottom: 0;
}

/* workaround. Component name is added to the  class to increase rule priority */
button.multiselect--menu-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  min-height: 40px;
  padding-right: 16px;
}

button.multiselect--menu-item :first-child {
  pointer-events: none; /* workaround to prevent hint text*/
  margin-top: 0;
}
button.multiselect--menu-item > * {
  min-width: 29px;
}

button.multiselect--menu-item :hover {
  background-color: rgba(var(--ap-color-green2), 0.1);
}

button.multiselect--menu-item :focus {
  background-color: rgba(var(--ap-color-green2), 0.2);
}

button.multiselect--menu-item :active {
  background-color: rgba(var(--ap-color-green2), 0.3);
}

.multiselect--text {
  display: inline-block;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.3px;
  padding: 2px 0 0 4px;
  resize: none;
}

.multiselect--disabled {
  pointer-events: none;
  background-color: none;
  color: var(--ap-color-grey5);
  cursor: default;
}

#id-multiselect-search-filed {
  box-shadow: none;
}

.multiselect--icon-rotate {
  transform: rotate(180deg);
}

.multiselect--footer {
  background: var(--ap-color-ui-white);
  position: sticky;
  bottom: 0;
  padding: 16px;
  border-top: solid 1px var(--ap-color-grey3);
  display: flex;
  justify-content: flex-end;
}
.multiselect--container .hover-data {
  position: absolute;
  top: 3px;
  z-index: 999;
  background-color: white;
  font-size: var(--ap-font-size-xxs);
  line-height: 18px;
  padding: 12px;
  box-shadow: 0px 4px 50px -12px rgba(0, 0, 0, 0.35);
  width: 100%;
  max-width: 400px;
}
