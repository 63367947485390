.table-sub-header {
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2px var(--ap-items-spacing) 2px;
  height: 47px;
  flex-shrink: 0;
  border-bottom: 1px solid #999;
  position: relative;
}
.table-sub-header-text {
  margin-right: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #737373;
  line-height: 18px;
}
.table-sub-header__schedule-select {
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.table-sub-header__buttons {
  display: flex;
  gap: 1rem;
  flex: 1;
}

.reset-filters-button {
  margin-left: 16px;
}

.table-sub-header__buttons > button {
  text-transform: unset;
}

/** Resize drop downs */
#select-select-part,
#select-select-schedule {
  height: 36px;
}
