.cases-entities .dynamicfields__row-el,
.cases-entities .edit-table__header-row {
  grid-template-columns: 5fr 5fr 2fr minmax(6rem, auto);
}
.cases-entities_text {
  max-height: 90vh;
  overflow-y: auto;
}
.cases-entities_text .dynamicfields__row-el {
  background-color: #fff;
}
.cases-entities_text .dynamicfields__row-el,
.cases-entities_text .edit-table__header-row {
  padding-inline: var(--cell-body-pad-inline);
  grid-template-columns: 8fr 3fr 3fr;
}
.cases-entities_text .edit-table__header {
  padding: 0;
}
.cases-entities .cases-entities_text .edit-table__header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.cases-entities .edit-table__header-row svg {
  width: 12px;
  margin-left: 8px;
}
.cases-entities__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.cases-entities-project-select {
  margin: 0;
}
.cases-entities-header__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  background-color: #fff;
  align-items: center;
}
.cases-entities__select, .cases-entities__list {
  width: 84%;
}
.cases-entities__select {
  margin-bottom: 0;
}
.cases-entities-header__container .edit-table__title {
  margin: 0;
}