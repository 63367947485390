.globalnote-listitem .action-list-item {
  grid-template-columns: auto 1fr auto;
  grid-template-columns: auto 1fr 1fr auto;
  padding-block: 0.5rem;
}

.globalnote-listitem__expand_btn {
  margin-inline: 0.25rem;
}

.globalnote-listitem__expand_btn svg {
  width: 18px;
  transform: rotate(-270deg);
  fill: currentColor;
  color: var(--ap-color-ui-green-primary);
  transition: transform 200ms ease-in-out;
}

[data-expanded='false'] .globalnote-listitem__expand_btn svg {
  transform: rotate(-90deg);
}
