.dashboard-detailed {
  min-height: calc(100vh - 214px);
}
.dashboard-detailed .dashboard-detailed__nav-buttons {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.dashboard-detailed ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
}
.dashboard-detailed ul li {
  display: flex;
  align-items: center;
}
.dashboard-detailed ul li .separator {
  margin: 0 15px;
  border-left: 1px solid var(--ap-color-ui-grey-bg);
  height: 32px;
}
.dashboard-detailed .dashboard-detailed__nav-buttons .ap-button-secondary {
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
  font-weight: 500;
  height: 32px;
  color: #fff;
  background-color: transparent;
  border: 1px solid var(--ap-color-ui-grey-bg);
  padding: 0 12px;
  text-transform: unset;
  transition: all 0.3s ease-in-out;
}
.dashboard-detailed__data {
  margin: 30px 0;
}
.dashboard-detailed__data .grid-col {
  display: grid;
}
.gridCol5 {
  grid-template-columns: 4fr 1fr 1.5fr 1.5fr 1.5fr;
}
.gridCol4 {
  grid-template-columns: 4fr 1fr 1fr 2fr;
}
.gridCol3 {
  grid-template-columns: 5fr 1fr 1.5fr;
}
.dashboard-detailed .dashboard-detailed__nav-buttons .ap-button-secondary:disabled,
.dashboard-detailed .dashboard-detailed__nav-buttons .ap-button-secondary:hover {
  background-color: #fff;
  color: #000;
}
.detailed-item {
  padding: 15px 32px;
  color: #fff;
}
.detailed-item.firstItem {
  background-color: var(--ap-color-ui-green-primary);
}
.detailed-item.firstItem p {
  text-transform: uppercase;
}
.detailed-item p {
  font-weight: 500;
  font-size: var(--ap-font-size-m);
  line-height: var(--ap-font-size-xl);
  margin: 0;
  text-align: center;
}
.detailed-item p span {
  display: block;
  font-size: var(--ap-font-size-s);
  line-height: var(--ap-font-size-s);
  font-weight: 400;
  margin: 0 0 7px;
  text-transform: capitalize;
}
.detailed-item.detailed-item__secondary {
  background-color: rgba(13, 13, 13, 0.5);
  border-bottom: 1px solid #e6e6e6;
}
.detailed-item.detailed-item__secondary:last-child {
  border: none;
}
.detailed-item:not(.firstItem) p.resolution-type {
  margin-top: 5px;
  margin-bottom: 5px;
}
.detailed-item p.resolution-type {
  text-align: left;
}
.detailed-item__secondary-subitems p {
  font-size: var(--ap-font-size-s);
}
.detailed-item__secondary-subitems p.resolution-type {
  margin-left: 20px;
}
