.related-claims {
  padding: 20px 16px;
}
.related-claims.scp-section h1 {
  margin: 0 0 16px;
  font-weight: 500;
  font-size: 1.44em;
}
.related-claims-headers,
.related-claims-content-item {
  display: grid;
  grid-template-columns: repeat(2, 1.2fr) 1.5fr 2fr 1fr 2fr 1fr;
  margin: 5px 0 15px;
  text-transform: capitalize;
  align-items: center;
}
.related-claims-content-item {
  padding: 10px 0;
  margin: 10px 0;
  background-color: white;
}
.related-claims-content-item * {
  white-space: pre-wrap;
}
.related-claims-content {
  max-height: 70vh;
  overflow-y: auto;
}
.related-claims h4,
.related-claims h5 {
  margin: 10px 0;
  font-weight: 400;
  font-size: 11px;
}
.related-claims-headers h5 {
  padding: 0 10px;
  margin: 0;
}
.related-claims-content-item p {
  font-size: var(--ap-font-size-xs);
  margin: 0;
  padding: 0 10px;
}
.related-claims-content .related-claims-content-item .ap-button-link {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  font-weight: 500;
  color: var(--Greyscale-Grey-700);
  font-size: var(--ap-font-size-xs);
  padding: 0 10px;
}
.related-claims-content .related-claims-content-item .ap-button-link:hover {
  background-color: transparent;
  text-decoration: underline;
}
.related-claims-content-item:last-child {
  margin-bottom: 0;
}
