.checkbox-option-item {
  display: flex;
}

.checkbox-option-item__checkbox {
  margin-right: 12px;
}

.checkbox-option-item__label {
  display: flex;
  align-items: center;
}
