.settlement-agreement-help {
  display: flex;
  flex-direction: column;
}

.settlement-agreement-help .ap-link-text {
  font-weight: var(--ap-font-weight-xl);
}

.settlement-agreement-help__section {
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding: 22px 22px;
  border-bottom: 1px solid var(--ap-color-ui-grey-bg);
}

.settlement-agreement-help__body {
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
}

.settlement-agreement-help__title {
  margin: unset;
  padding-left: 22px;
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-xl);
}

.settlement-agreement-help__additional-info {
  padding: 22px 22px;
  font-size: var(--ap-font-size-s);
  line-height: var(--ap-font-size-l);
}
