.single-contract-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: var(--_single-contract-row-space);
}

.single-contract-inputs .left-column,
.single-contract-inputs .right-column {
  width: calc(50% - 12px);
  padding-inline: 0;
  display: flex;
  flex-flow: column;
  gap: 8px;
}
.single-contract-inputs .textfields {
  width: 100%;
  padding-block: 0;
  padding-inline: 0;
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.single-contract-inputs.horizontal .single-contract-overview__input-label {
  width: calc(50% - 20px);
}

.single-contract-inputs .single-contract-overview__textfield-label {
  width: 100%;
}
.single-contract-overview__textfield-label textarea {
  font-family: inherit;
}
.single-contract-inputs .single-contract-overview__textfield-label {
  align-items: flex-start;
}
.single-contract-overview__input-label + .single-contract-overview__textfield-label {
  margin-top: var(--_single-contract-row-space);
}

.single-contract-inputs .single-contract-overview__input-label {
  margin-left: 0;
  padding-block: 0;
  padding-inline: 8px 0;
  height: 32px;
}
