.survivingclaimnoselect {
  position: relative;
  font-size: var(--ap-font-size-xs);
  font-weight: 400;
}

.survivingclaimnoselect__input {
  display: flex;
  align-items: stretch;
  position: relative;
  height: 100%;
}

.scp-input.survivingclaimnoselect__textinput {
  outline-color: transparent;
  transition: outline-color 200ms ease-in-out;
  padding: 0.25rem 0.5rem;
  /* leave space for the icon */
  padding-inline-end: 2rem;
}

.scp-input.survivingclaimnoselect__textinput:hover,
.scp-input.survivingclaimnoselect__textinput:focus {
  outline: 1px solid var(--ap-color-ui-green-primary);
}

.scp-input.survivingclaimnoselect__textinput::-webkit-input-placeholder {
  color: var(--ap-color-green2);
}

.scp-input.survivingclaimnoselect__textinput:-moz-placeholder {
  color: var(--ap-color-green2);
}

.survivingclaimnoselect__options {
  margin: 0;
  position: absolute;
  padding: 0.5rem 0;
  background-color: var(--ap-bg-surface1);
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  left: -1px;
  right: -1px;
  display: grid;
  gap: 0.5rem;
  max-height: 30vh;
  overflow-y: scroll;
  z-index: 10;
}

.utils-none.survivingclaimnoselect__options {
  display: none;
}

.survivingclaimnoselect__textinput__icon {
  fill: var(--ap-color-grey5);
  position: absolute;
  top: 0;
  right: 0.35rem;
}

.survivingclaimnoselect__option {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-columns: auto 1fr auto;
  gap: 0.5rem;
  list-style: none;
  padding: 0.5rem;
}

.survivingclaimnoselect__option > span:nth-child(2) {
  text-transform: uppercase;
}

.survivingclaimnoselect__option[data-headlessui-state='active'] {
  background-color: var(--ap-color-ui-green-primary);
  color: var(--ap-color-primary-text-inverted);
}

.survivingclaimnoselect__option > span:nth-child(3) {
  justify-self: flex-end;
}
