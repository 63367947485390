.single-contracts-documents__upload {
  text-transform: none;
  padding-inline: 0.75rem;
  margin-bottom: 2rem;
}

button.single-contracts-documents__upload > span {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  place-items: center;
  text-transform: none;
}
.doc-comment p {
  margin: 0.5rem 0 1rem;
}
.doc-comment textarea {
  padding: 0.5rem;
  width: calc(100% - 1rem);
  display: block;
  border-color: var(--ap-color-grey8);
  outline: none;
  margin-bottom: 0.7rem;
  font-family: inherit;
  resize: vertical;
}
.doc-comment button.ap-button-main {
  text-transform: none;
}
.doc-comment__buttons {
  gap: 1rem;
}
.doc-comment__buttons .ap-button-secondary.ap-button-casual > span {
  display: flex;
  align-items: center;
}
.doc-comment__buttons .ap-button-secondary.ap-button-casual > span svg {
  width: 16px;
}
.doc-comment label {
  display: block;
  margin-bottom: 0.3rem;
}
