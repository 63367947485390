.multiselect--button {
  width: 100%;
  height: 38px;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: var(--ap-color-grey8);
  border: none;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 4px 9px;
  background-color: var(--ap-color-ui-white);
  outline: none;
  cursor: pointer;
}

.multiselect--icon {
  flex-shrink: 0;
}

.multiselect--title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding: 8px 16px;
  color: var(--grey5);
}

.multiselect--search {
  margin: 8px 16px;
}

/* workaround. Component name is added to the class to increase rule priority */
div.multiselect--menu {
  padding-top: 8px;
  min-width: 250px;
  max-width: none;
  background-color: var(--ap-color-white);
  max-height: 40vh;
}

/* workaround. Component name is added to the  class to increase rule priority */
button.multiselect--menu-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  min-height: 40px;
  padding-right: 16px;
}

button.multiselect--menu-item :first-child {
  margin-top: 0;
}
button.multiselect--menu-item > * {
  min-width: 18px;
}

button.multiselect--menu-item :hover {
  background-color: rgba(var(--ap-color-green2), 0.1);
}

button.multiselect--menu-item :focus {
  background-color: rgba(var(--ap-color-green2), 0.2);
}

button.multiselect--menu-item :active {
  background-color: rgba(var(--ap-color-green2), 0.3);
}

.multiselect--text {
  display: inline-block;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;

  font-size: 16px;
  letter-spacing: 0.3px;
  padding: 2px 0 0 4px;
  resize: none;
}

.multiselect--disabled {
  pointer-events: none;
  background-color: none;
  color: var(--ap-color-grey5);
  cursor: default;
}

#id-multiselect-search-filed {
  box-shadow: none;
}

.multiselect--icon-rotate {
  transform: rotate(180deg);
}
