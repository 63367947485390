.delete-modal-content {
  background-color: var(--ap-color-ui-grey1);
  padding: 16px;
}
.delete-modal-content__item {
  display: flex;
  align-items: top;
  margin: 10px 0;
  gap: 20px;
}
.delete-modal-content p {
  margin: 0;
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
}
.delete-modal-content p:first-child {
  font-size: var(--ap-font-size-xxs);
  min-width: 70px;
}
.delete-modal .ap-modal-content .ap-button-group {
  justify-content: flex-end;
}
.delete-modal .ap-modal-content .ap-button-group .ap-button-danger {
  border: 1px solid var(--ap-color-red1);
  color: var(--ap-color-red1);
}
