.dashboard__additional-panel {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

/** 
  Fixes for the ApMenu 
  TO DO: Update library so this isn't necessary 
*/
.dashboard__export-menu button div:first-child {
  width: 16px;
}
.dashboard__export-menu button div {
  font-size: 13px;
}

button.dashboard__export-dropdown.ap-button-secondary {
  min-width: 180px;
  --btn-color: white;
  background: rgba(26, 26, 26, 0.5) !important;
}

.export-menu-item {
  padding-right: 24px;
}
