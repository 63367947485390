.page-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-main-container__body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
