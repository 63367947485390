.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  position: relative;
  display: flex;
}

.select {
  flex: 1;
  width: 100%;
  height: 40px;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border: 1px solid var(--ap-color-grey4);
  border-radius: 1px;
  padding-right: 32px;
  font-size: 16px;
  line-height: 24px;
  color: var(--ap-color-grey8);
  text-indent: 8px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select:hover,
.select:focus {
  border-color: var(--ap-color-green2);
  outline: none;
}
.select:active {
  border-color: var(--ap-color-green3);
  outline: none;
}

.disabled .select {
  pointer-events: none;
  color: var(--ap-color-grey5);
  border-color: var(--ap-color-grey2);
}

.error .select {
  border: 1px solid var(--ap-color-red1);
}

.disabled .caret {
  display: none;
}

.caret {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* TODO Remove */
.container + :global(.ap-alerts) {
  margin-top: 8px;
}

.hint {
  margin-top: 4px;
  color: var(--ap-color-ui-grey-base);
  font-size: var(--ap-font-size-xxs);
  line-height: 16px;
}

.placeholder {
  display: none;
}

.label {
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
