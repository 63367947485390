.sofas-template-loading {
  flex: 1;
  background-color: var(--ap-color-ui-grey-bg);
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: stretch;
}

.sofas-template-loading__title {
  padding-top: 48px;
  padding-bottom: 180px;
  display: flex;
  justify-content: center;
}

.sofas-template-loading__loading-screen {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  height: 160px;
}
