.claims-contracts-tool {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 52px);
}
.claims-contracts-tool__dashboard {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 52px - 62px);
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ap-button-group button.ap-button-tab:disabled {
  opacity: 0.6;
}
