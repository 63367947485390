.exhibit-table {
  width: 100%;
  --ag-header-column-resize-handle-display: none;
  --ag-secondary-border-color: #5cb335;
  --ag-checkbox-checked-color: var(--client-primary-brand-color);
  --ag-checkbox-unchecked-color: none;
  --ag-selected-row-background-color: rgba(92, 179, 53, 0.3);
  --ag-checkbox-background-color: var(--ag-background-color);
  --ag-checkbox-indeterminate-color: var(--client-primary-brand-color);
  --ag-font-family: Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
  --ag-font-size: 12px;
  display: flex;
  flex-direction: column;
}

.exhibit-table .ag-header-cell {
  font-size: 11px;
  font-weight: var(--ap-font-weight-s);
}

.exhibit-table .ag-cell {
  text-transform: uppercase;
}

.exhibit-table__body {
  flex: 1;
}

.ag-exhibit-bold-font {
  font-weight: var(--ap-font-weight-m);
}

.exhibit-table .ag-checkbox-input {
  cursor: pointer;
}

.exhibit-table > header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: var(--ap-color-grey5);
  font-size: 13px;
}
