footer.c11-footer-ap,
.c11-footer {
  background-color: #1a1a1a;
}

.c11-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 16px 32px;
}

footer.c11-footer-ap {
  padding: 0;
}

.c11-footer-logo {
  display: flex;
  align-items: center;
  gap: 16px;
  color: white;
}
.c11-footer-logo p {
  margin: 0;
  font-size: 13px;
}
.c11-footer-logo__logo {
  width: 108px;
  height: 16px;
}
.c11-footer-logo__separator {
  background-color: #ffffff;
  height: 1.5rem;
  width: 0.063rem;
}
.c11-footer-copyright__container {
  color: white;
  font-size: 11px;
  line-height: 15px;
  max-width: 50%;
}
.c11-footer-copyright__text {
  text-align: end;
  margin: 0;
}
