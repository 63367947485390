.single-input-section {
  background: #ffffff;
  display: flex;
  align-items: center;
}

.single-input-section__label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  width: 22ch;
  padding: 8px;
}

.single-input-section__body {
  flex: 1;
  display: flex;
}

.single-input-section__body > * {
  flex: 1;
}
