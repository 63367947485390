button.navbar-tab {
  height: 36px;
  color: var(--ap-color-ui-white);
}

button.navbar-tab_selected {
  color: var(--ap-color-ui-green-primary);
}

button.navbar-tab_disabled {
  cursor: default;
  color: var(--ap-color-ui-grey-dark);
}

button.navbar-button_disabled {
  cursor: default;
}

button.navbar-button:hover:enabled {
  background-color: var(--ap-color-green2);
}

button.navbar-button_active,
button.navbar-button:active:enabled {
  background-color: var(--ap-color-green1);
}

.header-navbar_notifications {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
