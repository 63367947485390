input.subheader__search {
  height: 32px;
  font-size: 13px;
  border-radius: 1px;
  border: 1px solid var(--ap-color-grey4);
  min-width: 216px;
}

input.subheader__search.ap-simple-search {
  padding: 0px 24px 0 32px;
}

.subheader__search + .ap-simple-search-search {
  cursor: default;
}

input.subheader__search + .ap-simple-search-search:focus:enabled,
input.subheader__search:focus:enabled {
  box-shadow: unset;
}

input.subheader__search::placeholder {
  color: var(--ap-color-grey4);
}
