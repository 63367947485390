.datacontent-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.datacontent-box__header {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-m);
}

.datacontent-box__header .warning span {
  padding-left: 5px;
}

.datacontent-box__content:not(:empty) {
  display: flex;
  flex-flow: column;
  gap: 32px;
  margin-bottom: 32px;
}

.datacontent-box__description {
  margin: 16px 0 0;
  font-size: var(--ap-font-size-xs);
  font-weight: 400;
  line-height: 22px;
}
