.dataupload-help {
  padding: 30px 30px 30px 0px;
}

.dataupload-help__data-templates {
  margin-top: 32px;
}

.dataupload-help__templates {
  display: flex;
  flex-direction: column;
  color: var(--client-primary-brand-color);
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 25px;
}
.dataupload-help__templates span {
  cursor: pointer;
}
