.header-item {
  display: flex;
  gap: 11px;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 1.5rem;
  padding-left: 2rem;
}

.header-item-vcenter {
  align-items: center;
}

.header-item__label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #737373;
  line-height: 1;
}
.header-item__value {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 1;
  color: #000000;
}
.header-item__value .action-status-select__trigger {
  font-size: 12px;
}
