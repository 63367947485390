.matching-section-card__container {
  background: #ffffff;
  width: 47.3%;
  height: 12.25em;
  padding: 2em;
  box-sizing: border-box;
}

.matching-section-card__container h3 {
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-weight: 500;
}

.matching-section-card__container button {
  text-transform: unset;
}
