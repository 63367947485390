.input-range__container {
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 13px;
  color: var(--ap-color-grey8);
}

.input-range__container > label {
  min-width: 38px;
}

.input-range__container > .ap-slider {
  flex: 1;
}

.input-range__container > span {
  min-width: 32px;
}
