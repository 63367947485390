.global-notes-upload-modal {
}

.global-notes-upload-modal .ap-modal-header {
  padding-block: 0.5rem;
  /* padding-block: 0; */
  padding-inline: 1rem;
  border-bottom: none;
}
.global-notes-upload-modal .ap-modal-body {
  padding-top: 0;
}

.global-notes-upload-modal .datacontent-box {
  gap: 0;
  margin-bottom: 0;
}

.global-notes-upload-modal .datacontent-box__header {
  display: none;
}

.global-notes-upload-modal .datacontent-box__content {
  padding: 1rem;
  background-color: var(--ap-color-ui-grey-bg);
}

.global-notes-upload-modal .ap-modal-content {
  max-width: 45rem;
}

.global-notes-upload-modal .labeled-multiselect {
  padding-inline: 1rem;
  padding-block: 1rem 0;
  background-color: var(--ap-color-ui-grey-bg);
}

.global-notes-upload-modal .file-display {
  padding-block-end: 1rem;
  padding-inline-start: 0;
}

.global-notes-upload-modal .ap-modal-footer {
  max-height: 3em;
  padding-top: 16px;
  padding-top: 0px;
  border: none;
  margin-top: auto;
  display: flex;
}

.global-notes-upload-modal__bottom-padding {
  padding-bottom: 1rem;
  background-color: var(--ap-color-ui-grey-bg);
}
