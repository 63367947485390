.sofas-export-history {
  background-color: var(--ap-color-grey2);
  padding: 24px;
  color: var(--ap-color-text);
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.sofas-export-history__body {
  flex: 1;
}

.sofas-export-history__body > .sofas-title-panel__body {
  gap: 22px;
  padding: 22px;
}

.sofas-export-history__help {
  flex: 0;
  flex-basis: 300px;
}

.sofas-export-history__help-article {
  padding: 0 22px 11px 22px;
  border-bottom: 1px solid var(--ap-color-grey2);
}
