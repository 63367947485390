.general-page__outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 52px);
  background: #f8f4f4;
  border-top: 1px solid var(--ap-color-grey4);
}

.general-page__inner-container {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  width: clamp(1000px, 100%, 1240px);
}
