.matching-homepage__container {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  background: var(--ap-color-grey2);
  padding: 2.438rem 2rem;
}

.matching-homepage__leftside {
  display: flex;
  height: 50%;
}

.matching-homepage__rightside {
  width: 27.7%;
  padding-left: 2em;
}

.matching-homepage-leftside__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5em;
  border: 1.5em solid #ffffff;
  background: var(--ap-color-ui-grey1);
}

.matching-homepage-leftside__textcontainer {
  margin-bottom: 1.5rem;
}

.matching-homepage-leftside__textcontainer h2 {
  margin: 0;
  font-size: 1.5rem;
  line-height: 2.125rem;
}

.matching-homepage-leftside__textcontainer p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 400;
}

.matching-homepage__cardscontainer {
  display: flex;
  justify-content: space-between;
}
