.claims-reports__card {
  background-color: var(--ap-color-white);
  width: 19.5rem;
  height: 17.5rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
}
.claims-reports__card__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4375rem;
}

.claims-reports__card__description {
  flex: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--ap-color-ui-grey-dark);
  overflow: auto;
}
