@import '../../../common.css';

.file-panel {
  padding: 14px 0;
  background-color: var(--ap-color-ui-white);
}

.file-panel__header {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--ap-color-ui-grey-darker);
}

.file-panel__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
}

.file-panel__header-title {
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 2;
  gap: 4px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  color: var(--ap-color-grey5);
}

.file-panel__filename {
  word-wrap: break-word;
  color: var(--client-primary-brand-color);
  cursor: pointer;
}
.file-panel__filename.unpublished {
  color: var(--ap-color-grey5);
}

.file-panel__has-error .file-panel__filename {
  color: #ee3a36;
}

.file-panel__errors-label {
  font-weight: 400;
  line-height: normal;
}

.file-panel__errors-hide-container {
  display: flex;
  align-items: center;
}

.file-panel__error {
  background: #f7f7f7;
  padding: 11px 16px 11px 16px;
  max-height: 100px;
  overflow-y: auto;
}

.file-panel__error:not(:last-child) {
  margin-bottom: 2px;
}

.file-panel__error > pre {
  margin: 0px;
  white-space: pre-wrap;
  /* to wrap to next line long text without spaces */
  overflow-wrap: break-word;
  word-break: break-word;
}

.file-panel__content {
  margin-top: 16px;
}

.file-panel__progress_bar {
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-panel__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

/* workaround. Component name is added to the class to increase rule priority */
button.file-panel__show-hide__button {
  line-height: normal;
}
.file-panel__show-hide__button {
  height: 19px;
  padding: 0px 0px 0px 16px;
  color: var(--ap-color-ui-grey-darker);
}
.file-panel__show-hide__button:hover,
.file-panel__show-hide__button:active,
.file-panel__show-hide__button:focus {
  background: none !important;
}

.file-panel__chevron {
  opacity: 0.6;
  padding: 0 4px;
}

.file-panel--rotated {
  transform: rotate(-180deg);
}

.file-panel__additional {
  display: flex;
  gap: 4px;
}

.file-panel__uploaded-by {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex: 1;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--ap-color-ui-grey-dark);

  padding-right: 6px;
}

.file-panel__download-btn {
  flex: 0;
  display: flex;
  align-items: center;
  border-left: 1px solid #e6e6e6;
  padding-left: 4px;
  height: 26px;
  color: var(--ap-color-ui-grey-light);
}

.file-panel__delete-btn {
  flex: 0;
  display: flex;
  align-items: center;
  border-left: 1px solid #e6e6e6;
  padding-left: 4px;
  height: 26px;
  color: var(--ap-color-ui-grey-light);
}
.file-panel__delete-btn > button {
  color: var(--ap-color-ui-grey-darker);
}
