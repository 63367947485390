.settlement-status-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ap-color-white);
}

.settlement-status-pill > span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.settlement-status-pill__normal {
  border-radius: 14px;
  padding: 4px 14px;

  font-size: var(--ap-font-size-xxs);
  line-height: var(--ap-font-size-xs);
  font-weight: var(--ap-font-weight-m);
}

.settlement-status-pill__big {
  border-radius: 24px;
  padding: 6px 24px;

  font-size: var(--ap-font-size-s);
  line-height: var(--ap-font-size-m);
  font-weight: var(--ap-font-weight-xl);
}
