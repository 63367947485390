.data-upload-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: auto;
  padding: 64px 0 48px 0;
  background: var(--ap-color-ui-white);
  border: 1px solid #e6e6e6;
  margin-bottom: 32px;
}

.data-upload-progress > div {
  padding: 1rem;
}

.data-upload-progress__meter {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  position: relative;
}
.data-upload-progress__meter img {
  width: 92px;
  height: 92px;
}
.data-upload-progress__meter::after {
  /* workaround for border that appears under gif img */
  content: '';
  height: 1px;
  width: 92px;
  background-color: white;
  position: absolute;
  bottom: 0;
}
.data-upload-progress__err-image {
  text-align: center;
  margin-bottom: 32px;
}

.data-upload-progress__text {
  color: var(--ap-color-ui-grey-dark);
  text-align: center;
}

.data-upload-progress__text p {
  font-size: 14px;
  margin-bottom: 14px;
}

.data-upload-progress__footer {
  font-style: italic;
}
.data-upload-progress__footer span {
  display: block;
  margin: 10px 0;
}
.data-upload-progress__footer span a {
  color: var(--ap-color-ui-green-primary);
  font-weight: 700;
}
.data-upload-progress__title {
  font-weight: 500;
}

.data-upload-progress__error {
  padding: 8px 0 0 0;
}
