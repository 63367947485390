.upload-help-panel {
  background: var(--ap-color-ui-white);
  font-size: var(--ap-font-size-s);
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 22px;
}

.upload-help-panel__title {
  margin-block: unset;
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-xl);
}

.upload-help-panel__body {
  display: flex;
  flex-direction: column;
}
