.help-popular-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.help-popular-item__example {
  cursor: pointer;
}
