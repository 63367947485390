:root {
  --ag-row-hover-color: var(--client-primary-brand-color);
}
.claims-tool-grid {
  flex-grow: 1;

  --ag-font-family: Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
  --ag-font-size: 11px;
  --ag-header-background-color: var(--client-secondary-brand-color);
  --ag-header-foreground-color: #ffffff;
  --ag-borders-critical: none;
  --ag-control-panel-background-color: #ffffff;
  --ag-alpine-active-color: var(--client-primary-brand-color) !important;
  --ag-input-focus-border-color: var(--client-primary-brand-color);

  --ag-header-column-resize-handle-height: 70px;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: #4d4d4d;
  --ag-checkbox-checked-color: var(--client-primary-brand-color);
  --ag-border-color: #999999;

  --ag-selected-row-background-color: rgba(92, 179, 53, 0.3);

  --ag-background-color: #fff;
  --ag-foreground-color: #1a1a1a;
  --ag-border-color: #1a1a1a;
  --ag-secondary-border-color: #5cb335;
  --ag-header-background-color: #333333;
  --ag-odd-row-background-color: #f7f7f7;
  --ag-control-panel-background-color: #fff;
  --ag-subheader-background-color: #fff;
  --ag-invalid-color: #e02525;
  --ag-checkbox-unchecked-color: none;
  --ag-checkbox-background-color: var(--ag-background-color);

  --ag-secondary-foreground-color: var(--ag-foreground-color);
  --ag-input-border-color: var(--client-primary-brand-color);

  --ag-input-focus-box-shadow: 0 0 2px 0.1rem var(--ag-input-focus-border-color);
  --ag-disabled-foreground-color: rgba(24, 29, 31, 0.5);
  --ag-chip-background-color: rgba(24, 29, 31, 0.07);
  --ag-input-disabled-border-color: rgba(186, 191, 199, 0.3);
  --ag-input-disabled-background-color: rgba(
    186,
    191,
    199,
    0.15
  ); /* Not sure if we need this */
}
.ag-header-cell,
.ag-header-row {
  overflow: visible;
}
.ag-header-cell-comp-wrapper {
  overflow: hidden;
}
.ag-header-cell-resize,
.ag-header-cell.checkbox.ag-header-cell.checkbox {
  height: 200%;
  z-index: 200;
}
.ag-tabs-body.ag-menu-body {
  background: #ffffff;
}
.ag-tabs-header.ag-menu-header {
  display: none;
}
.ag-picker-field-wrapper {
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 1px;
  height: 26px;
  font-size: 11px;
  color: #999999;
}

.claims-tool-grid .ag-side-buttons {
  display: none;
}

/* ag header cell input container styling start */
.ag-header-cell.ag-floating-filter {
  flex-direction: row-reverse;
}
.ag-floating-filter-button {
  margin-left: 0 !important;
  margin-right: 7px;
}
.ag-icon.ag-icon-filter {
  font-size: unset;
  cursor: pointer;
}
.ag-icon.ag-icon-filter::before {
  /* 
    * Ag Grid uses Css font for icons.
    * We can embed svg icon into css
    * https://stackoverflow.com/questions/4505093/css-content-property-is-it-possible-to-insert-html-instead-of-text 
    */
  content: url('data:image/svg+xml;%20charset=utf8,%3Csvg%20width%3D%2216%22%20height%3D%2210%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6.333%2010h3.334V8.333H6.333V10ZM.5%200v1.667h15V0H.5ZM3%205.833h10V4.167H3v1.666Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
}

/* ag header cell input container styling end */

/* styles for filter box start */
.ag-header-cell .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
  border: 1px solid var(--client-primary-brand-color);
  background: #1a1a1a !important;
}
.ag-header-cell
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper
  .ag-input-field-input {
  border-radius: 0 !important;
  border: none !important;
  background: #1a1a1a !important;
  height: 24px;
  font-family: var(--ag-font-family);
  font-size: var(--ag-font-size);
  font-weight: 400;
}

.ag-header-cell
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper.filter-set-column-filter::after {
  /* agSetColumn triangle icon */
  content: url('data:image/svg+xml;%20charset=utf8,%3Csvg%20width%3D%2211%22%20height%3D%226%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22m10.5.5-5%205-5-5%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
  margin-right: 8px;
  cursor: pointer;
}
.ag-header-cell
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper.filter-set-column-filter,
.ag-header-cell
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper.filter-set-column-filter
  input {
  cursor: pointer;
}

.ag-header-cell.ag-floating-filter {
  padding-bottom: 8px;
}

/* styles for filter box end */

/* styles for filter popover start */
.ag-popup .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper {
  border-radius: 1px;
  border: 1px solid #999999;
}
.ag-popup
  .ag-wrapper.ag-input-wrapper.ag-text-field-input-wrapper
  .ag-input-field-input {
  border: 0;
  background: #ffffff !important;
  height: 32px;
  font-size: 13px;
}

.ag-popup .ag-tabs.ag-menu.ag-focus-managed.ag-popup-child {
  border: 0;
}
/* styles for filter popover end */

.claims-tool-grid .pin-icon.active path {
  fill: var(--client-primary-brand-color);
}
.claims-tool-grid .pin-icon path {
  fill: #737373;
}

/* style for row */
.claims-tool-grid__row {
  border-bottom: 1px solid #e6e6e6;
}

.claims-tool-grid .ag-header-row .referenceNumber .ag-floating-filter-body {
  display: none;
}

.claims-tool-grid .ag-cell.referenceNumber {
  cursor: pointer;
  color: var(--ap-color-green2);
  font-weight: bold;
  text-decoration: underline;
  text-align: end;
}
.claims-tool-grid .ag-cell.counterpartyName {
  color: var(--ap-color-green2);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
}

.claims-tool-grid .ag-cell.actionStatus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.claims-tool-grid .ag-cell.currency {
  font-weight: 700;
}

.claims-tool-grid .ag-cell.group-column .ag-group-value {
  display: none;
}
.claims-tool-grid .ag-row-group-expanded {
  background: linear-gradient(0deg, rgba(92, 179, 53, 0.3), rgba(92, 179, 53, 0.3)),
    #ffffff;
}
.claims-tool-grid .ag-row-level-1 {
  background: linear-gradient(0deg, rgba(92, 179, 53, 0.2), rgba(92, 179, 53, 0.2)),
    #ffffff;
}

.claims-tool-grid .ag-cell.subMatchCode {
  display: flex;
  justify-content: center;
}

.claims-tool-grid .ag-cell.matchScore {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-header-cell.ag-header-cell-filtered .ag-header-cell-text {
  color: var(--ag-input-border-color);
}

.ag-header-cell.ag-header-cell-filtered .ag-icon.ag-icon-filter {
  display: none;
}

.ag-header-cell.ag-floating-filter.ag-focus-managed {
  outline: none !important;
}

.ag-header-cell.ag-floating-filter.ag-focus-managed::after {
  display: none;
}
