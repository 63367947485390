.batcheditor {
  top: var(--ap-app-bar-height);
  padding-bottom: 12px;
}

.batcheditor > .left-slider-modal__body {
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
}

.batcheditor .batcheditor-form {
  flex: 1;
}
.batcheditor .batcheditor-form h4 {
  display: flex;
  margin: 0;
}
.batcheditor .batcheditor-form h4 .ap-loader-default {
  width: auto;
  padding-left: 15px;
}
.batcheditor .ap-modal-footer .ap-loader-default > div {
  border-top-color: white;
}
