.sofa-export-in-progress {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #eff8ed;
}

.sofa-export-in-progress > h4 {
  margin: 0;
}

.sofa-export-in-progress > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sofa-export__in-progress {
  background-color: #eff8ed;
}

.sofa-export__in-progress > .accordion-section__content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sofa-export__in-progress__header {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sofas-export__modal-cancel__message {
  --cancel-icon-color: #dc2626;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--cancel-icon-color);
  font-weight: var(--ap-font-weight-m);
}
