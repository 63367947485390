.sofas-schedules-panel__loading-screen {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  height: 160px;
}

.sofas-export-loading__title {
  padding-top: 48px;
  padding-bottom: 120px;
  display: flex;
  justify-content: center;
}

.sofas-export-loading {
  flex: 1;
  background-color: var(--ap-color-ui-grey-bg);
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: stretch;
}

.sofas-export-loading__loader {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}

.sofas-export-loading__loader-text {
  display: flex;
  justify-content: space-between;
}

.sofas-export-loading__bottom-panel {
  display: flex;
  justify-content: right;
  padding: 0 16px 16px 16px;
}

.sofas-export__file-ready {
  padding: 32px 32px 16px 32px;

  display: flex;
  justify-content: left;
  align-items: center;
  gap: 32px;
}

.sofas-export__file-ready__text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sofas-export__file-ready__text > h5 {
  padding: unset;
  margin: unset;
  font-size: var(--ap-font-size-m);
}

.sofas-export__file-ready__text > span {
  font-size: var(--ap-font-size-s);
}

.sofas-export__file-ready__here {
  cursor: pointer;
  color: var(--ap-color-primary);
}

.sofas-export__file-ready__icon {
  background-image: url('../../../../img/svgImage/file.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: 'white';
  width: 72px;
  height: 72px;
}
