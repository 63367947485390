.settlement-agreement-cell {
  cursor: pointer;
  color: var(--ap-color-green2);
  font-weight: 700;
  text-decoration: underline;
}

.settlement-agreement-cell__disabled {
  color: var(--ap-color-ui-grey-base);
  font-weight: 500;
}
