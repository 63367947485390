/* Customization: Use CSS cascade to override either the properties directly or the CSS variables */

/*  Usage
    .custom-simple-table {
      --simple-table-columns-number: 2;
    }
    ...
    <div className="simple-table custom-simple-table">
      <div className="simple-table-header">Header 1</div>
      <div className="simple-table-header">Header 2</div>

      <div>Row 1 Col 1</div>
      <div>Row 1 Col 2</div>

      <div>Row 2 Col 1</div>
      <div>Row 2 Col 2</div>
    </div>

*/

.simple-table {
  /* CSS variables declaration */
  --simple-table-columns-number: 5;
  --simple-table-link-color: var(--ap-color-ui-green-base);

  --simple-table-row-bg: var(--ap-bg-surface1);
  --simple-table-font-size: var(--ap-font-size-xs);
  --simple-table-template-columns: minmax(auto, 2fr)
    repeat(calc(var(--simple-table-columns-number) - 1), 1fr);
}

.simple-table {
  background-color: var(--simple-table-bg, grey);
  margin-block-start: var(--simple-table-margin-block-start, 1rem);
  padding: 16px;
  display: grid;
  row-gap: 8px;
  grid-template-columns: var(--simple-table-template-columns);
  font-size: var(--simple-table-font-size);
  align-content: flex-start;
}

.simple-table > * {
  padding-inline: var(--simple-table--cell-padding-inline, 1em);
  padding-block: var(--simple-table--cell-padding-block, 0.55em);
  background-color: var(--simple-table-row-bg);
}

.simple-table-link {
  color: var(--simple-table-link-color);
  cursor: pointer;
}

.simple-table-link:focus,
.simple-table-link:hover {
  text-decoration: underline;
}

.simple-table-row-for-empty {
  grid-column: 1 / 6;
  min-height: 16em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sk-on .simple-table > * {
  color: transparent;
  background-color: var(--simple-table-row-bg);
}

.sk-on .simple-table > * * {
  color: transparent;
  background-color: var(--simple-table-row-bg);
}

.sk-on .simple-table .simple-table-header {
  color: transparent;
  opacity: 0.5;
}

.simple-table-title {
  background-color: var(--simple-table-bg, grey);
  text-transform: uppercase;
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-s);
  line-height: 1.25;
  padding: 0;
  margin: 0;
}

.simple-table-fullwidth {
  grid-column: 1 / -1;
}

.simple-table-header {
  font-size: var(--simple-table-header-fs, 11px);
  background-color: var(--simple-table-bg, grey);
}

.simple-table-bold {
  font-weight: var(--ap-font-weight-m);
}

.simple-table-highlight {
  font-weight: var(--ap-font-weight-m);
  color: var(--ap-color-ui-green-primary);
}

.simple-table-space {
  margin-inline-end: 1rem;
}

.simple-table__align-center {
  text-align: center;
}

.simple-table__align-end {
  text-align: end;
}
