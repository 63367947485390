.client-name-logo__line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.client-name-logo__line > .ap-button-main,
.client-name-logo__line > .ap-button-casual {
  flex-basis: 10em;
  text-align: center;
}

.client-name-logo__image {
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  gap: 8px;
}

.client-name-logo__drop-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  overflow: hidden;
  cursor: pointer;
}
.client-name-logo__drop-zone img {
  max-width: 100%;
  max-height: auto;
}

.client-name-logo__filename {
  width: 60%;
  align-items: stretch;
}

.client-name-logo__image-controls {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  width: 375px;
  gap: 12px;
}

.client-name-logo__info-text {
  font-size: 11px;
  color: var(--ap-color-grey5);
}
