.error-message {
  display: flex;
  align-items: center;
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
  font-weight: 500;
  color: var(--ap-color-ui-red-alert);
  margin: 15px 0;
}
.error-message span {
  padding-left: 5px;
}
