.contact-us-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contact-us-page__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  background-color: var(--ap-color-ui-grey-bg);
}

.contact-us-page__message-box > .panel-box-shadowed {
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 928px;

  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-s);
  font-style: normal;
  line-height: 20px;
}

.contact-us-page__message {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.contact-us-page__message_link {
  color: var(--ap-color-green2);
  font-size: var(--ap-font-size-m);
  font-weight: var(--ap-font-weight-xl);
  text-decoration-line: underline;
}
