.document-item__actions {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: nowrap;
}

.document-item__divider {
  border-right: 1px solid var(--ap-color-grey2);
  height: 28px;
  margin: 0 4px;
}

.document-item__primary-switch {
  padding-right: 8px;
}

.document-item__comment-text {
  padding: 8px 32px;
}

.pl-8 {
  padding-left: 8px;
}
.document-item__comment-button.active svg path {
  fill: var(--ap-color-ui-green-primary);
}

.document-item__delete-loader {
  width: 36px;
  height: 36px;
}
