.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  color: var(--ap-color-grey5);
  font-size: var(--ap-font-size-xs);
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 4px;
}

.input {
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 8px;
  border: 1px solid var(--ap-color-grey4);
  border-radius: 1px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--ap-color-grey8);
  font-size: 16px;
  line-height: 24px;
  appearance: none;
  text-indent: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input:hover,
.input:focus {
  border-color: var(--ap-color-green2);
  outline: none;
}
.input:active {
  border-color: var(--ap-color-green3);
  outline: none;
}
.input:disabled,
.input:disabled::placeholder {
  pointer-events: none;
  color: var(--ap-color-grey5);
  border-color: var(--ap-color-grey2);
}

.inputError {
  border: 1px solid var(--ap-color-red1);
}

.error {
  margin-top: 6px;
  color: var(--ap-color-red1);
  font-size: var(--ap-font-size-xxs);
  line-height: 16px;
  display: flex;
  align-items: center;
}

.errorIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: -1px;
  display: inline-block;
}

.errorIcon i {
  font-size: 16px;
}
