.settings-help__container {
  display: flex;
  justify-content: center;
  flex: 100;
}

.settings-help__background {
  background: url(/src/img/sofa_background.png) no-repeat center fixed;
  background-size: cover;
}

.settings-help__container img {
  border: 1px solid #cccccc;
}
