.usergroups {
  --edit-table-grid-template-columns: 1fr 1fr 1fr minmax(6rem, auto);
}

.usergroups .multiselect--container {
  border: none;
  line-height: 1;
}

.usergroups .multiselect--button {
  box-sizing: content-box;

  height: auto;
  font-weight: 400;
  min-height: unset;
  text-indent: 0;
  color: inherit;
  appearance: none;
  background-color: inherit;
  font-size: inherit;
  border: 0;
  margin: 0;
  caret-color: var(--ap-color-ui-green-primary);
  box-sizing: border-box;
  outline-style: solid;
  outline-width: 1px;
  outline-offset: -1px;
  outline-color: transparent;
  transition: outline-color 200ms ease-in-out;
}
.usergroups .multiselect--button:hover,
.usergroups .multiselect--button:focus {
  outline-color: var(--ap-color-ui-green-primary);
}

.usergroups .multiselect--text {
  font-size: var(--ap-font-size-xs);
  line-height: 1.5;
}

.usergroups .multiselect--disabled {
  color: inherit;
}

.usergroups .multiselect--disabled svg {
  visibility: hidden;
}
