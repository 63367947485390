.claims-tool-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 6px;
  height: 48px;
  flex-shrink: 0;
}

.claims-tool-header__container {
  display: flex;
  flex-direction: column;
}

.claims-tool-header__label {
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-m);
  cursor: pointer;
}

.claims-tool-header__menu {
  display: flex;
  align-items: center;
  gap: var(--ap-items-spacing);
}

.claims-tool-header .ap-button-tab::before {
  box-sizing: border-box;
}

.claims-contracts-tool
  .claims-tool-header__columns.ap-button-secondary.custom-icon-btn {
  cursor: pointer;
  height: 32px;
  background-color: white;
  transition: none;
}

.custom-icon-btn > span {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.claims-contracts-tool .custom-icon-btn > span svg {
  fill: var(--ap-color-green2);
  margin-right: 5px;
  width: 15px;
  height: 15px;
}
.claims-contracts-tool .claims-tool-header__columns.isOpen > span svg {
  position: relative;
  top: -2px;
}
.claims-contracts-tool
  .claims-tool-header__columns.ap-button-secondary.custom-icon-btn.isOpen {
  background-color: var(--ap-color-green0);
  color: var(--ap-color-dark);
}

.claims-tool-header__search .search-input input {
  font-size: 13px;
  font-weight: 400;
  width: 200px;
  height: 32px;
}
.claims-tool-header__search .search-input svg {
  margin-top: -6px;
  margin-right: -6px;
  height: 20px;
}

.claims-tool-header__my-claims-only {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #737373;
  display: flex;
  gap: 15px;
  line-height: 22px;
  margin-left: auto;
  margin-right: 16px;
}

.subheader__export-loader_container {
  display: flex;
  font-size: var(--ap-font-size-xs);
  color: var(--ap-color-grey6);
  flex-direction: row;
  align-items: center;
  /*position: absolute;*/
  background: var(--ap-color-green0);
  width: 100%;
  height: 44px;
}

.claims-tool-header__switch {
  display: flex;
  padding-left: 1em;
  gap: 0.625em;
  border-top: 1px solid #999999;
}

.claims-tool-header__switch .ap-three-way-switch {
  flex-direction: column;
  justify-content: center;
}

.claims-tool-header__switch .ap-three-way-switch__switch {
  height: 16px;
}