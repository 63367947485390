.dashboard {
  flex: 1;
  background: url(../../img/wizard_background.jpg) no-repeat center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__column {
  flex: 1;
  display: grid;
  align-items: flex-start;
  min-height: 70%;
  padding: 1rem 0;
  max-width: 69rem;
  row-gap: 1rem;
}
.dashboard__column.apDashboard {
  max-width: 84rem;
}
.dashboard__grid {
  flex: 1;
  display: grid;
  row-gap: 0.5rem;
  column-gap: 2rem;
}

.dashboard__grid--4col {
  grid-template-columns: repeat(auto-fit, minmax(22.5%, 1fr));
}

.dashboard__grid--3col {
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
}

.dashboard__userinfo {
  grid-column: 1 / -1;
  padding-block: 0 0.5rem;
  color: var(--ap-bg-surface1);
}
.dashboard__userinfo.withBackground {
  padding-block: 1.5rem;
  padding-inline: 2rem;
  background-color: var(--ap-color-ui-green-primary);
}

.dashboard__userinfo__title {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-m);
  line-height: 1.22;
  margin-block: 0;
  display: flex;
  align-items: center;
}
.dashboard__userinfo__title > span:last-child {
  margin-left: auto;
}

.dashboard__userinfo__description {
  font-size: var(--ap-font-size-s);
  margin-block: 1em 0;
  line-height: 1.42;
}

.dashboard__cdofooter {
  margin-block-start: auto;
}

.dashboard__skip {
  color: var(--ap-bg-surface1);
  background-color: var(--ap-color-ui-green-primary);
  display: inline;
  align-self: flex-start;
  width: fit-content;
  font-size: var(--ap-font-size-xs);
  font-weight: var(--ap-font-weight-m);
  padding-block: 0.5rem;
  padding-inline: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  border-radius: 1px;
}

.dashboard__skip svg {
  margin-inline-end: 0.35rem;
  fill: currentColor;
  font-size: 0.5em;
}

.dashboard__skip:hover {
  background-color: var(--ap-color-green2);
}

.dashboard__skip:focus-visible {
  outline: 2px solid var(--ap-bg-surface1);
  outline-offset: -2px;
}

.dashboard .plaincard__title {
  color: transparent;
}

.dashboard-tasks-done .plaincard__title {
  color: var(--plaincard--accent);
  animation: 300ms ease-in anim-appear;
}

.dashboard .dashboard-task-link {
  pointer-events: none;
}

.dashboard .dashboard-task-link .ap-loader-default {
  width: 34px;
}

.dashboard-user-done .dashboard-task-link {
  pointer-events: all;
}
.dashboard__actions {
  display: flex;
  align-items: center;
  gap: 20px;
}
.dashboard__actions .dashboard-show-mine-toggle .ap-three-way-switch__label {
  color: white;
}
