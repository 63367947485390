.single-contract-overview {
  display: flex;
  flex-direction: column;
  gap: 16px;
  --_single-contract-row-space: 8px;
  color: var(--ap-color-grey8);
}

.single-contract-overview__section .section__content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
.single-contract-overview__section.contract-resolution .section__content {
  margin-left: 0;
}

.single-contract-overview__section-is-matrix {
  padding-block-start: 8px;
}

.single-contract-overview__matrix-label {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  margin-block-start: var(--_single-contract-row-space);
  color: var(--ap-color-black);
}

.single-contract-overview__matrix-label > p {
  margin: 0;
}

.single-contract-overview__matrix-container {
  margin-left: 24px;
  flex: 1;
}

.single-contract-overview .section__title {
  text-transform: uppercase;
}

.single-contract-overview .section__content,
.single-contract-overview .section__title {
  padding-inline: 0;
  flex: 1;
}

.single-contract-overview__section .gms-root {
  --gms-font-size: var(--ap-font-size-xs);
}

.single-contract-overview__section .gms-main-label-wrapper {
  transition: border-color 200ms ease-in-out;
  border-color: transparent;
  height: unset;
  min-height: 32px;
  text-indent: 8px;
}

.single-contract-overview__section .gms-main-label-wrapper:hover,
.single-contract-overview__section .gms-main-label-wrapper:focus {
  border-color: var(--ap-color-ui-green-primary);
}

.single-contract-overview__section .gms-toggle[open] .gms-main-label-wrapper {
  border-color: var(--ap-color-ui-green-primary);
  background-color: var(--ap-color-ui-green-primary);
  color: var(--ap-color-primary-text-inverted);
}

.single-contract-overview__section .gms-select-icon {
  fill: var(--ap-color-ui-grey-base);
}

.single-contract-overview__section .multiselect--container .multiselect--button {
  transition: outline-color 200ms ease-in-out;
  padding-inline: 8px 4px;
  outline: 1px solid;
  outline-offset: -1px;
  outline-color: transparent;
  min-height: 32px;
  height: unset;
}

.single-contract-overview__section
  .multiselect--container
  .multiselect--button:hover,
.single-contract-overview__section
  .multiselect--container
  .multiselect--button:focus {
  outline-color: var(--ap-color-ui-green-primary);
}

.single-contract-overview__section .scp-input {
  min-height: 30px;
  box-sizing: border-box;
  padding: 8px;
}
.single-contract-overview__section .scp-input:not(:disabled):hover {
  outline: 1px solid var(--ap-color-ui-green-primary);
}

.single-contract-overview__section .scp-input:not(:disabled):active {
  /* TODO will use this when implmenting color flips */
  /* background-color: var(--ap-color-ui-green-primary); */
  /* color: var(--ap-color-primary-text-inverted); */
}
