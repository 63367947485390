.batchedit-line {
  display: flex;
  justify-content: center;
  --bachedit-line--gap: 10px;
  gap: var(--bachedit-line--gap);
  align-items: end;
}

.batchedit-line > button {
  text-transform: unset;
  flex: none;
}

.batchedit-selections {
  display: flex;
  flex: 1;
  gap: var(--bachedit-line--gap);
}

.batchedit-selections > * {
  display: flex;
  flex: 1;
}
.batchedit-line .ap-button-secondary.ap-button-casual {
  height: 40px;
}
