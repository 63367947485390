.matching-section {
  background-color: var(--ap-color-ui-grey1);
  padding: 0 2rem;
  margin-bottom: 2rem;
}
.matching-section > div {
  padding: 1.2rem 0;
}
.matching-section .supported-formats {
  bottom: -2rem;
  right: 6rem;
}
.matching-section .fileupload-panel__caption {
  width: 100%;
}
.matching-section div.fileupload-panel {
  padding: 0 0 1.2rem;
}
.matching-section .fileupload-panel__dragdrop {
  padding: 0rem 2rem;
}
.matching-section .fileupload-panel__caption p {
  gap: 1.2rem;
  width: 100%;
  text-align: center;
}
.matching-section .fileupload-panel__caption .ap-button-main {
  margin-left: auto;
}
.matching-section .warning {
  margin-bottom: 0;
}

.matching-section h4 {
  font-size: var(--ap-font-size-m);
  margin: 0;
  font-weight: 500;
}
