.claim-reviewer-cell {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: -webkit-fill-available;
}

.claim-reviewer-cell > svg {
  flex-shrink: 0;
}

.claim-reviewer-cell__name {
  display: inline-block;
  margin-left: 10px;
  min-width: 142px;
  /* max-width: 550px; */
  text-overflow: ellipsis;
  overflow: hidden;
}
