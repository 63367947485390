.dashboard-clients {
  display: flex;
  gap: 64px;
  opacity: 1;
  transition: all 0.7s ease-in-out;
  min-height: 460px;
}
.dashboard-clients > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 50%;
}
.dashboard .dashboard-clients > div.ap-loader-default {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 450px;
}
.dashboard-clients .claims-tasks .task:not(.firstTask) .left-col {
  text-transform: capitalize;
  font-size: var(--ap-font-size-s);
  font-weight: 400;
}
.dashboard-clients .task.indentLeft > div > span {
  padding-left: 20px;
}
.dashboard-clients .claims-tasks .task:not(.firstTask) > div {
  padding: 20px 0;
}
.dashboard-clients .claims-tasks .task.hasFootnote > div {
  padding-bottom: 5px;
}
.dashboard-clients .task b {
  font-size: var(--ap-font-size-xs);
  display: block;
  padding-bottom: 20px;
}
.dashboard-clients .fullWidth {
  width: 100%;
}
