.dropdown-button {
  padding-left: 16px;
}

.dropdown-button span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-button__label {
  text-transform: initial;
}

.dropdown-button__arrow {
  margin-left: 8px;
}

.dropdown-button__arrow .ap-icon {
  width: 24px;
  margin-right: 0;
}
