.button-nav {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
}

.button-nav-active {
  border-bottom: 1px solid #00ff00;
  background-color: #333333;
}

.button-nav--text {
  white-space: nowrap;
  display: block;
}

.button-nav svg {
  fill: transparent;
}
.button-nav-active > svg {
  fill: #fff;
}
