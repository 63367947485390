.settings-page-content {
  background-color: #f2f2f2;
  flex-grow: 1;
  margin: 40px 40px 40px 40px;
}

.settings-page-content > div {
  margin: 0 auto;
  width: clamp(40%, 100%, 1240px);
}

.settings-content__body {
  background: #ffffff;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.15);
  padding: 16px;
}
