.settlement-admin-list,
.settlement-admin__grid,
.settlement-admin__grid > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.settlement-admin__grid .ag-root-wrapper {
  flex: 1;
  min-height: 620px;
}
