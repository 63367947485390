.categories form.dynamicfields__row-el-add,
.categories .dynamicfields-table.add-category .edit-table__header-row {
  grid-template-columns: 1fr 1fr minmax(8em, auto);
}
.categories .dynamicfields-table.edit-category .edit-table__header:last-child,
.categories .dynamicfields__cell-edit .edit-table__cell-switch {
  text-align: center;
  justify-content: center;
}
.categories .dynamicfields-table.edit-category .edit-table__header {
  padding: 0;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sort-header.active,
.sort-header:hover {
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}
.categories .dynamicfields-table.edit-category .edit-table__header svg {
  width: 12px;
  margin-left: 8px;
}
.edit-category form.dynamicfields__row-el,
.edit-category .edit-table__header-row {
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr minmax(4em, auto);
}
.edit-category .edit-table__title {
  margin: 0;
  padding: 24px 16px 0px 16px;
  background-color: #ffffff;
}
.edit-category .warning {
  margin: 0;
  padding: 4px 16px 28px 16px;
  background-color: #ffffff;
}
.edit-category .switch-toggle-all {
  padding: 16px
}
.categories .dynamicfields__cell-edit .edit-table__cell-switch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.categories .minor-category-input {
  border: none;
  width: calc(100% - 10px);
  padding: 11px 5px;
  border: 1px solid transparent;
  background-color: transparent;
}
.categories .minor-category-input:focus,
.categories .minor-category-input:hover {
  border-radius: 0;
  outline: none;
  border-color: var(--ap-color-ui-green-primary);
}
.categories .dynamicfields__cell-edit p {
  padding-inline: var(--cell-body-pad-inline);
  margin: 0;
}
.categories .form-content .utils-obstruct-panel {
  opacity: 0.3;
}
