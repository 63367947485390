.contracts-tool-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px var(--ap-items-spacing);
  height: 47px;
  flex-shrink: 0;
  border-top: 1px solid #999999;
}

.contracts-tool-subheader__menu {
  display: flex;
  align-items: center;
  gap: var(--ap-items-spacing);
}

.contracts-tool-subheader__label {
  margin-left: 11px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #737373;
  margin-right: auto;
}

.subheader-tabs {
  background: #e6e6e6;
  border-radius: 18px;
  display: flex;
  gap: 8px;
  padding: 4px;
}

.subheader-tab-button:hover:enabled,
.subheader-tab-button:focus {
  background: #ffffff !important;
}

.subheader-tab-button.selected:hover:enabled,
.subheader-tab-button.selected:focus {
  background: var(--client-primary-brand-color) !important;
}

.subheader-tab-button::before {
  border: 0 !important;
}

.subheader-tab-button.selected {
  background: var(--client-primary-brand-color) !important;
  color: white !important;
}

.subheader-tab-button {
  border-radius: 15px !important;
  background: #ffffff !important;
  color: #737373 !important;
  height: 30px !important;
  text-transform: none !important;
  font-family: 'Roboto' !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.contracts-tool-subheader__hide_expand {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #737373;
  display: flex;
  gap: 15px;
  line-height: 22px;
}

.contracts-tool-subheader__accept_button button {
  text-transform: none;
}

.contracts-tool-subheader__export-loader {
  width: 36px;
  height: 32px;
}

/** 
  Fixes for the ApMenu 
  TO DO: Update library so this isn't necessary 
*/
.contracts-tool-subheader__export-menu button div:first-child {
  width: 16px;
}
.contracts-tool-subheader__export-menu button div {
  font-size: 13px;
}
