.surviving-claim-edit {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.surviving-claim-edit__label {
  color: var(--ap-color-grey5);
  font-size: var(--ap-font-size-xs);
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 4px;
}

.surviving-claim-edit > .survivingclaimnoselect {
  flex: 1;
}

.surviving-claim-edit .scp-input.survivingclaimnoselect__textinput {
  margin: 1px;
  outline: 1px solid var(--ap-color-ui-green-primary);
}
