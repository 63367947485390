.sofas-export-list {
  background-color: var(--ap-color-ui-grey1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sofas-export-list-table {
  font-size: var(--ap-font-size-xs);
}

.sofas-export-list-table .document-table-header-row {
  color: var(--ap-color-grey5);
}

.sofas-export-list-table .document-table-header-row > th:last-child {
  width: 40px;
}

.sofas-export-list > h4 {
  margin: 0;
}

.sofas-export-listitem {
  font-weight: var(--ap-font-weight-s);
}

.sofas-export-listitem__files {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: var(--ap-font-weight-m);
}

.sofas-export-listitem__files > .svg-icon {
  flex-shrink: 0;
  flex-basis: 22px;
}

.sofas-export-listitem__files-body {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.sofas-export-listitem__actions {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: flex-end;
}
