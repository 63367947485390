.page-help-section {
  flex: 0.4;
}
.page-help-section ul {
  padding: 0;
  list-style-type: none;
  background-color: white;
  margin-bottom: 0;
}
.page-help-section ul li {
  padding: 0.5rem 1.3rem;
  border-bottom: 1px solid var(--ap-color-grey2);
}
.page-help-section ul li:last-of-type {
  border: none;
  padding-top: 0.7rem;
}
.page-help-section h3 {
  margin: 0;
}
.page-help-section h6 {
  font-weight: 400;
  font-size: var(--ap-font-size-s);
  margin: 0.7rem 0 0;
}
.page-help-section h5,
.page-help-section p {
  margin: 10px 0;
  font-size: var(--ap-font-size-s);
  font-weight: 400;
}
.page-help-section p a {
  color: var(--ap-color-green2);
  font-weight: 700;
  text-decoration: underline;
}
.page-help-section h5 p {
  display: flex;
  margin: 0;
}
.page-help-section h5 p.withContent {
  font-weight: 700;
}
.page-help-section h5 span {
  padding-right: 10px;
  font-weight: bolder;
}
