.claim-document-table {
  --table-background: var(--simple-table-bg, ---ap-color-ui-grey-bg);
  min-height: 8em;
  display: flex;
  flex-direction: column;
  background-color: var(--table-background);
}

.claim-document-table > table {
  background-color: var(--table-background);
  border-spacing: 0 0.5rem;
  border: 16px solid var(--table-background);
  /* border-collapse: collapse; */
}

.claim-document-table .table-header-row > th {
  background-color: var(--table-background);
  font-size: var(--ap-font-size-xs);
  font-weight: unset;
  text-align: unset;
  padding: 6px 10px;
}

.claim-document-table .table-item-row {
  background: white;
}

.claim-document-table .table-item-row > td {
  text-align: unset;
  font-size: var(--ap-font-size-xs);
  padding: 6px 10px;
}

.claim-document-table .table-progress-row > td {
  padding: 0;
  background: white;
  font-size: var(--ap-font-size-xs);
}

.claim-document-table .table-item-row__new-row {
  animation: 500ms ease-in anim-fade-green-2;
}

.table-item__empty-loading-value {
  min-height: 18px;
}

.table-item__empty-list-message {
  min-height: 16em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table-item__additional-row-info {
  top: -6px;
  position: relative;
}
.claim-document-table .table-item__additional-row-info > td {
  padding: 10px;
}
.claims-documents__delete svg {
  width: 20px;
  height: 20px;
}

.claim-document-table .table-header-row th:last-child {
  text-align: center;
}
