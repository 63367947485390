.exhibit-contract-exporter__section {
  background: var(--ap-color-ui-grey-bg);
  display: flex;
  flex-flow: column;
  padding: 16px;
  margin-bottom: 16px;
}

.exhibit-contract-exporter__section > header {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ap-color-black);
  padding-bottom: 16px;
}

.exhibit-contract-exporter__input-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  margin-bottom: 16px;
}
.exhibit-contract-exporter__input-section > div {
  flex: 1;
}

.exhibit-contract-exporter__table {
  display: flex;
  align-items: stretch;
  gap: 25px;
  margin-bottom: 16px;
}

.exhibit-contract-exporter__table-buttons {
  flex: 0.15;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
}
.exhibit-contract-exporter__section .exhibit-exporter__section-content-row__radio {
  margin: 10px 0;
}
.exhibit-contract-exporter__section
  .exhibit-exporter__section-content-row__radio
  label {
  color: var(--ap-color-grey5);
  font-size: var(--ap-font-size-xs);
}
