.batchedit-upload-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f7f7f7;
}

.batchedit-upload-form label {
  margin-bottom: 10px;
}

.batchedit-upload-form .bottom-text {
  margin-top: 30px;
}

.batchedit-upload-form .ap-button-group button {
  margin-right: 5px;
  text-transform: unset;
}

.batchedit-upload-form .ap-button-group button + button {
  margin-left: 5px;
}

.batchedit-upload-form__error-title {
  display: flex;
  align-items: center;
  font-size: var(--ap-font-size-xs);
  color: #d97706;
  font-weight: 500;
  margin: 0 0 10px;
}

.batchedit-upload-form__error-title button {
  background-color: transparent;
  border: none;
  color: #d97706;
  margin: 0;
  padding: 0 0 0 0.5em;
  text-decoration: underline;
  font-size: var(--ap-font-size-xs);
  font-weight: 700;
  cursor: pointer;
}

.batchedit-upload-form__error-title svg {
  margin-right: 5px;
}
