.exhibit-exporter__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 52px);
  background: var(--ap-color-grey2);
}

.exhibit-exporter {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: clamp(1000px, 100%, 1240px);
}

.exhibit-history-table__wrapper {
  display: none;
}

.exhibit-history-table__wrapper.show {
  display: block;
  margin-bottom: 32px;
}

.exhibit-exporter__sections-container {
  margin-top: 36px;
  background: var(--ap-color-white);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.15);
  padding: 16px;
}

button.exhibit-exporter__btn,
button.exhibit-exporter__previous-exhibits-btn {
  border: 1px solid var(--ap-color-green2);
  color: var(--ap-color-green2);
  width: 176px;
  text-transform: initial;
}

button.exhibit-exporter__previous-exhibits-btn {
  font-size: var(--ap-font-size-s);
}

button.exhibit-exporter__btn {
  font-size: var(--ap-font-size-xs);
  height: 32px;
  margin-top: 10px;
}

button.exhibit-exporter__btn--disabled {
  border: 1px solid var(--ap-color-grey3);
  color: var(--ap-color-grey3);
}

.exhibit-exporter__container button.exhibit-exporter__btn span {
  display: flex;
  align-items: center;
  text-transform: initial;
}

.exhibit-exporter__section {
  background: var(--ap-color-ui-grey-bg);
  display: flex;
  flex-flow: column;
  padding: 16px;
  margin-bottom: 16px;
}

.exhibit-exporter__section .label {
  color: var(--ap-color-grey5);
  font-size: var(--ap-font-size-xs);
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 4px;
}

.exhibit-exporter__section-title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ap-color-black);
  padding-bottom: 16px;
}

.exhibit-exporter__section-content-row {
  display: flex;
  align-items: stretch;
  gap: 25px;
  margin-bottom: 16px;
}

.exhibit-exporter__section-content-row_buttons-right-container {
  flex: 0.15;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
}

.exhibit-exporter__section-content-row__multiselect-container {
  width: 40%;
}
.exhibit-exporter__section-content-row__radio-container {
  width: 20%;
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
}

.exhibit-exporter__section-content-row__delete-btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: none;
}

.exhibit-exporter__section-content-row__delete-btn {
  display: none;
}

.exhibit-exporter__section-content-row__delete-btn.show {
  display: block;
  height: 51px;
  padding-top: 37px;
}

.exhibit-exporter .align-flex-end {
  align-items: flex-end;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.exhibit-exporter__section-content-row .ap-input-text,
.exhibit-exporter__section-content-row .ap-input-select {
  /*min-width: 376px;*/
}

.exhibit-exporter__section-content-row .ap-input-text input,
.exhibit-exporter__section-content-row .ap-input-select select {
  border: 1px solid var(--ap-color-grey3);
}

.exhibit-exporter__select-input__container {
  background: var(--ap-color-white);
  border: 1px solid var(--ap-color-grey3);
  height: 40px;
}

.exhibit-exporter__select-input__container .ap-nice-select__arrow {
  top: 3px;
}

.exhibit-exporter__resolution-select {
  flex: 1;
}

.exhibit-exporter__select-input__label {
  color: var(--ap-color-grey5);
  font-size: var(--ap-font-size-xs);
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 4px;
}

.exhibit-exporter__section-content-row-align-right {
  display: flex;
  justify-content: flex-end;
}

.exhibit-exporter__section-content-row__footnote-text {
  width: 250%;
}

.exhibit-exporter-header {
  width: 100%;
  margin-bottom: 25px;
}

.exhibit-exporter-header__row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.exhibit-exporter-header__title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-transform: capitalize;
  margin-top: 10px;
}

.exhibit-exporter-header__left-side {
  margin-right: auto;
}

.exhibit-exporter-header__right-side {
  margin-left: auto;
  display: flex;
  gap: 24px;
  max-width: 500px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.reference-numbers__textarea {
  width: 750px;
  height: 100px;
  border: 1px solid var(--ap-color-grey3);
}

.exhibit-exporter__section-content-row.hide {
  display: none;
}

button.exhibit-exporter__btn.ap-button-secondary svg {
  fill: var(--ap-color-green2);
}

button.exhibit-exporter__btn--disabled.exhibit-exporter__btn svg {
  fill: var(--ap-color-grey3);
}

button.exhibit-exporter__previous-exhibits-btn svg {
  fill: var(--ap-color-green2);
}
