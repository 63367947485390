.sub-match-code-edit {
  border: 0;
  width: 100%;
  font-family: Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

.sub-match-code-edit:focus {
  outline: none;
}

input[type='text'].sub-match-code-edit {
  width: 90%;
}

/* Chrome, Safari, Edge, Opera */
.sub-match-code-edit::-webkit-outer-spin-button,
.sub-match-code-edit::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
.sub-match-code-edit[type='number'] {
  appearance: textfield;
}

.claim-matches-grid--cell__submatch-cell input[type='text'].sub-match-code-edit {
  background-color: transparent;
  width: calc(100% - 1.6rem);
  padding: 2px;
}
.claim-matches-grid--cell__submatch-cell svg.ap-icon {
  visibility: hidden;
  padding: 2px;
  transition: none;
}
.claim-matches-grid--cell__submatch-cell input:hover {
  cursor: pointer;
}
.claim-matches-grid--cell__submatch-cell input:hover + svg,
.claim-matches-grid--cell__submatch-cell input:focus + svg {
  visibility: visible;
}
.claim-matches-grid--cell__submatch-cell input:focus + svg,
.claim-matches-grid--cell__submatch-cell
  input[type='text'].sub-match-code-edit:focus {
  background-color: white;
  cursor: initial;
}
