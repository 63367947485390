.single-claim-matrix table {
  border: 0;
  border-spacing: 0px var(--_single-claim-row-space);
  width: 100%;
}

.single-claim-matrix .header .header-label {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  width: 100px;
  text-align: end;
}

.single-claim-matrix .header .header-title {
  padding-bottom: 0;
  font-weight: 500;
}
.single-claim-matrix .header .header-label.empty {
  width: 32px;
}
.single-claim-matrix .header .header-label.total {
  text-align: right;
  padding-right: 8px;
}
.single-claim-matrix .header .header-label.contingent,
.single-claim-matrix .header .header-label.unliquidated,
.single-claim-matrix .header .header-label.disputed {
  width: 70px;
  text-align: center;
}
.single-claim-matrix .row {
  padding: 8px;
  height: 32px;
  margin-bottom: var(--_single-claim-row-space);
}
.single-claim-matrix .row .empty {
  padding: 0;
  height: 16px;
  width: 24px;
}
.single-claim-matrix .row-label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  background: #ffffff;
  padding: 4px 6px;
  width: 23ch;
}
.single-claim-matrix .row-value {
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: end;
}

.single-claim-matrix .row-value > span > input {
  text-align: end;
}

.single-claim-matrix .row-value.editable {
  color: #488929;
}

.single-claim-matrix .row-value.total {
  font-weight: var(--ap-font-weight-xl);
  text-align: right;
}

.single-claim-matrix .row-value.total > span > input {
  font-weight: var(--ap-font-weight-xl);
}

.single-claim-matrix .row-value.contingent span,
.single-claim-matrix .row-value.unliquidated span,
.single-claim-matrix .row-value.disputed span {
  display: flex;
  justify-content: center;
  align-items: center;
}
