.category-select.multiselect--container {
  border: 0;
}

.category-select .multiselect--button,
.category-select .multiselect--text {
  padding-left: 0;
}

.category-select .multiselect--text {
  color: var(--ap-color-green2);
  text-indent: 0;
  font-weight: 400;
  font-size: 13px;
}

.single-claim-overview .multiselect--is-open .multiselect--button,
.single-contract-overview .multiselect--is-open .multiselect--button {
  background: var(--ap-color-green1);
}

.category-select.multiselect--is-open .multiselect--text {
  color: var(--ap-color-ui-white);
  text-indent: 8px;
}

.category-select.multiselect--is-open .ap-icon {
  display: none;
}

.category-select__select {
  outline-style: solid;
  outline-width: 1px;
  outline-offset: -1px;
  outline-color: transparent;
  transition: outline-color 200ms ease-in-out;
  text-indent: 0;
  font-weight: 400;
  font-size: 13px;
  color: var(--ap-color-green2);
  border: 0;
}

.category-select__select:hover,
.category-select__select:focus {
  outline-color: var(--ap-color-ui-green-primary);
}

.category-select__select:disabled {
  outline-color: transparent;
}
.category-select .multiselect--disabled {
  color: var(--ap-color-grey8) !important;
}
.category-select__select.disabled.ap-nice-select__select {
  opacity: 0.7;
  pointer-events: none;
}

.category-select__select:active {
  color: var(--ap-color-primary-text-inverted);
  background-color: var(--ap-color-ui-green-primary);
}

.category-select__select:active > option {
  color: var(--ap-color-ui-green-primary);
  background-color: white;
}

.category-select__select.disabled {
  color: var(--ap-color-grey8) !important;
}

.category-select__multiselect-menu {
  --options-list__menu-item--left-padding: 19px;
  --options-list__menu-item--level-padding: 0px;
}

.category-select__item {
  display: flex;
  align-items: center;
}

.return_turren__icon {
  margin-right: 10px;
}
