.client-name-logo__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.client-name-logo__content-box {
  width: 100%;
  max-width: 1100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.client-name-logo__stepper-container {
  width: 100%;
}

.client-name-logo__step-wizard {
  flex: 7;
}

.client-name-logo__step-wizard,
.setup-page__step-wizard > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-name-logo__footer {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}
