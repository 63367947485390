.claims-panel {
  flex: 1;
  display: flex;
  flex-direction: row;
  background: var(--ap-color-grey2);
  align-items: center;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  border-top: 1px solid var(--ap-color-ui-grey-base);
}
