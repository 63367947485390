.authn-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
}

.authn-error__title {
  display: flex;
  gap: 12px;
  padding-bottom: 16px;
}

.authn-error__title-text {
  font-size: 19px;
}

.authn-error__body {
  padding-bottom: 36px;
}

.authn-error__body-errortype {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 5px;
}
