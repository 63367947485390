.exhibit-history-table {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  --ag-header-column-resize-handle-display: none;
  --ag-secondary-border-color: #5cb335;
  --ag-checkbox-checked-color: var(--client-primary-brand-color);
  --ag-checkbox-unchecked-color: none;
  --ag-selected-row-background-color: rgba(92, 179, 53, 0.3);
  --ag-checkbox-background-color: var(--ag-background-color);
  --ag-checkbox-indeterminate-color: var(--client-primary-brand-color);

  --ag-background-color: var(--ap-color-grey2);
  --ag-foreground-color: #1a1a1a;
  --ag-border-color: var(--ap-color-grey2);

  --ag-secondary-border-color: var(--ap-color-grey2);
  --ag-header-background-color: var(--ap-color-grey2);
  --ag-selected-row-background-color: var(--ap-color-grey2);
  --ag-row-hover-color: var(--ap-color-grey2);
  --ag-odd-row-background-color: var(--ap-color-grey2);
}

.exhibit-history-table .ag-center-cols-viewport {
  overflow-x: hidden;
}

.exhibit-history-table .ag-cell,
.exhibit-history-table .ag-header-cell {
  padding-left: 10px;
}

.exhibit-history-table .ag-header-cell-text {
  font-size: 16px;
  font-family: Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.exhibit-history-table__buttons-cell {
  display: flex;
  align-items: center;
  width: 47px;
  height: 43px;
}

.exhibit-history-table__buttons-cell svg {
  padding: 7px;
  cursor: pointer;
}

.exhibit-history-table__buttons-cell .copy-button {
  width: 46px;
  height: 43px;
}

.exhibit-history-table__buttons-cell .copy-button svg {
  width: 20px;
  height: 20px;
  position: relative;
  left: 3px;
  top: 2px;
}

.exhibit-history__row {
  border-bottom: 1px solid var(--ap-color-grey4);
}

.exhibit-history-table .ag-cell {
  font-size: var(--ap-font-size-xs);
  font-family: Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.exhibit-history-table .ag-header-row {
  border-bottom: 1px solid var(--ap-color-grey8);
  height: 30px;
}

.exhibit-history-table__header-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.exhibit-history-table__header-top__title {
  font-size: var(--ap-font-size-m);
  font-weight: var(--ap-font-weight-xl);
  font-family: Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.exhibit-history-table__header-top__sort-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 125px;
  height: 35px;
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-m);
  font-family: Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  cursor: pointer;
}

.exhibit-history__triangle-chevron--active {
  transform: rotate(180deg);
}
