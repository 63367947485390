div#ap-snack-container > div > div#top-right > div {
  width: 100%;
}

.http-error-messege {
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  color: #ffffff;
  min-height: 26px;
  padding: 16px;
  transition: 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) all;
  width: 100%;
  background-color: #ee3a36;
  pointer-events: auto;
}

.http-error-messege__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2em;
}

.http-error-messege__title {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 6px;
}

.http-error-messege__title > svg.ap-icon {
  flex-shrink: 0;
  flex-grow: 0;
}

.http-error-messege__title > span {
  margin-top: 3px;
}

button.http-error-messege__close {
  width: 32px;
  height: 32px;
}

.http-error-messege__body pre {
  white-space: pre-wrap;
  margin: 1em 0 0 0;
  /* font-size: 13.5px; */
}
