.user-logs__title {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}
.user-logs__title button.ap-button-casual {
  margin-left: auto;
}
.users-content {
  display: flex;
  gap: 20px;
  width: 100%;
}
.users-content__item {
  background-color: var(--ap-color-ui-grey-bg);
  padding: 15px;
  width: 50%;
  max-height: 55vh;
  overflow-y: auto;
}
.users-content__item h4 {
  margin: 0 0 20px;
  text-transform: uppercase;
  font-size: var(--ap-font-size-xs);
}
.users-content__details,
.users-content__title {
  display: flex;
  justify-content: space-between;
}
.users-content__title p {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.users-content__title svg {
  width: 12px;
  margin-left: 8px;
}
.users-content__details {
  display: flex;
  flex-direction: column;
}
.users-content p {
  margin: 5px 0;
  font-size: var(--ap-font-size-xs);
  color: var(--ap-color-ui-grey-dark);
}
.users-content__details p {
  margin: 10px 0;
  font-weight: 700;
  color: black;
}
.users-content__details-item {
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin: 5px 0;
  padding: 0 20px;
}
