.left-slider-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  z-index: 997;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100px;
}

.left-slider-modal__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: auto;
}

.left-slider-modal--animation {
  animation: sidepanel--enter 0.1s ease-out normal;
}

@keyframes sidepanel--enter {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
