.matching-page {
  width: 100%;
  background: var(--ap-color-grey2);
  padding: 2rem 2.5rem;
  display: flex;
  gap: 2rem;
  box-sizing: border-box;
}

.matching-page .matching-page__data {
  background-color: white;
  padding: 2rem;
  flex: 1;
}
.matching-start {
  width: 100%;
}

.matching-start,
.matching-status {
  padding-bottom: 18px;
  border-bottom: 1px solid var(--ap-color-ui-grey-light);
  width: 100%;
}

.matching-process.matching .matching-start__header {
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.matching-start__header button.ap-button-main {
  margin-left: auto;
}
.matching .matching-status__header {
  padding: 0 20px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 1.2rem;
  gap: 1.5rem;
}
.matching .matching-status__header img {
  max-width: 50px;
  display: block;
}
.matching .matching-status__header svg {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}
.matching-status__spinner {
  position: relative;
}
.matching-status__download span {
  display: flex;
  align-items: center;
}
.matching-status__download-icon > div {
  margin-right: 0.7rem;
}
.matching-status__spinner::after {
  /* weird gif border bottom fix */
  content: '';
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0px;
  height: 1px;
}
.matching-status__header p {
  font-weight: 400;
  font-size: var(--ap-font-size-xs);
}
.matching-status__content {
  font-size: var(--ap-font-size-xs);
}
.matching-process.matching .matching-process__buttons {
  margin: 0;
  width: 100%;
}
.matching-process.matching .matching-process__buttons {
  padding: 15px 0;
  flex-direction: column;
  height: auto;
  text-align: center;
}

.matching-process.matching .matching-process__download {
  margin: 10px auto 0;
  max-width: 300px;
}
.matching-process.matching .matching-process__download svg {
  fill: var(--ap-color-green2);
}
.matching-process__download > span {
  display: flex;
  align-items: center;
}
.matching-start__header p {
  border-top: 1px solid white;
  margin-top: -1px;
  position: relative;
  padding-top: 10px;
}
.matching-page button.ap-button-main {
  text-transform: none;
}
.matching-process__content {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--ap-color-ui-grey-darker);
}
.matching-process__content p {
  margin: 10px 0;
}
.matching-process__content button.ap-button-main {
  text-transform: none;
}
.matching-process__buttons {
  height: 64px;
  border-top: 1px solid black;
  display: flex;
  margin-left: -32px;
  margin-right: -32px;
  align-items: right;
}

.matching-process__download {
  margin-top: auto;
  margin-left: auto;
  margin-right: 32px;
  text-transform: none !important;
  line-height: 20px;
}

.matching-process__download-icon {
  margin-right: 8px;
  display: flex;
}

.matching-status__header {
  font-size: var(--ap-font-size-s);
  font-weight: 500;
}
.matching-page__help {
  width: 100%;
  max-width: 360px;
}

.matching-page__help h6 {
  font-size: 13px;
}

.matching-status__error {
  gap: 1rem;
  margin-bottom: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
}
.matching-status__error p {
  margin: 0;
  font-size: var(--ap-font-size-s);
  font-weight: 500;
}
.matching-status__error p span {
  display: block;
  font-size: var(--ap-font-size-xs);
  font-weight: 400;
  margin-top: 0.3rem;
}
.matching-status.publish-failed {
  gap: 1rem;
}
.matching-status.publish-failed p {
  margin: 0.5rem 0 0;
}
