.loading-menu-item {
  display: flex;
  font-size: var(--ap-font-size-xs);
  color: var(--ap-color-grey6);
  align-items: center;
  background: var(--ap-color-green0);
  width: 100%;
  height: 28px;
  padding: 6px 0;
}

.loading-menu-item:first-child {
  margin-top: 8px;
}

/* workaround. "div." is added to the class to increase rule priority */
div.loading-menu-item__animation {
  width: 36px;
  height: 32px;
}
