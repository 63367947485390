.dataupload-content {
  padding: 30px 30px 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.dataupload-content__header {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataupload-content__label {
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.dataupload-content__actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dataupload-content__actions > button.dataupload__button-danger {
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
}

.dataupload-body {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background-color: var(--ap-color-ui-white);
  max-height: 70vh;
  overflow-y: auto;
}

.dataupload-body > div:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
