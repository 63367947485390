.settlements-agreement-templates {
  margin: 0 auto;
  width: clamp(800px, 100%, 1600px);
  display: flex;
  justify-content: space-between;
  padding: 40px;
  gap: 40px;
}

.settlements-agreement-templates__body {
  flex: 1;
  flex-shrink: 0;
  min-width: 450px;
}

.settlements-agreement-templates__body > .settings-title-panel__body {
  padding: 16px;
  gap: 16px;
}

.settlements-agreement-templates__help {
  flex: 0 100 360px;
  min-width: 180px;
}

.settlements-agreement-templates__help > .settings-title-panel__title {
  font-size: var(--ap-font-size-m);
  line-height: var(--ap-font-size-l);
}
