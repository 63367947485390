.data-upload {
  display: flex;
  flex-direction: column;
  height: 1px;
  min-height: 100vh;
}

.data-upload_body {
  display: flex;
  justify-content: space-between;
  background: #e6e6e6;
  flex: 3;
}

.data-upload_body > .dataupload-menu {
  min-width: 150px;
  max-width: 350px;
  flex-basis: 600px;
}

.data-upload_body > .dataupload-help {
  max-width: 350px;
  flex-basis: 650px;
}

.data-upload_body > .dataupload-content {
  flex-basis: 1300px;
}

@media only screen and (max-width: 950px) {
  .data-upload_body {
    display: flex;
    flex-wrap: wrap;
    flex: unset;
    justify-content: center;
  }
  .data-upload_body > .dataupload-menu {
    min-width: 100%;
    flex-basis: unset;
    margin-left: 30px;
    margin-right: 30px;
  }
  .data-upload_body > .dataupload-content {
    width: calc(100% - 60px);
    flex-basis: unset;
  }
  .data-upload_body > .dataupload-help {
    flex-basis: unset;
    width: 100%;
    padding: 0 30px 30px 30px;
  }
}
