.single-contract-subheader {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.single-contract-subheader__tabs {
  display: flex;
  align-items: center;
  margin-top: -16px;
  margin-bottom: 3px;
  flex-grow: 1;
  column-gap: 1rem;
}

.single-contract-subheader__tabs button.single-contract-subheader__tab {
  font-size: var(--ap-font-size-xs);
  letter-spacing: 0.02em;
}

.single-contract-subheader__tabs button.single-contract-subheader__tab.selected {
  color: var(--ap-color-ui-green-primary);
}

.single-contract-subheader__tabs
  button.single-contract-subheader__tab.selected::before {
  border-bottom-width: 4px;
  top: -4px;
}

.single-contract-subheader__tabs .ap-button-group {
  flex: 1;
  display: inline-block;
  box-shadow: 0px 1px 0px #e6e6e6;
}
.single-contract-subheader__tabs
  .ap-button-group.contracts-tool-button-group-approval {
  box-shadow: 0px 1px 0px var(--ap-color-amber2);
}

button.single-contract-subheader__approval {
  align-self: flex-end;
  font-size: var(--ap-font-size-xs);
  font-weight: var(--ap-font-weight-m);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 0.75em;
  padding-block: 0.4em;
  transition: background-color 200ms ease-in-out;
  min-height: 2.7em;

  border: 1px solid var(--ap-color-ui-green-primary);
}

button.single-contract-subheader__approval:hover {
  cursor: pointer;
  background-color: rgba(72, 137, 41, 0.1);
}

button.single-contract-subheader__approval:focus,
button.single-contract-subheader__approval:focus-visible {
  outline: 1px solid var(--ap-color-ui-white);
  outline-offset: -2px;
}

button.single-contract-subheader__approval > svg {
  margin-right: 0.5em;
  width: 1.25em;
}

button.single-contract-subheader__approval-required {
  background-color: var(--ap-color-red2);
  background-color: var(--ap-color-red2);
  color: var(--ap-color-ui-white);
  border: 1px solid var(--app-color-red2);
}

button.single-contract-subheader__approval-required:hover {
  background-color: var(--ap-color-red3);
}
