.exhibit-exporter-top-menu {
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  background: var(--ap-color-white);
  border-bottom: 1px solid var(--ap-color-grey4);
}

.exhibit-exporter-top-menu__sections {
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-m);
  cursor: pointer;
}

.exhibit-exporter-top-menu__sections .ap-button-tab:before {
  box-sizing: border-box;
}

.exhibit-exporter-top-menu__additional {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
