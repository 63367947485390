.data-upload__replace-append-data-modal .ap-modal-header {
  border: none;
  padding-inline: 2em;
}

.data-upload__replace-append-data-modal .ap-modal-body {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  padding-inline: 2em;
  padding-block-start: 0;
}

.data-upload__replace-append-data-modal
  .data-upload__replace-append-data-modal__radio-group {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.data-upload__replace-append-data-modal
  .data-upload__replace-append-data-modal__radio-group
  label {
  line-height: 1.5em;
  font-weight: 400;
  color: #333;
  font-size: 1em;
}

.data-upload__replace-append-data-modal
  .data-upload__replace-append-data-modal__radio-group
  .ap-radio-unchecked {
  fill: #333;
}
