.single-input {
  border: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #488929;
  padding: 8px;
}

.single-input:disabled {
  color: #1a1a1a;
  background-color: #fff;
}

.single-input:focus {
  outline: none;
}

.single-input:not(:disabled):hover {
  outline: 1px solid var(--ap-color-ui-green-primary);
}

.single-input.single-text-area {
  resize: vertical;
}

.single-span {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--ap-color-grey8);
  padding: 8px;
}
