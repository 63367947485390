.modulecard {
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: var(--ap-color-ui-white);
  --_pad: 1.5rem;
  --_title-fs: var(--ap-font-size-m);
  padding: var(--_pad);
  position: relative;
  transition: background-color 300ms ease-in-out;
}

.modulecard-tool {
  background-color: var(--ap-color-grey8);
}

.modulecard__title-text {
  font-size: var(--_title-fs);
  font-weight: var(--ap-font-weight-s);
  display: flex;
  line-height: 1.421;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 0;
  color: inherit;
  border-bottom: 1px solid var(--ap-color-ui-green-primary);
}

.modulecard__title-animated {
  padding: var(--_pad);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: var(--ap-color-black);
  transition: transform 300ms ease-in-out, color 300ms ease-in-out;
}

.modulecard-tool .modulecard__title-animated {
  color: var(--ap-color-ui-white);
}

.modulecard__title-animated svg {
  color: var(--ap-color-black);
  transition: color 300ms ease-in-out;
}

.modulecard-tool .modulecard__title-animated svg {
  color: var(--ap-color-ui-white);
}

a:focus-visible .modulecard__title-animated,
a:hover .modulecard__title-animated {
  color: var(--ap-color-ui-white);
  transform: translate(0px calc(50% - var(--_title-fs) - var(--_pad)));
}

a:focus-visible .modulecard__title-animated svg,
a:hover .modulecard__title-animated svg {
  color: var(--ap-color-ui-green-primary);
}

.modulecard__title-original {
  visibility: hidden;
}

.modulecard__title-original {
  color: var(--ap-color-black);
  transition: color 0ms ease-in-out;
  transition-delay: 300ms;
}

a:focus-visible .modulecard__title-original,
a:hover .modulecard__title-original {
  transition-delay: 0ms;
}

.modulecard__content {
  margin-top: auto;
  padding-top: 0.5612rem;
  color: var(--ap-color-black);
  transition: color 300ms ease-in-out 0ms;
  font-size: var(--ap-font-size-xs);
  line-height: 1.46;
}

.modulecard-tool .modulecard__content {
  color: var(--ap-color-ui-white);
}

a:focus-visible .modulecard__title-original,
a:focus-visible .modulecard__content,
a:hover .modulecard__title-original,
a:hover .modulecard__content {
  color: transparent;
}

a:hover .modulecard {
  background-color: rgba(26, 26, 26, 0.5);
  backdrop-filter: blur(4px);
}

a:focus-visible .modulecard {
  background-color: rgba(26, 26, 26, 0.7);
  backdrop-filter: blur(4px);
}

.moduletip {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-m);
  color: var(--ap-color-ui-white);
  display: grid;
  place-content: center;
  padding: 2rem 1.5rem;
  background-color: var(--ap-color-ui-green-primary);
  letter-spacing: 0.02em;
}
