.help-accordion {
  border: 1px solid var(--greyscale-grey-200, #e6e6e6);
  padding: 22px 0;
}

.help-accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
  padding: 0 22px;
}

.help-accordion__header-text {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-xl);
  cursor: pointer;
  flex: 1;
}

.help-accordion__content {
  margin-top: 22px;
  color: #000;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.accordion-icon-rotate {
  transform: rotate(180deg);
}

.help-accordion__show {
  cursor: pointer;
}
