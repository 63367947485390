/* Added the ui- prefix because "pagination" is a common word and might appear in other CSS rules */
.ui-pagination {
  font-size: var(--ap-font-size-xs);
  --default-unit: 3.1em;
  place-items: center;
}

.ui-pagination-grid {
  display: grid;
}

.ui-pagination__group {
  margin: 0;
  padding: 0;
  border: none;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.ui-pagination__slider {
  margin: 0 auto;
  white-space: nowrap;
}

.ui-pagination__base-control {
  display: inline-block;
  border-radius: 0.15em;
  text-align: center;
  height: var(--ui-component-unit, var(--default-unit));
  line-height: calc(var(--ui-component-unit, var(--default-unit)) - 1px);
  min-width: var(--ui-component-unit, var(--default-unit));
  border: 1px solid transparent;
  cursor: pointer;
}

.ui-pagination__base-control:hover {
  transition: background-color 200ms linear;
  background-color: var(--ap-color-hover);
}

.ui-pagination__current {
  color: var(--ap-bg-surface1);
  background-color: var(--ap-color-ui-green-primary);
}

.ui-pagination__current:hover {
  transition: background-color 200ms linear;
  background-color: var(--ap-color-green2);
}

.ui-pagination__base-control:focus {
  border: 1px solid var(--ap-color-green2);
}

.ui-pagination__base-control:last-child,
.ui-pagination__base-control:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ap-color-ui-green-primary);
}
