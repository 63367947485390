.slip-sheet-delete-modal .ap-modal-header {
  padding-block: 0.5rem;
  padding-inline: 1rem;
  border-bottom: none;
}

.slip-sheet-delete-modal .ap-modal-footer {
  display: flex;
  justify-content: space-between;
  max-height: 3em;
  border: none;
  margin-top: auto;
  display: flex;
}

button.normal-text-case > span {
  text-transform: none;
}

button.slip-sheet-confirm-button {
  border: 1px solid;
  padding: 0px 16px;
}

.slip-sheet-delete-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2rem;
  border: 1rem var(--ap-color-grey2) solid;
  padding: 0.5rem 0;
}

.slip-sheet-delete-info > * {
  flex: 1;
}
