:root {
  --options-list__menu-item--left-padding: 19px;
  --options-list__menu-item--level-padding: 20px;
}

.options-list__menu-item {
  min-height: 48px;
}

.options-list__menu-item:hover {
  background-color: initial !important;
  cursor: initial !important;
}
.options-list__menu-item.isSelectable:hover {
  background-color: #eff8ed !important;
  cursor: pointer !important;
}

.options-list__menu-item__container {
  display: flex;
  align-items: center;
  font-size: 13px;
}
