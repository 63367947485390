.vendors-tool__dashboard {
  flex: 1;
  max-width: 1130px;
  margin: 0 auto;
  padding: 3rem 1rem;
}
.vendors-tool__dashboard .dashboard__userinfo__title {
  padding-block: 1.5rem;
  padding-inline: 2rem;
  background-color: var(--ap-color-ui-green-primary);
  color: white;
}
.vendors-header-tabs {
  background-color: white;
}
.vendors-tool__dashboard p {
  color: white;
  margin: 1.5rem 0 1rem;
}
