.batchupload-panel {
  display: flex;
  flex-direction: column;
}

.batchupload-panel__label {
  margin-bottom: 10px;
  font-size: var(--ap-font-size-xs);
  font-weight: var(--ap-font-weight-s);
}

.batchupload-panel__error-title {
  display: flex;
  align-items: center;
  font-size: var(--ap-font-size-xs);
  color: #d97706;
  font-weight: 500;
  margin: 0 0 10px;
}

.batchupload-panel__error-title button {
  background-color: transparent;
  border: none;
  color: #d97706;
  margin: 0;
  padding: 0 0 0 0.5em;
  text-decoration: underline;
  font-size: var(--ap-font-size-xs);
  font-weight: 700;
  cursor: pointer;
}

.batchupload-panel__error-title svg {
  margin-right: 5px;
}

.batchupload-panel__uploading {
  padding-top: 1em;
  display: flex;
  text-wrap: nowrap;
  gap: 1em;
  align-items: center;
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-m);
}

.batchupload-panel__uploading > .ap-loader-default {
  flex: 0;
}
