.table-section {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table-section__content {
  flex: 1;
}

.table-section .ag-row {
  border-bottom: 0;
}

.table-section .ag-header-cell:after {
  display: block;
  content: '';
  width: 1px;
  height: calc(200% - 24px);
  margin-top: 12px;
  background-color: var(--ap-color-grey6);
}

.table-section .ag-header-cell:last-of-type:after {
  display: none;
}

.table-section__body {
  height: 100%;
  margin: 0 auto;
}

.ag-cell.legalEntityId {
  text-transform: uppercase;
}

/* 
  At this momment all fields bellow are in UPPERCASE
  todo: change fields to Capitalized format at the Back-end side
*/
.datatype-schedule .ag-cell.assetValueTerm,
.datatype-schedule .ag-cell.typeOfInvestment,
.datatype-schedule .ag-cell.typeOfAsset,
.datatype-schedule .ag-cell.typeOfFarmAsset,
.datatype-schedule .ag-cell.typeOfFurnitureAsset,
.datatype-schedule .ag-cell.typeOfMotorAsset,
.datatype-schedule .ag-cell.typeOfIntangibleAsset,
.datatype-schedule .ag-cell.typeOfContingentAsset,
.datatype-schedule .ag-cell.assetValueType {
  text-transform: lowercase;
}

.ap-modal .ap-button-group button {
  font-size: 13px;
}
