.single-contract-matches {
  overflow-x: hidden;
  padding: 24px 32px 37px;
}

.single-contract-matches > .section__title {
  text-transform: uppercase;
}

.single-contract-matches-grid {
  border-spacing: 0 8px;
  margin: -8px 0;
  width: 100%;
}

.contract-matches-grid--header th {
  font-weight: 400;
  font-size: 11px;
}

.contract-matches-grid--row {
  position: relative;
  background: #fff;
  font-weight: 400;
  font-size: 11px;
  height: 32px;
}

.contract-matches-grid--header > th {
  padding: var(--cell-padding);
  text-align: start;
  padding: 0.25rem 0.5rem;
}

.contract-matches-grid--cell {
  max-width: 9rem;
  max-height: 2.5rem;
  padding: 0.25rem 0.5rem;
  text-align: start;
}

.contract-matches-grid--cell-title {
  max-height: 2.5rem;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
}

.contract-matches-grid--cell-bold {
  display: inline-flex;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
}

.hover-data {
  position: absolute;
  top: 3px;
  z-index: 999;
  background-color: white;
  font-size: var(--ap-font-size-xxs);
  line-height: 18px;
  padding: 12px;
  box-shadow: 0px 4px 50px -12px rgba(0, 0, 0, 0.35);
  width: fit-content;
  height: fit-content;
  max-width: 40%;
}