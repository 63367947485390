.claims-reports {
  width: 100%;
  flex: 1;
  padding: 2rem;
  box-sizing: border-box;
  overflow-y: scroll;
  background: var(--ap-color-grey2);
  display: grid;
  grid-template-columns: repeat(auto-fit, 23.5rem);
  gap: 3rem;

  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--ap-color-ui-grey-base);
}
