.page-button-group {
  padding: 0 12px 0 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  background: var(--ap-color-white);
  border-bottom: 1px solid #999;
}

.page-button-group__buttons {
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-m);
  cursor: pointer;
}

.page-button-group__buttons .ap-button-tab:before {
  box-sizing: border-box;
}

.page-button-group__buttons__additional {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
