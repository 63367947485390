.div-extended {
  position: relative;
  width: auto;
}

.div-extended__disabled {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: #ffffff88;
}
