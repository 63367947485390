.claimsmatching-summary-grid {
  position: relative;
  --ag-header-background-color: #fff;
  --ag-header-column-resize-handle-color: #fff;
  --ag-header-foreground-color: #000;
  --ag-border-color: #ffff;
  --ag-row-border-color: #e6e6e6;
}

.claimsmatching-summary-grid .ag-header-cell {
  font-size: 13px;
}
