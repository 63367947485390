.single-claim-matches {
  overflow-x: scroll;
  padding: 16px 0;
}

.single-claim-matches > .section__title {
  text-transform: uppercase;
}

.single-claim-matches {
  padding: 16px 0;
}

.single-claim-matches > .section__title {
  text-transform: uppercase;
}

.single-claim-matches-grid {
  border-spacing: 0 8px;
  margin: -8px 0;
  width: 100%;
}

.claim-matches-grid--header th {
  font-weight: 400;
  font-size: 11px;
  font-weight: 400;
}

.claim-matches-grid--row.claim-matches-grid--title-row {
  background: #93d676;
}

.claim-matches-grid--row {
  background: #fff;
  font-weight: 400;
  font-size: 11px;
  height: 32px;
}

.claim-matches-grid--header > th {
  padding: var(--cell-padding);
  padding: var(--cell-padding);
  text-align: start;
  padding: 0.25rem 0.5rem;
}

.claim-matches-grid--cell {
  max-height: 2rem;
  padding: 0.25rem 0.5rem;
  text-align: start;
}

.claim-matches-grid--cell {
  max-height: 2rem;
  padding: 0.25rem 0.5rem;
  text-align: start;
}

.claim-matches-grid--title-row > td > .claim-matches-grid--cell-ref {
  color: #4d4d4d;
}

.claim-matches-grid--cell-ref {
  text-decoration: underline;
  display: inline-flex;
  color: #5cb335;
  font-weight: 500;
  font-size: 13px;
}

.claim-matches-grid--cell-conterparty {
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

.claim-matches-grid--cell-submatchcode {
  font-weight: 400;
  max-width: fit-content;
}
