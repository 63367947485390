.single-entity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-entity-header-subtitle h4 {
  font-size: var(--ap-font-size-l);
  font-weight: var(--ap-font-weight-s);
  line-height: 1.22;
  margin-block-start: 0;
  margin-block-end: 0;
}

.single-entity-header__title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  margin-top: 18px;
}
.single-entity-header__left-pane {
  flex: 2;
}

.single-entity-header__claimsinfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.single-entity-header__right-pane {
  display: grid;
  row-gap: 1rem;
  column-gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 3;
}

.single-entity-header__right-pane__row {
  display: flex;
  column-gap: 1.5rem;
  row-gap: inherit;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.single-entity-header__action-status-container {
  position: relative;
  top: 5px;
}
.single-entity-header__action-status {
  height: 20px;
  display: flex;
  padding: 2px 6px;
  border-radius: 16px;
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  width: 100%;
  min-width: calc(13.3em - 2 * 6px);

  gap: 8px;
  justify-content: center;
  align-items: center;
}

.single-entity-header__action-status {
  background: #0696a6;
}
.single-entity-header__action-status.scheduled-claim {
  background: #006176;
}
.single-entity-header__action-status.filed-claim {
  background: #0696a6;
}
.single-entity-header__action-status.matches-found {
  background: #ff600f;
}
.single-entity-header__action-status.matched {
  background: #008f0a;
}
.single-entity-header__action-status.categorized-claim {
  background: #595959;
}
.single-entity-header__action-status.assigned-claim {
  background: #15723b;
}
.single-entity-header__action-status.claim-withdrawn {
  background: #ee3a36;
}
.single-entity-header__action-status.client-reconciled-claim {
  background: #032e45;
}
.single-entity-header__action-status.alix-reconciled-claim {
  background: #5cb335;
}
.single-entity-header__action-status.matching-complete {
  background: #008f0a;
}
.single-entity-header__action-status.objection {
  background: #f9a12a;
}
.single-entity-header__action-status.order-vacated {
  background: #bc1310;
}
.single-entity-header__action-status.settlment-pending-claim {
  background: #77b0be;
}
.single-entity-header__action-status.settlment {
  background: #77b0be;
}
.single-entity-header__action-status.voided-claim {
  background: #bc1310;
}
.single-entity-header__action-status.trumped-schedule {
  background: #ff600f;
}
.single-entity-header__action-status.zero-value-scheduled-only {
  background: #e6e6e6;
}
.single-entity-header__action-status.allowable-allowed {
  background: #e6e6e6;
}

.single-entity-header__current-debtor-container > .header-item__value {
  text-align: end;
}
.action-status-pill .header-item__value {
  display: flex;
  padding: 0.27em 0.5em;
  border-radius: 16px;
  min-width: calc(14.5em - 2 * 0.5em);
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border: 1px solid;
  font-size: 12px;
  line-height: 1.1818;
  box-sizing: content-box;
  outline-color: transparent;
}

.action-status-pill .header-item__value {
  border-color: tomato;
  background-color: #0696a6;
}
.action-status-pill.scheduled-claim .header-item__value {
  background-color: #006176;
  border-color: #006176;
  color: #fff;
}
.action-status-pill.filed-claim .header-item__value {
  background-color: #0696a6;
  border-color: #0696a6;
  color: #fff;
}
.action-status-pill.matches-found .header-item__value {
  background-color: #ff600f;
  border-color: #ff600f;
  color: #fff;
}
.action-status-pill.matched .header-item__value {
  background-color: #008f0a;
  border-color: #008f0a;
  color: #fff;
}
.action-status-pill.categorized-claim .header-item__value {
  background-color: #595959;
  border-color: #595959;
  color: #fff;
}
.action-status-pill.assigned-claim .header-item__value {
  background-color: #15723b;
  border-color: #15723b;
  color: #fff;
}
.action-status-pill.claim-withdrawn .header-item__value {
  background-color: #ee3a36;
  border-color: #ee3a36;
  color: #fff;
}
.action-status-pill.client-reconciled-claim .header-item__value {
  background-color: #032e45;
  border-color: #032e45;
  color: #fff;
}
.action-status-pill.alix-reconciled-claim .header-item__value {
  background-color: #5cb335;
  border-color: #5cb335;
  color: #fff;
}
.action-status-pill.matching-complete .header-item__value {
  background-color: #008f0a;
  border-color: #008f0a;
  color: #fff;
}
.action-status-pill.objection .header-item__value {
  background-color: #f9a12a;
  border-color: #f9a12a;
  color: var(--ap-color-grey6);
}
.action-status-pill.order-vacated .header-item__value {
  background-color: #bc1310;
  border-color: #bc1310;
  color: #fff;
}
.action-status-pill.settlement-pending-claim .header-item__value {
  background-color: #77b0be;
  border-color: #77b0be;
  color: var(--ap-color-grey8);
}
.action-status-pill.settlement .header-item__value {
  background-color: #77b0be;
  border-color: #77b0be;
  color: var(--ap-color-grey8);
}
.action-status-pill.voided-claim .header-item__value {
  background-color: #bc1310;
  border-color: #bc1310;
  color: #fff;
}
.action-status-pill.trumped-schedule .header-item__value {
  background-color: #ff600f;
  border-color: #ff600f;
  color: var(--ap-color-grey8);
}
.action-status-pill.zero-dollar-value-scheduled-only .header-item__value {
  background-color: #e6e6e6;
  border-color: var(--ap-color-ui-grey-base);
  color: var(--ap-color-grey6);
}
.action-status-pill.allowable-allowed .header-item__value {
  background-color: #fff;
  border-color: var(--ap-color-ui-green-primary);
  color: var(--ap-color-green2);
}
