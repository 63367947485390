.sofa-modal-download-template {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  width: unset;
}

/* .sofa-modal-download-template__checkbox {
  padding-bottom: 1rem;
} */

.sofa-modal-download-template__checkbox > label {
  font-weight: 700;
  font-size: 13px;
  color: #333333;
}
