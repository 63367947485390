.data-upload__duplicates-display-modal .ap-modal-header {
  border-bottom: none;
  padding-block-start: 2em;
  padding-inline: 1.5em;
  padding-block-end: 0;
}

.data-upload__duplicates-display-modal .ap-modal-body {
  max-height: 486px;
  padding: 1.5em;
  border-bottom: none;
}

.data-upload__duplicates-display-modal .ap-modal-footer {
  border-top: none;
  padding-block-start: 0;
}

.data-upload__duplicates-display-modal__duplicates-rows {
  display: grid;
  grid-template-columns: 8em 8em auto;
  grid-auto-rows: 2em;
  line-height: 2em;
}

.data-upload__duplicates-display-modal__duplicates-header-cell {
  font-size: 0.75em;
  border-bottom: 1px solid #ccc;
}

.data-upload__duplicates-display-modal__duplicates-cell {
  border-bottom: 1px solid #ccc;
}

.data-upload__duplicates-display-modal__duplicates-cell-bold {
  font-weight: 700;
  border-bottom: 1px solid #ccc;
}
