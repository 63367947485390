.preview-sub-header {
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2px var(--ap-items-spacing) 2px;
  height: 47px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--ap-color-ui-grey-base);
  gap: 8px;
}

.preview-sub-header__label {
  color: #737373;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 18px;
}
