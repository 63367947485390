.exhibit-select > label {
  color: var(--ap-color-grey5);
  font-size: var(--ap-font-size-xs);
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 4px;
}

.exhibit-select__container {
  background: var(--ap-color-white);
  border: 1px solid var(--ap-color-grey3);
  height: 40px;
}

.exhibit-select__container > .ap-nice-select__arrow {
  top: 3px;
}
