.help-topics-box {
  padding: 24px;
  background-color: white;
}

.help-topics-box > header {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  margin-bottom: 15px;
}

.help-module-box {
  padding: 32px 48px;
}

.help-module-box > header {
  font-size: 33px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;

  margin-bottom: 32px;

  display: flex;
  align-items: center;
  gap: 1em;
}

.help-module-box > header > * {
  flex: 1;
}

.help-search-box {
  padding: 36px 48px 48px 48px;
  background-color: #e6e6e6;
}

.help-search-box > header {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  margin-bottom: 24px;

  display: flex;
  align-items: center;
  gap: 1em;
}

.help-search-box > header > * {
  flex: 1;
}
