.claims-reports__generate-by-entity {
  display: flex;
  gap: 0.5rem;
}

.claims-reports .claims-reports__generate-by-entity > .ap-button-main {
  height: 2rem;
  min-width: 10rem;
  text-transform: capitalize;
}
.claims-reports .claims-reports__generate-by-entity > .ap-button-main > span {
  display: flex;
  align-items: center;
}
.claims-reports__generate-by-entity > .ap-button-main .ap-loader-default {
  margin-left: 5px;
}
.claims-reports__generate-by-entity > .ap-button-main .ap-loader-default > div {
  border: 2px solid var(--ap-color-green2);
  border-top-color: white;
}
.claims-reports__generate-by-entity .multiselect--button {
  /* -2px for the border */
  height: calc(2rem - 2px);
}
.claims-reports__generate-by-entity .multiselect--text {
  max-width: 130px;
}
