.settlement-agreemant-upload {
  background-color: var(--ap-color-white);
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.settlement-agreemant-upload > .fileupload-section {
  min-height: 80px;
}

.settlement-agreemant-upload-template {
  color: var(--ap-color-grey5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  font-size: var(--ap-font-size-xs);
}

.settlement-agreemant-upload-template > span {
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.settlement-agreemant-fileinfo {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--ap-color-white);
  padding: 22px;
}

.settlement-agreemant-fileinfo__body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.settlement-agreemant-fileinfo__filename {
  font-size: var(--ap-font-size-s);
  font-weight: 500;
  color: var(--ap-color-ui-green-primary);
  overflow-wrap: anywhere;
}

.settlement-agreemant-fileinfo__createdby {
  font-size: var(--ap-font-size-xs);
  color: var(--ap-color-grey5);
}

.settlement-agreemant-fileinfo__buttons {
  flex: 0 1 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settlement-agreemant-fileinfo__separator {
  width: 1px;
  height: 32px;
  background-color: var(--ap-color-grey3);
}
