.accordion-section {
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.accordion-section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;

  cursor: pointer;
}

.accordion-section__header-text {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-xl);
}

.accordion-icon-rotate {
  transform: rotate(180deg);
}

.accordion-section__show {
  cursor: pointer;
}
