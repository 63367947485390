.claims-table__row-error {
  background-color: #fef2f2 !important;
}

.claim-schedule-number_novalue {
  text-transform: lowercase;
  color: red;
  display: flex;
  align-items: center;
  gap: 0.35em;
}

.exhibit-contracts__grid-header__claims-number {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
