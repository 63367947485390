.schedules-upload .fileupload-panel .fileupload-panel__caption {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
  position: relative;
}
.schedules-upload .fileupload-panel .fileupload-panel__caption .ap-button-secondary {
  position: absolute;
  right: 00px;
  border: 1px solid var(--ap-color-green2);
  color: var(--ap-color-green2);
  text-transform: capitalize;
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
}
.schedules-upload .fileupload-panel .fileupload-panel__dragdrop {
  margin: 5px 0 15px;
  padding: 16px 24px;
}
.schedules-upload label {
  font-weight: 700;
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
}

.dataupload-content__label
  .ap-button-danger.dataupload__button-danger
  .ap-loader-default
  > div {
  margin-left: 8px;
  border-top-color: var(--ap-color-ui-red-alert);
}
.schedules-upload .progress-bar {
  width: 100%;
}
