.help-search-results {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.help-search-results__results {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.help-search-results__body {
  padding: 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
}

.help-search-results__body > header {
  color: #000;
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
}

.help-search-results__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.help-search-results__list-item {
  cursor: pointer;

  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border: 1px solid var(--greyscale-grey-200, #e6e6e6);

  color: var(#1a1a1a);
  font-size: 16px;

  font-weight: 400;
  line-height: 20px;
}

.help-search-results__list-item > header {
  color: #000;
  font-size: 19px;

  font-weight: 500;
  line-height: 24px;
}

.help-search-results__list-item > footer {
  color: #000;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
}
