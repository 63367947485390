:root {
  --ap-app-bar-height: 52px;

  /* fonts */
  --ap-font-size-xxxl: 83px;
  --ap-font-size-xxl: 33px;
  --ap-font-size-xl: 28px;
  --ap-font-size-l: 23px;
  --ap-font-size-m: 19px;
  --ap-font-size-s: 16px;
  --ap-font-size-xs: 13px;
  --ap-font-size-xxs: 11px;
  --ap-font-weight-xxl: 800;
  --ap-font-weight-xl: 700;
  --ap-font-weight-l: 600;
  --ap-font-weight-m: 500;
  --ap-font-weight-s: 400;
  --ap-font-weight-xxs: 300;
  --ap-font-family: var(--font-family);

  /* colors */
  --ap-color-ui-green-dark: #478b36;
  --ap-color-ui-green-base: #519f3e;
  --ap-color-ui-green-light: rgba(92, 178, 71, 1);
  --ap-color-ui-green-light-triplet: 92, 178, 71;
  --ap-color-ui-green-lighter: rgb(111, 192, 91);
  --ap-color-ui-green-light-semi: rgba(92, 178, 71, 0.3);
  --ap-color-ui-green-lightest: #f6fbf5;
  --ap-color-ui-green-hover: #eff8ed;
  --ap-color-ui-green-light-r: 92;
  --ap-color-ui-green-light-g: 178;
  --ap-color-ui-green-light-b: 71;
  --ap-color-ui-green-light-059: rgba(
    var(--ap-color-ui-green-light-r),
    var(--ap-color-ui-green-light-g),
    var(--ap-color-ui-green-light-b),
    0.59
  );
  --ap-color-red1-rgb: 238, 58, 54;
  --ap-color-ui-green-primary: #5cb335;
  --ap-color-ui-white: #ffffff;
  --ap-color-ui-white-rgb: 255, 255, 255;
  --ap-color-ui-white-r: 255;
  --ap-color-ui-white-g: 255;
  --ap-color-ui-white-b: 255;
  --ap-color-ui-grey1: #f7f7f7;
  --ap-color-ui-grey-lightest: #fafafa;
  --ap-color-ui-grey-lighter: #eeeeee;
  --ap-color-ui-grey-light-alternative: #dddddd;
  --ap-color-ui-grey-light: #cccccc;
  --ap-color-ui-grey-light-triplet: 204, 204, 204;
  --ap-color-ui-grey-base: #999999;
  --ap-color-ui-grey-dark: #737373;
  --ap-color-ui-grey-darker: #333333;
  --ap-color-ui-grey-darker-r: 118;
  --ap-color-ui-grey-darker-g: 118;
  --ap-color-ui-grey-darker-b: 118;
  --ap-color-ui-red-alert: #e95959;
  --ap-color-ui-red-alert-triplet: 233, 89, 89;
  --ap-color-ui-red-alert-semi: rgba(233, 89, 89, 0.3);
  --ap-color-ui-red-alert-light: #ffeded;
  --ap-color-ui-orange: #fa9000;
  --ap-color-ui-orange-triplet: 250, 144, 0;
  --ap-color-ui-orange-semi: rgba(250, 144, 0, 0.3);
  --ap-color-ui-orange-warning: #faa229;
  --ap-color-ui-yellow: rgba(255, 221, 0, 1);
  --ap-color-ui-yellow-triplet: 255, 221, 0;
  --ap-color-ui-amber: #f9a82a;
  --ap-color-ui-yellow-semi: rgba(255, 221, 0, 0.3);
  --ap-color-ui-grey-bg: #f2f2f2;
  --ap-color-ui-blue: #2d93d2;
  --ap-color-ui-blue-2: #0091ff;
  --ap-color-ui-blue-3: #0883c9;
  --ap-color-ui-blue-dark: #2e83b8;
  --ap-color-ui-lightblue: #f6fdff;
  --ap-color-ui-lightblue-hsla: hsl(193deg 100% 50% / 5%);
  --ap-color-ui-purple: #7053c6;
  --ap-color-ui-purple-dark: #6346b9;
  --ap-color-ui-violet: #b373d0;
  --ap-color-ui-violet-dark: #8a40bf;
  --ap-color-ui-teal: #25b4c1;
  --ap-color-ui-teal-dark: #249da8;

  --ap-color-green0: #daf1d0;
  --ap-color-green1: #5cb335;
  --ap-color-green2: #488929;
  --ap-color-green3: #33621d;
  --ap-color-dark: #254715;
  --ap-color-lightGreen1: #ecf3ea;
  --ap-color-lightGreen2: #dae7d5;
  --ap-color-lightGreen3: #b7d0ac;
  --ap-color-red1: #b91c1c;
  --ap-color-red2: #b81430;
  --ap-color-red3: #8a0f24;
  --ap-color-red4: #d78585;
  --ap-color-red5: #ee3a36;
  --ap-color-red300: #fca5a5;
  --ap-color-lightRed: #fef2f2;
  --ap-color-pink1: #eddcdc;
  --ap-color-pink2: #e8c6c5;
  --ap-color-pink3: #dc9997;
  --ap-color-amber: #f9a12a;
  --ap-color-amber2: #d98615;
  --ap-color-amber3: #a1610a;
  --ap-color-amber4: #fef3c7;
  --ap-color-orange: #d8a14c;
  --ap-color-grey2: #e6e6e6;
  --ap-color-grey3: #cccccc;
  --ap-color-grey4: #999999;
  --ap-color-grey5: #737373;
  --ap-color-grey6: #4d4d4d;
  --ap-color-grey7: #333333;
  --ap-color-grey8: #1a1a1a;
  --ap-color-grey9: #99a5af;
  --ap-color-white: #ffffff;
  --ap-color-black: #000000;
  --ap-color-blue: #6babc7;
  --ap-color-blue1: #77b4e1;
  --ap-color-transparent: rgba(0, 0, 0, 0);
  --ap-color-lightbox: rgba(50, 50, 50, 0.6);
  --ap-color-purple0: #b67ac5;
  --ap-color-chartDarkBlue: #032e45;
  --ap-color-chartLightBlue: #77b0be;
  --ap-color-chartBrightTeal: #0696a6;
  --ap-color-chartMediumGrey: #adafb2;
  --ap-color-chartOrange: #ff600f;
  --ap-color-chartDarkGreen: #15723b;
  --ap-color-chartDarkTeal: #006176;
  --ap-color-chartLightOrange: #ff910f;
  --ap-color-chartMediumGreen: #008f0a;
  --ap-color-chartDarkGrey: #595959;

  --ap-border-padding: 32px;
  --ap-items-spacing: 12px;

  --ap-bg-surface1: var(--ap-color-ui-white);
  --ap-bg-surface2: #f8f4f4;

  --ap-color-text: #000000;
  --ap-color-text-inverted: #fff;
  --ap-color-primary-text-inverted: #fff;

  /* inspired from the tabs */
  --ap-color-hover: rgba(72, 137, 41, 0.1);
}

.link-text {
  color: var(--ap-color-ui-green-primary);
  cursor: pointer;
}

.link-text:hover {
  text-decoration: underline;
}

:root {
  /* setting semantic variables used by app components*/
  --plaincard--fs: var(--ap-font-size-s);
  --plaincard--fw: var(--ap-font-weight-m);
  --plaincard--bg: var(--ap-bg-surface1);
  --plaincard--accent: var(--ap-color-ui-green-primary);
  --plaincard--color: var(--ap-color-black);
  --plaincard--bg-focus: rgba(51, 51, 51, 0.3);
  --plaincard--bg-focus-blur: 9.5px;
  --plaincard--bg-hover: rgba(115, 115, 115, 0.3);
  --plaincard--bg-hover-blur: 9.5px;

  --simple-table--header-fs: var(--ap-font-size-xxs);
  --simple-table-margin-block-start: 1rem;
  --simple-table--cell-padding-inline: 1em;
  --simple-table--cell-padding-block: 0.55em;
  --simple-table-bg: var(--ap-color-ui-grey-bg);
}

/* 
  ApToggleSwitch style reset 
  using id selector for specificity
*/
#root input[type='checkbox'] + .ap-toggle-switch {
  background-color: var(--ap-color-ui-grey-base);
  border-color: transparent;
  height: 18px;
}
#root input[type='checkbox']:checked + .ap-toggle-switch {
  background-color: var(--ap-color-ui-green-primary);
}
#root input[type='checkbox'] + .ap-toggle-switch::before {
  bottom: 1px;
  width: 12px;
  height: 12px;
  background-color: white;
}
#root input[type='checkbox']:disabled + .ap-toggle-switch {
  opacity: 0.3;
}

/** Buttons */
#root button.ap-button-main,
#root button.ap-button-secondary,
#root button.ap-link-button-secondary {
  padding-right: 16px;
}

#root button.ap-button-tab {
  font-size: 13px;
}

/* Ag-grid toolpanel customization */
.ag-tool-panel-wrapper .ag-drag-handle {
  display: none;
}
.ag-tool-panel-wrapper .ag-column-select-header {
  padding: 40px var(--ag-widget-container-horizontal-padding);
  border-bottom: none;
}
.ag-tool-panel-wrapper .ag-input-wrapper.ag-text-field-input-wrapper {
  position: relative;
}
.ag-tool-panel-wrapper
  .ag-input-wrapper.ag-text-field-input-wrapper
  .ag-text-field-input {
  margin-top: 35px;
}
.ag-tool-panel-wrapper .ag-input-wrapper.ag-text-field-input-wrapper::before {
  content: 'New added columns will appear on the right side of the table';
  position: absolute;
  top: 0px;
  font-size: 11px;
}
.ag-side-bar .ag-column-select-header {
  position: relative;
  margin-bottom: 20px;
}
.ag-side-bar .ag-column-select-header-checkbox {
  position: absolute;
  bottom: -17px;
}
.ag-side-bar .ag-column-select-header-checkbox::after {
  content: 'Select all';
  width: auto;
  padding-left: 12px;
  font-size: var(--ap-font-size-xxs);
}
