div.matching__prev-uploaded {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 0 1rem;
}

.matching__prev-uploaded-file {
  margin-bottom: 2px;
  background-color: white;
  padding: 0 1rem;
  flex-wrap: wrap;
}
.matching__prev-uploaded-file .right-content {
  margin-left: auto;
}
.matching__prev-uploaded-file .right-content span {
  border-right: 1px solid #ccc;
  padding: 0 0.7rem;
}
.matching__prev-uploaded-file .right-content button.c11-buttonicon {
  border-radius: 0;
  border-right: 1px solid #ccc;
}
.matching__prev-uploaded-file .right-content button:last-of-type {
  border: none;
}
.matching__prev-uploaded-file .errors-content {
  width: 100%;
}
.matching__prev-uploaded-file > div {
  font-size: var(--ap-font-size-xs);
  color: var(--ap-color-grey5);
  margin: 0.8rem 0;
}
.matching__prev-uploaded-file .left-content svg {
  padding-right: 0.7rem;
}
.matching__prev-uploaded-file .right-content button:last-of-type svg {
  color: var(--ap-color-red1);
}
.matching__prev-uploaded-file .file-panel__show-hide__button {
  padding: 0;
}
.matching__prev-uploaded-file .file-panel__show-hide__button > span {
  gap: 10px;
  display: flex;
  align-items: center;
}
.matching__prev-uploaded-file .file-panel__show-hide__button span.errors-count {
  font-size: var(--ap-font-size-xxs);
  color: var(--ap-color-grey5);
  text-transform: lowercase;
}
.matching__prev-uploaded-file .file-panel__error {
  display: block;
}
.matching__prev-uploaded-file .file-panel__chevron svg {
  padding: 0;
}
.matching-page .ap-button-link.file-panel__download {
  text-transform: none;
  padding: 0 0 5px;
  height: auto;
  font-weight: 700;
}
.matching-page .ap-button-link.file-panel__download.unpublished {
  color: var(--ap-color-grey5);
}
.matching-page .ap-button-link.file-panel__download.hasError {
  color: var(--ap-color-red5);
}
.matching-page .ap-button-link.file-panel__download:hover {
  background: none;
}
