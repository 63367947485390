.details-accordion {
  width: 100%;
  max-width: 50%;
  padding: 10px;
  background-color: #fff;
  margin: 2.25rem 0 0;
  pointer-events: none;
}
.details-accordion summary {
  display: flex;
  align-items: center;
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
}
.details-accordion__left {
  color: var(--ap-color-ui-green-primary);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  pointer-events: all;
}
.details-accordion__right {
  margin-left: auto;
}
.details-accordion p {
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
}
