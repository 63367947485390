.browserfilerow {
  display: flex;
  align-items: center;
  gap: 1em;
}

.browserfilerow__delete-btn  {
  margin-left: auto;
}

.browserfilerow__delete-btn > button {
  border-radius: 0;
  color: var(--ap-color-grey4);
}

.browserfilerow__name {
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-s);
  color: var(--ap-color-ui-green-primary);
}

.browserfilerow__icon {
  line-height: 0;
}

.browserfilerow__icon svg {
  color: var(--ap-color-grey4);
  fill: currentColor;
}