.preview-enumerator {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  gap: 1rem;
  align-items: center;
  /* stretch in parent grid */
  align-self: stretch;

  font-size: var(--ap-font-size-xs);
  line-height: 14px;
  color: var(--ap-color-grey8);
  font-weight: var(--ap-font-weight-s);
}

.preview-enumerator .action-list-item__actions__divider {
  /* stretch in parent flex */
  align-self: stretch;
}

.preview-enumerator__info {
  width: calc(50% - 1px);
}

.preview-enumerator__info:first-child {
  text-align: end;
}
