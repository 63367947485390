.batcheditor-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;

  justify-content: stretch;
  overflow: hidden;
  width: 800px;
}

.batcheditor-form--title {
  color: #333;
  font-size: 16px;
  font-weight: 400;

  margin-top: 20px;
}

.batcheditor-form--body {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;

  /* set margin and padding for better looking scrollbar */
  margin: 0 -10px;
  padding: 0 10px;
}

.batcheditor-form--addpanel {
  display: flex;
  justify-content: flex-start;
  margin-right: 83px;
}

.batcheditor-form--footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.batcheditor-form--footer > button,
.batcheditor-form--addpanel > button {
  text-transform: unset;
}
