.error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 48px;
  padding: 40px;
}

.error-boundary__title {
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 8px;
  padding: 10px 0;
}

.error-boundary__title-text {
  font-size: 20px;
}

.error-boundary__body {
  padding-bottom: 20px;
}

.error-boundary__body-text {
  font-size: 17px;
}

.error-boundary__view-details {
  background: none;
  font-size: 13px;
  appearance: none;
  border: none;
  color: #478b36;
  cursor: pointer;
  padding-bottom: 5px;
}

.error-boundary__stack-trace {
  font-size: 12px;
  background: #f5f5f5;
  text-align: left;
  padding: 5px;
  white-space: pre;
  line-height: 20px;
}
