header.header-navbar {
  display: flex;
  min-height: 52px;
  padding: 0 32px;
  --spacing: 12px;
}

.header-navbar_logo * {
  display: flex;
  align-items: center;
  gap: var(--spacing);
  overflow: hidden;
}

.header-navbar_image {
  display: static;
  max-height: 28px;
}

.header-navbar_main-logo {
  background-image: url('../../img/ap-logo-short.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: 'white';
  content: '';
  width: 20px;
  height: 20px;
}

.header-navbar_logo {
  display: flex;
  align-items: center;
  gap: calc(2 * var(--spacing));
  font-size: 17px;
  line-height: 18px;
  font-weight: 400;
  box-sizing: border-box;
}

.header-navbar_logolink {
  cursor: pointer;
  text-decoration: none;
  color: var(--ap-color-ui-white);
}

.header-navbar_clientname {
  color: var(--ap-color-ui-white);
  font-size: 16px;
}

.header-navbar_client {
  height: 16px;
}

.header-navbar_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing);
}

.header-navbar_image_wrapper {
  width: 104px;
  height: 26px;
}
