.inputcheckbox {
  position: relative;
  padding: 0.8em;
  display: block;
  color: var(--ap-color-grey8);
  font-size: var(--ap-font-size-xs);
  transition: background-color 200ms ease-in-out;
}

.inputcheckbox-disabled {
  opacity: 0.5;
}

.inputcheckbox:hover {
  background-color: var(--ap-color-hover);
}
.inputcheckbox-disabled:hover {
  background-color: transparent;
}

.inputcheckbox__input {
  /* display none or visibility hidden will nuke any keyboard nav, better avoid */
  height: 0;
  position: absolute;
}

.inputcheckbox .box {
  padding-inline: 0em 1em;
}

.inputcheckbox[data-type='checkbox'] .emptybox {
  color: var(--ap-color-ui-grey-dark);
}

.inputcheckbox[data-type='checkbox'] .checkbox {
  color: var(--ap-color-green1);
}

.inputcheckbox[data-type='radio'] .emptybox {
  color: var(--ap-color-ui-grey-dark);
}

.inputcheckbox[data-type='radio'] .checkbox {
  color: var(--ap-color-ui-grey-darker);
}

.inputcheckbox .emptybox {
  display: inline-block;
}

.inputcheckbox .checkbox {
  display: none;
}

.inputcheckbox__label {
  display: flex;
  align-items: center;
}

.inputcheckbox__input:checked + label > .emptybox {
  display: none;
}

.inputcheckbox__input:checked + label > .checkbox {
  display: inline-block;
}

.inputcheckbox__input:focus + label {
  text-decoration: underline;
}

.inputcheckbox-svg {
  fill: currentColor;
}
