.counterparty-name-cell {
  display: inline-block;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.counterparty-name-cell__with-icon {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-decoration: none;
}

.counterparty-name-cell__with-icon .cell-name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.counterparty-name-cell__with-icon a:link {
  text-decoration: none;
}

.cell-icon {
  background-color: var(--ap-color-ui-amber);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ap-color-black);
  text-decoration: none !important;
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 2px;
}
