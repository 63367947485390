.editorcell-custom {
  height: 100%;
}

.editorcell-custom > input.ag-input-field-input,
.editorcell-custom > select.ag-input-field-input {
  width: 100%;
  height: 100%;
  border: var(--ag-borders-input) var(--ag-input-border-color);
  border-radius: var(--ag-border-radius);
  padding-left: var(--ag-grid-size);
  font-family: inherit;
}

.eeditorcell-custom > input:focus-visible {
  outline: none;
}
