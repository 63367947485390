.settlement-agreemant-preview .ap-modal-content {
  --document-width: 800px;
  --document-height: 1035px;

  flex: 1;
  max-width: 845px;
  min-height: 0;
}

.settlement-agreemant-preview .ap-modal-body {
  flex: 1;
  min-height: 75vh;
  background: var(--ap-color-ui-grey-bg);
}

.settlement-agreemant-preview .ap-modal-header {
  padding: 8px 16px;
}

.settlement-agreemant-preview > .ap-modal-wrapper {
  flex-direction: column;
}

.settlement-agreemant-preview div.react-pdf__Page {
  margin-bottom: 1rem;
}
