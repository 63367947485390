.claims-settlement-single {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--ap-color-ui-grey-bg);
  padding: 32px 0;
}

.claims-settlement-single__body {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: clamp(900px, 100%, 1240px);
}

.claims-settlement-single__content {
  background: #fff;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.15);
  padding: 0px 16px 16px 16px;
}

.claims-settlement-single__tabs {
  border-bottom: 1px solid var(--ap-color-grey2);
}

.claims-settlement-single__tabs-body {
  padding-top: 16px;
}
