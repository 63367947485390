.settlement-header-box {
  display: flex;
  justify-content: space-between;
}

.settlement-header-box__main {
  flex: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  min-width: 0px;
}

.settlement-header-box__number {
  color: var(--ap-color-grey5);
  font-size: 16px;
  font-weight: 700;
}

.settlement-header-box__numberlink {
  color: var(--ap-color-green1);
}

.settlement-header-box__claims {
  color: var(--ap-color-grey7);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow-x: hidden;
  width: 100%;
}

.settlement-header-box__name {
  color: var(--ap-color-black);

  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
}

.settlement-header-box__addition {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
}

.settlement-header-box__status {
  color: var(--ap-color-grey5);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.settlement-header-box__addition > .settlement-status-pill {
  min-width: 160px;
  min-height: 31px;
}
