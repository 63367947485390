.settings-environment-name {
  color: var(--ap-color-grey2);
  font-weight: var(--ap-font-weight-s);
  font-size: var(--ap-font-size-xs);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.settings-environment-name__name {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-xl);
}
