.labeled-multiselect {
  padding-block-start: 0.75rem;
}

.labeled-multiselect__label {
  color: var(--ap-color-ui-grey-dark);
  font-size: var(--ap-font-size-xxs);
  margin-block-end: 0.25rem;
}

.labeled-multiselect__select .multiselect--text {
  font-size: var(--ap-font-size-xs);
}
.labeled-multiselect__select.multiselect--empty .multiselect--text {
  color: var(--ap-color-ui-grey-base);
}
