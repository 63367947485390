.ch11-detailsmodal {
  margin: 10px;
}

.ch11-detailsmodal h4 {
  color: #333333;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: normal;
  text-align: left;
  line-height: 27px;
  margin: 0 0 20px 0;
}

.ch11-detailsmodal__footer {
  justify-content: right;
  margin: 0 10px;
}
