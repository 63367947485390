.data-upload_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px var(--ap-border-padding);

  border-bottom: 1px solid #999999;
}

.data-upload_header-label {
  font-weight: var(--ap-font-weight-m);
  font-size: var(--ap-font-size-m);
  cursor: pointer;
}

.data-upload_header-menu {
  display: flex;
  align-items: center;
  gap: var(--ap-items-spacing);
}

.data-upload_header-notification {
  font-weight: var(--ap-font-weight-s);
  font-size: var(--ap-font-size-s);
  color: var(--ap-color-ui-grey-darker);
}
