.utils-none {
  display: none;
}

.utils-hidden {
  visibility: hidden;
  pointer-events: none;
}

.utils-obstruct a,
.utils-obstruct select,
.utils-obstruct input,
.utils-obstruct-panel,
.utils-obstruct button {
  filter: grayscale(20%) opacity(0.8);
  pointer-events: none;
}

.utils-no-appearance {
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
}

.utils-capitalize {
  text-transform: capitalize;
}

/* Form */

.utils-form .normalize-pad {
  padding-inline: 14px;
  text-indent: 0;
}

.utils-form [type='submit']:disabled {
  filter: grayscale(20%) opacity(0.8);
}

.utils-form:invalid [type='submit'] {
  filter: grayscale(20%) opacity(0.8);
  pointer-events: none;
}

.utils-form-invalid input:invalid,
.utils-form-invalid select:invalid {
  border: 1px solid var(--ap-color-ui-red-alert);
}
.utils-form-invalid input:invalid label,
.utils-form-invalid fieldset:invalid label {
  color: var(--ap-color-ui-red-alert);
}

.utils-form-invalid [data-input='invalid'] {
  border: 1px solid var(--ap-color-ui-red-alert);
}

/* Inputs */

.utils-clean-input {
  text-indent: 0;
  color: inherit;

  appearance: none;
  background-color: inherit;
  font: inherit;
  font-size: inherit;
  border: 0;
  margin: 0;
  caret-color: var(--ap-color-ui-green-primary);
  box-sizing: border-box;
  outline-style: solid;
  outline-width: 1px;
  outline-offset: -1px;
  outline-color: transparent;
  transition: outline-color 200ms ease-in-out;
}

.utils-clean-input:hover,
.utils-clean-input:focus {
  outline-color: var(--ap-color-ui-green-primary);
}

.utils-clean-input:disabled {
  outline-color: transparent;
}

select:not(:disabled).utils-clean-input {
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(204, 204, 204)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}

select:disabled.utils-clean-input {
  opacity: 1;
}

[data-input] {
  box-sizing: border-box;
}

.pos-relative {
  position: relative;
}

/* Flex */
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Scrollbar */

.thin-scrollbar {
  overflow-x: hidden;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  width: 8px;
  height: 8px;
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  height: 12px;
  width: 12px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid rgba(155, 155, 155, 0.3);
  /* transitions do not work on this, leaving it here in case support kicks in */
  transition: background-color ease-in-out 200ms;
}

.thin-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
