.slider-dark {
  --slider-active: var(--ap-color-grey6);
  --rail-background: var(--ap-color-grey3);
  --size: 9px;
  --active-size: 8px;
}

.slider-dark > .rc-slider-track {
  background-color: var(--slider-active);
}

.slider-dark > .rc-slider-rail {
  background-color: var(--rail-background);
}

.slider-dark > .rc-slider-handle {
  background-color: var(--slider-active);
  border: 0;
  cursor: unset;
  margin-top: calc(var(--size) / -2 + 2px);
  width: var(--size);
  height: var(--size);
}

.slider-dark > .rc-slider-handle:active {
  box-shadow: none;
}

.slider-dark > .rc-slider-handle:focus {
  box-shadow: 0 0 5px var(--slider-active);
}
