.switch-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 13px;
}

.switch-button__switch {
  border-radius: 10px;
  width: 26px;
  height: 13px;
  border: 2px solid #737373;
  display: flex;
  align-items: center;
  padding: 1px 2px;
}

.switch-button__circle {
  width: 12px;
  height: 12px;
  background: #999999;
  border-radius: 6px;
}

.switch-button__label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #737373;
  line-height: 18px;
}

.switch-button_disabled {
  cursor: default;
  opacity: 0.3;
}

.switch-button_on > .switch-button__switch {
  border-color: var(--ap-color-ui-green-primary);
  background: var(--ap-color-ui-green-primary);
  justify-content: right;
}

.switch-button_on > .switch-button__switch > .switch-button__circle {
  background: var(--ap-color-ui-white);
}
