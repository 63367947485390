.disclosurelinkmenu .enter {
  transition: transform 100ms ease-out, opacity 100ms ease-out;
}

.disclosurelinkmenu .enterFrom {
  transform: translate(0, -25%);
  opacity: 0;
}

.disclosurelinkmenu .enterTo {
  transform: translate(0, 0%);
  opacity: 1;
}

.disclosurelinkmenu .leave {
  transition: transform 100ms ease-out, opacity 100ms ease-out;
}

.disclosurelinkmenu .leaveFrom {
  transform: translate(0, 0%);
  opacity: 1;
}

.disclosurelinkmenu .leaveTo {
  transform: translate(0, -25%);
  opacity: 0;
}
