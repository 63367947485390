.warning-text {
  display: inline-flex;
  align-items: baseline;
  line-height: var(--ap-font-size-s);
  font-weight: 500;
  color: #d97706;
  gap: 0.3em;
}

.warning-text > .ap-icon {
  transform: translatey(2px);
}
