.upload-progress__container {
  position: relative;
  isolation: isolate;
  outline: 1px solid var(--ap-color-ui-green-light-semi);
  padding: 5px 5px;
  overflow: hidden;
}

.upload-progress__progress-bar::before {
  content: '';
  display: inline-block;
}

.upload-progress__progress-bar {
  --progress-percent: 0%;

  isolation: isolate;
  z-index: -1;
  position: absolute;
  display: inline-block;
  width: var(--progress-percent);
  background-color: var(--ap-color-ui-green-primary);
  transition: transform 1000ms linear;
  animation: 1500ms ease-in opacity-change-keyframes;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  --min-opacity: 0.65;
}

.upload-progress__body {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: var(--ap-bg-surface1);
}

.upload-progress__container .upload-progress__hide {
  display: none;
}

@keyframes opacity-change-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: var(--min-opacity);
  }
}
