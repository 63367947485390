.settings-help {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settings-help__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 50px 0;
  color: white;
  width: 100%;
}

.settings-help__search {
  width: 75%;
  max-width: 600px;
}

.settings-help__body {
  background: #e6e6e6;
  flex: 1;
}

.settings-help__content {
  padding: 48px 48px 48px 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.settings-help__content > header {
  color: var(--greyscale-grey-700, #333);
  font-size: 33px;
  font-weight: 700;
  line-height: 40px; /* 121.212% */
}

.settings-help__panels {
  display: flex;
  gap: 30px;
}

.settings-help__panels > div {
  flex: 1;
}
