.settings-title-panel {
  font-size: var(--ap-font-size-s);
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.settings-title-panel__title {
  margin-block: unset;
  font-size: var(--ap-font-size-l);
}

.settings-title-panel__body {
  background-color: var(--ap-color-white);
  display: flex;
  flex-direction: column;
}
