.settings-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  background-color: var(--ap-bg-surface1);
}

.settings-page > .settings-page-body {
  flex-grow: 100;
}

.settings-page-body {
  display: flex;
  background-color: #f2f2f2;
  min-width: 0;
}

.settings-page-left {
  background-color: #4d4d4d;
  flex-basis: fit-content;
  min-width: 240px;
  max-width: 240px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
}

.settings-page__env-name {
  padding: 1rem;
}

.settings-page-nav .disclosurelinkmenu__panel {
  padding-block: 0;
  overflow-x: hidden;
}

.settings-page-nav .disclosurelinkmenu__subitem {
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  border: none;
  color: white;
}

.settings-page-nav .disclosurelinkmenu__panel .disclosurelinkmenu__subitem {
  margin: 0;
  padding: 16px 32px;
  width: 100%;
  max-width: 240px;
}

.settings-page-nav .disclosurelinkmenu,
.settings-page-nav > .button-nav {
  margin-bottom: 20px;
  font-size: var(--ap-font-size-s);
  line-height: 20px;
  font-weight: var(--ap-font-weight-m);
}

.settings-page-nav .disclosurelinkmenu__subitem:disabled {
  cursor: not-allowed;
  opacity: 0.9;
}

.settings-content__exit-btn {
  width: 120px;
  margin-left: auto;
}
