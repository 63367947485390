.fileupload-panel__dragdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px;
  background: var(--ap-color-ui-white);
  border: 1px dashed #aaaaaa;
  border-radius: 5px;
  cursor: pointer;
}

.fileupload-panel__caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.fileupload-panel__dragging .fileupload-panel__dragdrop {
  background-color: var(--client-primary-brand-color);
}

.fileupload-panel__dragging .fileupload-panel__caption .link-text{
  color: var(--ap-bg-surface1);
}

.fileupload-panel__caption {
  color: #737373;
}
