.pdf-preview__empty-document {
  background-color: white;
  width: var(--document-width);
  height: var(--document-height);
}

.pdf-preview__specified {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: var(--document-width);
  height: var(--document-height);
}

.pdf-preview__specified > h3 {
  margin: 10rem 0 0 0;
  font-size: var(--ap-font-size-m);
}

.pdf-preview__loading {
  height: var(--document-height);
}
