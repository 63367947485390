.global-notes-delete-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2rem;
  border: 1rem var(--ap-color-grey2) solid;
  padding: 0.5rem 0;
}

.global-notes-delete-info > * {
  flex: 1;
}
