div.select-cover {
  margin-bottom: 16px;
}

div.select-cover > div {
  background-color: #ffffff;
}

.sofa-settings__footer {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}
