.claims-panel-card {
  background: var(--ap-color-white);
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  width: 500px;
  min-height: 218px;
}

.claims-panel-card__title {
  flex: 0;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
}

.claims-panel-card__footer {
  flex: 0;
}

.claims-panel-card__body {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: var(--ap-color-ui-grey-dark);
  color: var(--greyscale-grey-500, #737373);
  line-height: 20px;
}

.claims-panel-card__body > li {
  padding-left: 1em;
}
