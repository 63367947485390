.cover-docs-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header-container {
  padding: 12px 12px 0;
}

.cover-docs-page h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.word-container {
  width: 100%;
  height: 100%;
}

iframe.word-embed {
  border: 0;
  outline: none;
}
