.gms-root {
  border-color: var(--ap-color-green2);
  display: block;
  position: relative;
  --gms-options-area-bg: #fff;
  --gms-font-size: var(--ap-font-size-s, 20px);
  font-size: var(--gms-font-size);
  width: 100%;
}

.gms-root-disabled {
  opacity: 0.65;
  pointer-events: none;
}

/* Note: Keep the root in all selectorors to ensure proper specificity */

/* == React Select style overrides == */

.gms-root .gms-react-select {
  padding-block-start: 0;
  position: relative;
  overflow: hidden;
}

.gms-root .gms__option {
  position: relative;
  padding-block: 12px;
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1em;
}

.gms-root .gms__control {
  height: 40px;
  border: 1px solid var(--ap-color-grey4);
  border-radius: 1px;
  cursor: pointer;
  box-shadow: none;
  margin: 16px;
  width: calc(100% - 2 * 16px);
  font-size: var(--gms-font-size);
  color: var(--ap-color-grey4);
}

.gms-react-select.gms-hide-search .gms__control {
  /* Hide this offscreen because hidden visibility or no display makes react-select fail to have the checkboxes selected by arrow keys */
  position: absolute;
  top: -100px;
}

.gms-root .gms__control:hover,
.gms-root .gms__control:focus {
  border-color: var(--ap-color-green2);
  outline: none;
}
.gms-root .gms__control:active {
  border-color: var(--ap-color-green3);
  outline: none;
}

.gms__value-container.gms-hide-values > .gms__multi-value {
  display: none;
}

.gms-root .gms__value-container--is-multi {
  padding: 0;
  margin-inline-end: 16px;
}

.gms-root .gms__menu {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 1px;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  box-shadow: none;
}

.gms-root .gms__menu-list {
  max-height: unset;
  padding: 0;
}

.gms-root .gms__group-heading {
  font-weight: var(--ap-font-weight-xl);
  font-size: inherit;
  text-transform: capitalize;
  color: inherit;
  margin: 0;

  padding-block: 14px;
  padding-left: 16px;
  padding-right: 16px;
}

.gms-root .gms__group {
  padding: 0;
}

.gms-root .gms__multi-value__remove {
  display: none;
}

.gms-root .gms__input-container {
  font-size: var(--gms-font-size);
}

.gms-root .gms__placeholder {
  color: inherit;
  font-size: var(--gms-font-size);
}

.gms-root .gms__option:hover {
  background-color: var(--ap-color-hover);
}

.gms-root .gms__option {
  position: relative;
  padding-right: 10px;
}

.gms-root .gms__focus-indicator {
  position: absolute;
  top: 4px;
  bottom: 4px;
  width: 2px;
}

.gms-root .gms__focus-indicator-active {
  animation-name: x;
  animation-duration: 7000ms;
  animation-fill-mode: forwards;
}

@keyframes x {
  0% {
    background-color: var(--ap-color-green1);
    opacity: 1;
  }
  33% {
    background-color: var(--ap-color-green1);
    opacity: 1;
  }
  66% {
    background-color: var(--ap-color-green1);
    opacity: 0.5;
  }
  99% {
    opacity: 0.5;
  }
  100% {
    background-color: var(--ap-color-green1);
    opacity: 0;
  }
}

/* == Specific styles == */

.gms-checkbox {
  margin-inline-start: 16px;
  margin-inline-end: 16px;
}

.gms-tick {
  color: var(--ap-color-green1);
}

.gms-empty {
  color: var(--ap-color-ui-grey-dark);
}

.none {
  display: none;
}

.gms-select-icon {
  flex-shrink: 0;
}

.gms-toggle[open] .gms-select-icon {
  transform: rotate(-180deg);
}

.gms-main-label-wrapper {
  min-height: 1em;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: var(--gms-font-size);

  display: flex;
  justify-content: space-between;
  padding-right: 3px;
  flex-wrap: nowrap;
  text-indent: 16px;
}

.gms-main-label {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
}

.gms-main-label--loading {
  color: var(--ap-color-green2);
}

.gms-root summary::marker {
  display: none;
  content: '';
}

.gms-options-area {
  width: 100%;
  position: absolute;
  isolation: isolate;
  z-index: 2;
  background-color: var(--gms-options-area-bg, white);
  border-radius: 1px;
  border: 1px solid var(--ap-color-grey4);
  border-top: 0;
  box-sizing: border-box;
}

.gms-options-area:focus,
.gms-options-area:focus-visible {
  border-color: var(--ap-color-green2);
  outline: none;
}

.gms-scrollable-area {
  max-height: 260px;
  overflow-y: auto;

  padding-block-end: 12px;
}

.gms-search-parent {
  outline-offset: -1px;
  position: relative;
}

.gms-search-icon {
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}

.gms-search-parent {
  margin: 16px;
  width: calc(100% - 2 * 16px);
  font-size: var(--gms-font-size);
}

.gms-search-input {
  text-indent: 32px;
}

.gms-tick {
  color: var(--ap-color-green1);
}

.gms-empty {
  color: var(--ap-color-ui-grey-dark);
}

.gms-btns {
  display: flex;
  gap: 8px;
  padding: 8px;
  padding-block-start: calc(8px + 2px);
  border-block-start: 1px solid var(--ap-color-grey4);
}

.gms-btn {
  text-transform: capitalize;
  flex: 1;
  font-size: var(--gms-font-size);
}

.gms-submit {
  background-color: var(--ap-color-green1);
}

.gms-cancel {
  box-sizing: border-box;
  color: var(--ap-color-green1);
  border: 1px solid var(--ap-color-green1);
}

.none {
  display: none;
}

.gms-backdrop {
  display: none;
}

.gms-toggle[open] ~ .gms-backdrop {
  display: block;
  isolation: isolate;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
