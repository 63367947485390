.dynamicfields-table {
  position: relative;
  background-color: var(--ap-color-ui-grey-bg);
  margin-block-end: 1rem;
  padding: 1.5rem;
  row-gap: 8px;
  font-size: var(--ap-font-size-xs);
  --cell-body-pad-inline: 0.5rem;
  --cell-height: calc(3em + 1px);
  --cell-body-pad-block: 0.55em;
}
.dynamicfields-table > div:empty {
  display: none;
}
.edit-table__header-row {
  font-size: var(--ap-font-size-xxs);
  margin-block-start: 1rem;
  margin-block-end: 0.75rem;
  color: var(--ap-color-ui-grey-dark);
  display: grid;
  grid-template-columns: var(
    --edit-table-grid-template-columns,
    var(--default-edit-table-grid-template-columns)
  );
}

.edit-table__header {
  padding-inline: var(--cell-body-pad-inline);
}

.edit-table__title {
  line-height: 1.5rem;
  display: flex;
  font-size: var(--ap-font-size-m);
  margin-block: 0 1.5rem;
  color: var(--ap-color-grey8);
}

.edit-table__description {
  font-size: 1rem;
  margin-block: 1em;
  line-height: 1.5rem;
  color: var(--ap-color-ui-grey-darker);
}
.ap-button-group.edit-row-buttons {
  margin-left: 8px;
}

.dynamicfields__row-el {
  display: grid;
  grid-template-columns: var(
    --edit-table-grid-template-columns,
    var(--default-edit-table-grid-template-columns)
  );
  margin-block-end: 0.4em;
  align-items: center;
  column-gap: 0px;
  max-width: 100%;
  --dynamicfields__row--bg: var(--ap-color-ui-grey-bg);
  background-color: var(--dynamicfields__row--bg);
  position: relative;
}
form.dynamicfields__row-el.disabled {
  opacity: 0.4;
}

.dynamicfields__row-el:last-child {
  margin-block-end: 0;
}

.dynamicfields__cell__body {
  padding-inline: var(--cell-body-pad-inline);
  display: flex;
  align-items: center;
  width: 100%;
}

.dynamicfields__cell {
  min-height: var(--cell-height);
  background-color: var(--ap-bg-surface1);
  display: flex;
  align-items: center;
  position: relative;
  margin-inline: 0px;
}

.dynamicfields__cell .datepicker__label {
  pointer-events: none;
}

.edit-table__cell-switch {
  background-color: var(--ap-bg-surface1);
}

.trigger .edit-table__cell-switch {
  background-color: transparent;
}

.trigger .dynamicfields__cell-edit {
  margin-inline: 4px;
}
.dynamicfields__row-el button {
  text-transform: capitalize;
  height: auto;
  min-height: var(--cell-height);
}

button.dynamicfields__edit.ap-button-main {
  background-color: transparent;
  border: 1px solid var(--ap-color-green2);
  color: var(--ap-color-green2);
  padding-inline: var(--cell-body-pad-inline);
  padding-block: var(--cell-body-pad-block);
}

.dynamicfields__edit svg {
  color: var(--ap-color-green2);
}

.dynamicfields-table .dynamicfields__row-el.transition-expand.trigger {
  margin-block: 0.3em;
  padding-inline: 20px;
}
.dynamicfields-table .form-content {
  max-height: 70vh;
  overflow-y: auto;
}
.dynamicfields-table .ap-button-icon.dynamicfields__edit {
  border: 1px solid var(--ap-color-green2);
  border-radius: 0;
}
.dynamicfields__edit > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dynamicfields-table .switch-toggle-all {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.dynamicfields-table button.entity-table__action:active:enabled,
.dynamicfields-table button.entity-table__action:focus:enabled,
.dynamicfields-table button.entity-table__action:hover:enabled {
  background-color: var(--ap-color-hover);
}

.dynamicfields__row-el-saving {
  /* used in transition */
  outline: 1px solid var(--ap-color-green1);
  animation-iteration-count: infinite;
  --dynamicfields__row--bg: var(--ap-color-green0);
  background-color: var(--dynamicfields__row--bg);
  z-index: 1;
  outline-offset: 0px;
  animation: 1000ms ease-in anim-bg-pulse infinite alternate;
}

.dynamicfields__row-el-add.dynamicfields__row-el-saving {
  outline-offset: 5px;
}

select.dynamicfields__cell__body {
  min-height: inherit;
}
input.dynamicfields__cell__body {
  min-height: inherit;
}

select.exhibitSelect {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dynamicfields__cell-filter,
.dynamicfields__cell-add {
  margin-inline-end: 8px;
}

.dynamicfields__cell-add:first-child {
  margin-inline-start: 0;
}

.edit-table__cell-last {
  margin-inline-end: 0px;
}

.edit-row-info {
  margin-left: 15px;
  height: var(--cell-height);
}

/* .dynamicfields__add,
.dynamicfields__save,
.dynamicfields__edit {
  margin-inline-start: 8px;
} */

.dynamicfields__save {
  width: 100%;
}

.form-content .entity-table__action {
  margin-left: 8px;
}

.dynamicfields-table button.entity-table__action svg {
  fill: currentColor;
}

button.entity-table__reset {
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  left: 0;
  transform-origin: 50% 50%;
  transform: scaleY(0.8);
}

button.utils-none {
  display: none;
}

.dynamicfields-table button.ap-button-main > span {
  text-transform: none;
}

.sk-on .dynamicfields__cell__body {
  color: transparent;
  background-color: var(--ap-bg-surface1);
  visibility: hidden;
}

.sk-on .dynamicfields__row-el {
  background-color: transparent;
}

.sk-on .entity-table__action {
  color: transparent;
  pointer-events: none;
  background-color: transparent;
}

.enity-table__empty-msg {
  min-height: 7rem;
  display: grid;
  place-items: center;
}

.entity-table__error-row {
  position: absolute;
  margin-block: 0;
  font-size: 1em;
  color: var(--ap-color-ui-red-alert);
  margin-inline: 4px;
  bottom: -1.3em;
}

.entity-table__error-row__edit {
  bottom: -0.9em;
  padding-inline: var(--cell-body-pad-inline);
}
.dynamicfields-table .ap-button-group .dynamicfields__delete {
  border: 1px solid var(--ap-color-red1);
  border-radius: 0;
  margin-left: 3px;
  padding: 0 8px;
  width: auto;
}
.dynamicfields-table .ap-button-group .dynamicfields__delete:hover {
  background-color: var(--ap-color-lightRed);
}

.dynamicfields__row-el .dynamicfields__delete svg {
  fill: var(--ap-color-red1);
  width: 20px;
  height: 20px;
}

.edit-table__pagination {
  display: flex;
  justify-content: end;
}
.dynamicfields__cell-edit .disabled-value {
  padding: 0 0.5rem;
  margin: 0;
}
