.sofa-delete-modal .ap-modal-content {
  max-width: 800px;
}

.sofa-delete-modal .ap-modal-header {
  padding-block: 0.5rem;
  padding-inline: 1rem;
  border-bottom: none;
}

.sofa-delete-modal .ap-modal-footer {
  display: flex;
  justify-content: space-between;
  max-height: 3em;
  border: none;
  margin-top: auto;
  display: flex;
}

button.normal-text-case > span {
  text-transform: none;
}

button.sofa-confirm-button {
  border: 1px solid;
  padding: 0px 16px;
}
