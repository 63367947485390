.settlement-single-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.claims-settlement-single__agreement {
  gap: 1em;
}

.claims-settlement-single__label {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.claims-settlement-single__label a {
  color: var(--ap-color-ui-green-primary);
  font-weight: var(--ap-font-weight-m);
}

.claims-settlement-single__label a:hover {
  text-decoration: underline;
}

.claims-settlement-single__label b {
  font-weight: var(--ap-font-weight-m);
}
