.settlememt-view-save-panel--container {
  background-color: var(--ap-color-white);
  display: flex;
  justify-content: center;
}

.settlememt-view-save-panel {
  display: flex;
  align-items: flex-end;
  gap: 2em;
  padding: 16px 32px;
}

.settlememt-view-save-panel__comment {
  width: 560px;
}

.settlememt-view-save-panel__checkbox {
  min-height: 36px;
  display: flex;
  align-items: center;
}
