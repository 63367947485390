.counterparties-and-debtors__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 32px;
  gap: 32px;
}

.counterparties-and-debtors__container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
}

.counterparties-and-debtors__section {
  padding: 0;
}

.counterparties-and-debtors__section-title {
  font-weight: 500;
  font-size: 23px;
  letter-spacing: 0.3px;
}

.contract-counterparties-and-debtors-table {
  --table-background: var(--simple-table-bg, --ap-color-ui-grey-bg);
  min-height: 34em;
  display: flex;
  flex-direction: column;
  background-color: var(--table-background);
}

.contract-counterparties-and-debtors-table > table {
  background-color: var(--table-background);
  border-spacing: 0 0.5rem;
  border: 16px solid var(--table-background);
}

.contract-counterparties-and-debtors-table .table-header-row > th {
  background-color: var(--table-background);
  font-size: var(--ap-font-size-xxs);
  font-weight: unset;
  text-align: unset;
  padding: 6px 10px;
}

.contract-counterparties-and-debtors-table .table-item-row {
  background: white;
}
.contract-counterparties-and-debtors-table .table-item-row .table-actions label {
  text-transform: capitalize;
}

.counterparties-and-debtors__divide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 16px;
  height: 32px;
  border-left: 1px solid var(--ap-color-grey2);
}
.table-item-row .table-actions .ap-button-icon {
  padding: 0;
}
.ap-modal .counterparties-and-debtors__modal-content {
  background-color: var(--ap-color-ui-grey1);
  padding: 15px;
}
.ap-modal .counterparties-and-debtors__modal-content h4 {
  margin: 0;
  font-weight: 400;
}
.ap-modal .counterparties-and-debtors__modal-content > div {
  justify-content: space-between;
}
.ap-modal .counterparties-and-debtors__modal-content > div p {
  max-width: 60%;
  font-weight: 700;
}
.counterparties-and-debtors__modal-title {
  font-size: var(--ap-font-size-m);
  font-weight: 400;
  margin-top: 5px;
}
.ap-button-group.counterparties-and-debtors__modal-footer .ap-button-casual {
  margin-left: auto;
  border: 1px solid var(--ap-color-red2);
  color: var(--ap-color-red2);
}
.contract-counterparties-and-debtors-table .table-item-row > td {
  text-transform: uppercase;
  text-align: unset;
  font-size: var(--ap-font-size-xs);
  font-weight: 500;
  padding: 6px 10px;
}

.table-item__empty-list-message {
  min-height: 16em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
