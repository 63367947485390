.single-view-panel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: var(--ap-color-ui-grey-bg);
}

.single-view-panel__title {
  color: var(--ap-color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.single-view-panel__body {
  display: flex;
  flex-direction: column;
}
