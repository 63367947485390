div.sofa-preview-section {
  padding: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sofa-preview-frame {
  /* size A4 paper */
  width: 100%;
  height: 100%;
  border: unset;
}

div.sofa-doc-layout {
  background: white;
}

.sofa-preview__container {
  display: flex;
  flex-direction: column;
}

.sofa-preview__content {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
