.bookmarks-page__container {
  background-color: var(--ap-color-ui-grey-bg);
  display: flex;
  min-height: calc(100vh - 217px);
  padding: 50px 20px;
  flex-direction: column;
}

.bookmarks-page__popup p {
  color: var(--ap-color-ui-grey-darker);
  font-size: var(--ap-font-size-s);
  line-height: var(--ap-font-size-m);
}
.bookmarks-page__popup label {
  color: var(--ap-color-ui-grey-dark);
  font-size: var(--ap-font-size-xs);
  display: block;
}
.bookmarks-page__popup textarea {
  font-family: 'Roboto';
  width: calc(100% - 10px);
  margin: 5px 0;
  padding: 10px 5px;
}
.bookmarks-page__popup textarea:focus {
  border: 1px solid var(--ap-color-ui-green-primary);
  outline: 1px solid var(--ap-color-ui-green-primary);
}
.ap-modal .ap-modal-footer .bookmarks-page__popup-footer {
  justify-content: flex-end;
}
.bookmarks-page__popup-footer .ap-button-secondary {
  border: 1px solid var(--ap-color-ui-grey-dark);
}
.bookmarks-page__popup-footer .ap-button-danger {
  border: 1px solid var(--ap-color-red1);
  color: var(--ap-color-red1);
}
.ap-modal .ap-modal-footer .bookmarks-page__popup-footer .ap-button-danger:hover {
  background-color: var(--ap-color-lightRed);
  color: var(--ap-color-red1);
}
.bookmarks-page__popup-footer button {
  text-transform: capitalize;
}
