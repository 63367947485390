.documents-page {
  width: 100%;
  flex: 1;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 1px solid var(--ap-color-ui-grey-base);
  background: var(--ap-color-grey2);
  position: relative;
  padding: 1rem 0 2rem;
}
.documents-page__section {
  background-color: white;
  padding: 2rem;
  width: 500px;
  margin: 0 2rem;
}
.documents-page__section h4 {
  margin: 0 0 1rem;
  font-size: var(--ap-font-size-l);
  font-weight: 500;
}
.documents-page__section p {
  margin: 0 0 0.8rem;
}
.documents-page__section li {
  padding-bottom: 0.3rem;
}
.documents-page__section p,
.documents-page__section li {
  font-size: var(--ap-font-size-s);
  color: var(--ap-color-ui-grey-dark);
}
.documents-page .documents-page__section .ap-button-main {
  margin: 2rem 0 0;
}

.documents-page_all {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  gap: 4rem;
  max-width: 1360px;
}
.documents-page_all-files {
  flex: 1;
}
.documents-page_all-content {
  background-color: white;
  padding: 1.5rem 1rem;
}
.documents-page button > span {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.documents-page_upload p {
  text-align: center;
}

.documents-page_upload-section {
  background: var(--ap-color-ui-grey1);
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
}
.documents-page_upload-section .datacontent-box__content {
  gap: 10px;
  flex-direction: row;
  align-items: center;
}
.documents-page_upload-section .datacontent-box__content p {
  margin: 0;
  gap: 15px;
}
.fileupload-panel.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.documents-page_upload .fileupload-panel__dragdrop {
  padding: 12px 15px;
  min-width: 400px;
}
.documents-page .fileupload-panel__caption {
  font-size: var(--ap-font-size-xs);
}

.documents-page_upload-progress {
  min-width: 400px;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1rem;
  position: relative;
}
.documents-page_upload-progress img {
  max-width: 40px;
}
.documents-page_upload-progress p {
  margin: 0;
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-m);
  text-align: left;
}
.documents-page_upload-progress::after {
  /* img weird border fix */
  content: '';
  position: absolute;
  width: 40px;
  height: 1px;
  background-color: white;
  bottom: 1.1rem;
}

.documents-page_upload-progress .ap-button-group {
  margin-left: auto;
}
.documents-page_upload-progress.unzipping::after {
  display: none;
}
.documents-page_upload-progress svg,
.documents-page_upload-progress img {
  margin-right: 10px;
}
.documents-page b.success {
  color: var(--ap-color-green2);
}
.documents-page b.failed {
  color: var(--ap-color-red1);
}
.documents-page_upload-section .documents-page_upload-progress .ap-button-casual {
  margin-left: auto;
  border-color: var(--ap-color-red1);
  color: var(--ap-color-red1);
  min-width: 100px;
}

.documents-page_upload-progress .ap-button-secondary.ap-button-casual > span > svg {
  fill: var(--ap-color-red1);
}
.documents-page_upload-section .title button.ap-button-casual {
  margin-left: auto;
}
.documents-page h3 {
  font-size: var(--ap-font-size-m);
  margin: 1rem 0;
}
.documents-page_upload-section h4 {
  margin: 0;
  font-weight: 500;
}
.documents-page_upload-section .title h4 {
  font-size: var(--ap-font-size-s);
}
.documents-page_upload-section .files_headers,
.documents-page_upload-section .files_content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.documents-page_upload-section .files_content {
  background-color: white;
  padding: 1rem 0;
}
.documents-page_upload-section .files_headers h5 {
  font-weight: 400;
  padding: 0 1rem;
}
.documents-page_upload-section .files_content p {
  margin: 0;
  padding: 0.5rem 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: var(--ap-font-size-s);
}
.files_content p svg {
  cursor: pointer;
  width: 2rem;
}

.mapping-file-upload .fileupload-panel,
.claimsDocUpload.fileupload-panel,
.documents-page_upload-progress.claimsDocUpload {
  width: 100%;
}
.mapping-file-upload .datacontent-box__content {
  margin-bottom: 10px;
}
.mapping-file-upload .fileupload-panel .ap-button-main:enabled {
  margin-left: 2rem;
}
.documents-page button.ap-button-main.upload-new-file {
  margin-left: auto;
}
.download-template {
  font-size: var(--ap-font-size-xs);
}
.download-template a.ap-button-link {
  text-transform: capitalize;
  text-decoration: underline;
  color: var(--ap-color-green2);
  font-size: var(--ap-font-size-xs);
}
.download-template a.ap-button-link:hover {
  background-color: transparent;
  font-weight: bold;
}

.documents-page_info {
  flex: 0.4;
}
.documents-page_info ul {
  padding: 0.5rem 0;
  list-style-type: none;
  background-color: white;
  margin-bottom: 0;
}
.documents-page_info ul li {
  padding: 0.1rem 1.3rem;
  border-bottom: 1px solid var(--ap-color-grey2);
}
.documents-page_info ul li:last-of-type {
  border: none;
  padding-top: 0.7rem;
}
.documents-page_info h5,
.documents-page_info p {
  margin: 10px 0;
  font-size: var(--ap-font-size-s);
}
.documents-page_info p a {
  color: var(--ap-color-green2);
  font-weight: 700;
  text-decoration: underline;
}

.documents-page .files-upload {
  padding: 15px;
  border: 1px dashed #aaa;
  background-color: white;
  margin: 10px 0 32px;
  font-size: var(--ap-font-size-xs);
  text-align: center;
}
.progress-meter-container {
  width: 100%;
  padding-left: 10px;
  font-size: var(--ap-font-size-xs);
}
.documents-page .progress-meter {
  margin: 0px 5px 5px 0;
  background-color: var(--ap-color-ui-green-hover);
  max-width: 95%;
  height: 5px;
}
.documents-page .progress-meter__fill {
  background-color: var(--ap-color-green1);
  height: 5px;
}

.documents-page__export-button {
  width: 13em;
  height: 44px !important;
  margin-left: auto;
}

.documents-page__export-button-loading {
  display: flex;
  font-size: var(--ap-font-size-xs);
  color: var(--ap-color-grey6);
  align-items: center;
  background: var(--ap-color-green0);
  padding: 6px 0;
  width: 13em;
  margin-left: auto;
}
