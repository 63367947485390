.dataupload-menu {
  background: var(--ap-color-ui-white);
  border-right: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
}

.dataupload-menu__section-title {
  color: #737373;
  background: #f7f7f7;
  padding: 8px var(--ap-border-padding);
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-s);
  display: flex;
  gap: 9px;
  text-transform: uppercase;
}

.dataupload-menu__delete-all {
  display: flex;
  margin-top: auto;
  padding: var(--ap-border-padding);
  justify-content: flex-end;
}

.dataupload-menu-section_item {
  display: flex;
  align-items: center;
  padding: 0px var(--ap-border-padding);
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-m);
  cursor: pointer;
  height: 60px;
}

.dataupload-menu-section_item > span {
  flex: 1;
}

.dataupload-menu-section_item-selected {
  background-color: var(--ap-color-ui-green-primary);
  color: var(--ap-color-ui-white);
}
.dataupload-menu-section_item .ap-loader-default {
  width: auto;
}

.dataupload-menu__delete-all > .dataupload__button-danger {
  padding: 0.625em 1em;
  height: auto;
}
