.preview-modal__container .button-close {
  position: absolute;
  right: 0;
  top: -40px;
}

.preview-modal__wrapper {
  width: calc(100% + 30px);
  overflow: hidden;
  background-color: #e2e2e2;
  border: 1px solid #c5c5c5;
  margin: -16px;
}

.preview-modal__nav-bar-container {
  width: 1500px;
}

.preview-modal__white-space {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 22px;
}

.preview-modal__black-object {
  width: 128px;
  height: 24px;
  content: '';
  margin-left: 8px;
}

.preview-modal__body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.preview-modal__left-menu {
  width: 30%;
  height: 300px;
  content: '';
  background-color: #444444;
}
