.setup-wizard-section > div {
  margin: 5px 0;
}

.brand-color {
  display: flex;
}

.setup-wizard-step01 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.setup-wizard-field__label {
  font-size: 13px;
  color: #737373;
  display: flex;
  align-items: center;
  gap: 0.3em;
  margin-bottom: 3px;
}
.setup-wizard-field__error {
  font-size: 11px;
  color: #ff600f;
  display: flex;
  align-items: center;
  gap: 0.3em;
}

.setup-wizard-field__fixedvalue {
  font-weight: 500;
}

.setup-wizard-line {
  display: flex;
  align-items: center;
  gap: 0.3em;
}

.setup-wizard-line > .ap-button-main {
  flex-basis: 14em;
}

.setup-wizard-field__coloredit {
  width: 6em;
}

#setup-wizard-color {
  cursor: default;
}
