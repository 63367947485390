.sofas-schedules-headerbar {
  padding: 0.62rem 1.5rem;
  border-bottom: 1px solid var(--ap-color-ui-grey-base);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.sofas-schedules__export-btn {
  text-transform: none;
}

.sofas-schedules-headerbar__export {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}

.sofas-schedules__export-history {
  font-size: var(--ap-font-size-s);
}
