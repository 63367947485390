.submatch-code-cell {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.submatch-code-cell__icon-container {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submatch-code-cell .label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #1a1a1a;
}
