.sofa-export-details {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sofa-export-details__title {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-l);
}

.sofa-export-details__body {
  font-size: var(--ap-font-size-xs);
  font-weight: var(--ap-font-weight-m);
  border-spacing: 0 0.5rem;
}

.sofa-export-details--id {
  margin-right: 0.6em;
}

.sofa-export-details__body tr {
  background: #fff;
}

.sofa-export-details__body tr > td {
  padding: 6px 10px;
}

.sofa-export-details__body tr > td:nth-child(1) {
  width: 65%;
}

.sofa-export-details__body tr > td:nth-child(2) {
  text-align: right;
}
