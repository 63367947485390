.progress-bar {
  --progress-height: 3px;
  width: 100%;
}
.progress-bar__container {
  background-color: #eeeeee;
  width: 100%;
  height: var(--progress-height);
}
.progress-bar__bar {
  background-color: #5cb335;
  height: var(--progress-height);
  content: '&nbsp;';
}
