.related-contracts {
  padding: 20px 16px;
}
.related-contracts-headers,
.related-contracts-content-item {
  display: grid;
  grid-template-columns: repeat(2, 1.2fr) 1.5fr 2fr repeat(2, 1fr);
  margin: 5px 0 15px;
  text-transform: capitalize;
  align-items: center;
}
.related-contracts-content-item {
  padding: 10px 0;
  margin: 10px 0;
  background-color: white;
}
.related-contracts-content-item * {
  white-space: pre-wrap;
}
.related-contracts-content {
  max-height: 70vh;
  overflow-y: auto;
}
.related-contracts h4,
.related-contracts h5 {
  margin: 10px 0;
  font-weight: 400;
}
.related-contracts-headers h5 {
  padding: 0 10px;
  margin: 0;
}
.related-contracts-content-item p {
  font-size: var(--ap-font-size-xs);
  margin: 0;
  padding: 0 10px;
}
.related-contracts-content .related-contracts-content-item .ap-button-link {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  font-weight: 700;
  font-size: var(--ap-font-size-xs);
  padding: 0 10px;
}
.related-contracts-content .related-contracts-content-item .ap-button-link:hover {
  background-color: transparent;
  text-decoration: underline;
}
