.single-claim-subheader {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.single-claim-subheader__tabs {
  display: flex;
  align-items: center;
  margin-top: -16px;
  margin-bottom: 3px;
  flex-grow: 1;
  column-gap: 1rem;
}

.single-claim-subheader__tabs button.single-claim-subheader__tab {
  font-size: var(--ap-font-size-xs);
  letter-spacing: 0.02em;
}

.single-claim-subheader__tabs button.single-claim-subheader__tab.selected {
  color: var(--ap-color-ui-green-primary);
}

.single-claim-subheader__tabs button.single-claim-subheader__tab.selected::before {
  border-bottom-width: 4px;
  top: -4px;
}

.single-claim-subheader__tabs .ap-button-group {
  flex: 1;
  display: inline-block;
  box-shadow: 0px 1px 0px #e6e6e6;
}
.single-claim-subheader__tabs .ap-button-group.claims-tool-button-group-approval {
  box-shadow: 0px 1px 0px var(--ap-color-amber2);
}
