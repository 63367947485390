.upload-modal .ap-modal-header {
  padding-left: 32px;
}

.upload-modal .ap-modal-header .ap-button-icon > svg {
  color: var(--ap-color-ui-green-base);
  fill: currentColor;
}

.upload-modal .ap-modal-body {
  padding: 32px;
  padding-block-end: 16px;
}

.upload-modal .ap-modal-content {
  max-width: 640px;
  max-height: min(786px, 85vh);
}

.upload-modal .fileupload-panel__dragdrop {
  padding-block-start: 0px;
  padding-block-end: 8px;
}

.upload-modal__action-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.upload-modal__action {
  text-transform: capitalize;
  min-width: 7em;
}

button.upload-modal__action > span {
  text-transform: none;
  display: flex;
  align-items: center;
}

.upload-modal__action-close {
  color: var(--ap-color-green1);
  border: 1px solid var(--ap-color-green1);
  color: var(--ap-color-green1);
}

.upload-modal__files > :not(:first-child) {
  padding-block-start: 2px;
}

.upload-modal__file-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  margin-block-start: 8px;
  margin-block-end: 24px;
}

.upload-modal .ap-modal-footer {
  padding: 32px;
  max-height: 3em;
  padding-top: 16px;
  border: none;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.upload-modal__hide-footer .ap-modal-footer {
  display: none;
}

.upload-modal .upload-modal__comment {
  /* for some reason the width & height was capped from an unknown souce */
  width: 100% !important;
  height: 5em !important;
  resize: none;
  font-size: 1rem;
  font-family: inherit;
  padding: 8px 16px;
  overflow-y: auto;
  text-indent: unset;
}

.upload-modal-appear {
  animation: 300ms ease-in upload-modal-appear;
  animation-fill-mode: forwards;
  transform-origin: center center;
}

.ap-modal-footer .ap-button-casual.cancel-button {
  margin-right: 15px;
  text-transform: capitalize;
  width: 91px;
}
.ap-modal-footer .ap-button-casual.cancel-button span {
  display: block;
  text-align: center;
}

@keyframes upload-modal-appear {
  from {
    opacity: 0.5;
    scale: 0.99;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}
