.supported-formats {
  display: none;
  position: absolute;
  z-index: 9;
  background-color: white;
  font-size: var(--ap-font-size-xxs);
  line-height: 18px;
  padding: 12px;
  box-shadow: 0px 4px 50px -12px rgba(0, 0, 0, 0.35);
  width: 100%;
  max-width: 400px;
}
.supported-formats-icon:hover + .supported-formats {
  display: block;
}
.supported-formats-icon {
  vertical-align: middle;
  margin-left: 5px;
  height: 18px;
  width: 18px;
}
