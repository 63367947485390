.document-table {
  --table-background: unset;
  min-height: 34em;
  display: flex;
  flex-direction: column;
  background-color: var(--table-background);
}

.document-table > table {
  background-color: var(--table-background);
  border-spacing: 0 0.5rem;
  border: 16px solid var(--table-background);
}

.document-table-header-row > th {
  background-color: var(--table-background);
  font-size: var(--ap-font-size-xs);
  font-weight: unset;
  text-align: unset;
  padding: 6px 10px;
}

.document-table-item-row {
  background: white;
}

.document-table-item-row > td {
  text-align: unset;
  font-size: var(--ap-font-size-xs);
  padding: 6px 10px;
}

.document-table-progress-row > td {
  padding: 0;
  background: white;
  font-size: var(--ap-font-size-xs);
}

.document-table-item-row__new-row {
  animation: 500ms ease-in anim-fade-green-2;
}

.document-table-item__empty-loading-value {
  min-height: 18px;
}

.document-table-item__empty-list-message {
  min-height: 16em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.document-table-item__additional-row-info {
  top: -6px;
  position: relative;
}
.document-table-item__additional-row-info > td {
  padding: 10px;
}

.document-table-header-row th:last-child {
  text-align: center;
}
