.slip-sheet-list-item__details {
  background-color: var(--ap-bg-surface1);
  padding-block: 0.5rem 0.75rem;
  padding-inline: 3rem 1rem;
}

.enumerator {
  padding-block: 0.25rem;
}

.enumerator__list {
  padding-inline: 0;
  list-style: none;
}

.enumerator__text {
  font-size: var(--ap-font-size-xs);
  line-height: 14px;
  color: var(--ap-color-grey8);
  font-weight: var(--ap-font-weight-s);
}

.enumerator__title {
  color: var(--ap-color-grey6);
}
