button.exhibit-claim-button {
  height: 32px;
  font-size: var(--ap-font-size-xs);
  border: 1px solid var(--ap-color-green2);
  color: var(--ap-color-green2);
  text-transform: unset;
}

button.exhibit-claim-button svg {
  fill: var(--ap-color-green2);
}

.exhibit-exporter__container button.exhibit-exporter__btn span {
  line-height: unset;
}

button.exhibit-claim-button {
  margin-top: 10px;
}

button.exhibit-claim-button[disabled] {
  border: 1px solid var(--ap-color-grey3);
  color: var(--ap-color-grey3);
}

button.exhibit-claim-button[disabled] svg {
  fill: var(--ap-color-grey3);
}
