.sofa-modal .ap-modal-header {
  padding-block: 0.5rem;
  /* padding-block: 0; */
  padding-inline: 1rem;
  border-bottom: none;
}

button.sofa-modal__action > span {
  text-transform: none;
}

.sofa-modal .ap-modal-footer {
  max-height: 3em;
  border: none;
  margin-top: auto;
  display: flex;
}
