.dashboard-ap {
  display: flex;
  gap: 64px;
  opacity: 1;
  transition: all 0.7s ease-in-out;
  min-height: 460px;
}
.dashboard-ap.scrolled {
  opacity: 0;
}
.dashboard-ap > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 50%;
}
.dashboard-tasks {
  min-width: 520px;
}
.dashboard-tasks .task {
  display: flex;
  flex-direction: column;
  font-size: var(--ap-font-size-s);
  line-height: 20px;
  background-color: #fff;
  padding: 0 20px;
}
.dashboard-tasks .task.firstTask {
  text-transform: capitalize;
  background-color: rgba(26, 26, 26, 0.5);
  font-weight: 700;
  color: #fff;
}
.claims-tasks .dashboard-tasks .task:not(.firstTask) .left-col {
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--ap-font-size-xs);
}
.dashboard-tasks .task.firstTask .task-count {
  color: #fff;
}
.dashboard-tasks .task.firstTask.hasPointer,
.left-col.hasPointer {
  cursor: pointer;
}
.left-col.hasPointer:hover {
  text-decoration: underline;
}
.dashboard-tasks .task > div {
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin: 0;
  width: 100%;
  position: relative;
}
.dashboard-tasks .task.firstTask > div {
  padding: 15px 0;
}
.claims-tasks .dashboard-tasks .task:not(.firstTask) > div {
  padding: 40px 0;
}
.dashboard-tasks .task .left-col .ap-loader-default {
  position: absolute;
  left: 40%;
  transform: translateX(-40%);
}

.dashboard-tasks .task.withBorder > div {
  border-bottom: 1px solid var(--ap-color-ui-grey-bg);
}

.contracts-tasks .dashboard-tasks .task:not(.firstTask) > div {
  padding: 9px 0;
}

.dashboard-tasks .task.firstTask .task-count > span:not(.task-percentage):hover {
  text-decoration: underline;
}

.dashboard-tasks .task.firstTask > div > span {
  display: flex;
  align-items: center;
}
.dashboard-tasks .task.firstTask > div svg {
  padding-left: 5px;
}
.dashboard-tasks .task-count {
  margin-left: auto;
  color: var(--ap-color-green1);
  font-weight: 700;
  display: flex;
  align-items: center;
}
.dashboard-tasks .task-count a {
  padding-left: 40px;
}
.dashboard-tasks .task-count a:hover {
  text-decoration: underline;
}
.dashboard-tasks .task-count span.task-percentage:not(:empty) {
  padding-left: 30px;
  min-width: 62px;
  text-align: right;
}
.claims-tasks .task-count span.task-percentage:not(:empty) {
  min-width: 155px;
}
.dashboard-tasks .task-subheader {
  font-weight: 700;
  font-size: var(--ap-font-size-xxs);
  text-transform: uppercase;
  background-color: #fff;
  margin: 0;
  padding: 15px 20px 0px;
}
.dashboard-ap .task.indentLeft > div > span {
  padding-left: 20px;
}
.dashboard-tasks .task pre {
  display: inline-block;
  color: var(--ap-color-black);
  font-size: inherit;
  font-family: inherit;
  font-weight: 400;
  margin: 0;
}
.tasks-separator {
  height: 15px;
  display: block;
  width: 100%;
  background-color: rgba(26, 26, 26, 0.5);
}
.dashboard .dashboard-ap > div.ap-loader-default {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 450px;
}
.percentage-warning {
  color: white;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: var(--ap-font-size-xs);
  font-weight: bold;
}
.percentage-warning svg {
  margin-right: 10px;
  fill: white;
}
