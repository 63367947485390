.action-name-edit {
  display: flex;
  gap: var(--bachedit-line--gap);
}
.action-name-edit__selector {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.action-name-edit__selector label {
  color: var(--ap-color-grey5);
  font-size: var(--ap-font-size-xs);
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.action-name-edit__selector .ap-input-select {
  margin-top: 3px;
}
