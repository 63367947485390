.help-page-nav {
  background-color: #4d4d4d;
  flex-basis: fit-content;
  min-width: 240px;
  max-width: 240px;
}

.help-page-nav .disclosurelinkmenu__panel {
  padding-block: 0;
}

.help-page-nav .disclosurelinkmenu__subitem {
  display: flex;
  align-items: center;
  padding: 16px 32px;
  border: none;
  color: white;
}

.help-page-nav .disclosurelinkmenu,
.help-page-nav > .button-nav {
  margin-bottom: 2px;
  font-size: var(--ap-font-size-s);
  line-height: 20px;
  font-weight: var(--ap-font-weight-m);
}

.help-page-nav .disclosurelinkmenu__subitem:disabled {
  cursor: not-allowed;
  opacity: 0.9;
}
