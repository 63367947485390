.doc-file-link_loader {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--ap-color-ui-green-primary);
}

.doc-file-link_loader .ap-loader-default {
  width: 16px;
  margin-right: 5px;
}
