.scp-input {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #488929;
  border: 0;
  flex-grow: 1;
  width: 100%;
  padding: 0;
}
.scp-input:disabled {
  color: #1a1a1a;
  background-color: #fff;
}
.scp-input:focus {
  outline: none;
}

.scp-input.scp-text-area {
  resize: vertical;
}
.scp-input.withTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input-with-label .datepicker__label {
  pointer-events: none;
}
