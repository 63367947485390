.cell-text-input__container {
  position: relative;
}
.cell-text-input {
  padding: 4px 6px;
}

.cell-text-input {
  text-align: center;
  width: 116px;
  height: 26px;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid transparent;
  background-color: transparent;
  outline: none;
}

.cell-text-input__regenerate {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 4px;
  bottom: 0;

  display: none;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.cell-text-input__icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 4px;
  bottom: 0;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.cell-text-input__icon svg:not(.cell-text-input__loader) {
  display: none;
}

.cell-text-input__container:not(::disabled):hover input {
  border: 1px solid #75ca4e;
  background: #ffffff;
}

.cell-text-input:focus,
.cell-text-input.draft {
  outline: none;
  background: #5cb335 !important;
  color: white;
  font-style: normal;
}
.cell-text-input:focus::placeholder {
  opacity: 0;
}
/* checkmark icon */
.cell-text-input__container:hover .cell-text-input__icon svg,
.cell-text-input__container.editing-empty .cell-text-input__icon,
.cell-text-input__container.draft .cell-text-input__icon {
  display: block !important;
}
.cell-text-input__icon svg {
  height: 16px;
  padding-right: 3px;
}
.cell-text-input__icon path[data-name='outline_check_circle_outline'],
.cell-text-input__icon path[data-name='baseline_close'] {
  fill: #ffffff;
}

/* regenerate icon */
.cell-text-input__container:hover .cell-text-input__regenerate {
  display: block;
}
/* .cell-text-input__container.draft .cell-text-input__regenerate, */
.cell-text-input__container.editing-empty .cell-text-input__regenerate {
  display: none;
}
.cell-text-input__regenerate svg {
  height: 16px;
  padding-right: 3px;
}

.cell-text-input__loader {
  margin-top: 6px;
  margin-right: 10px;
}
