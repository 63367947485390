.sofas-schedules__content-signatories {
  display: grid;
  padding: 1.5rem;
  grid-template-columns: auto;
}

.signatories {
  background-color: var(--ap-bg-surface1);
  padding: 2rem;
  margin: 0 auto;
  max-width: 72rem;
  width: calc(100% - 3 * 1.5rem);
}

.signatories__panel {
  padding: 1rem;
  background-color: var(--ap-color-ui-grey-bg);
}

.signatories__tabs {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.signatories__tabs > a {
  transition: background-color 200ms ease-in-out;
  padding: 0.73em;
  border-radius: 1px;
}

.signatories__tabs > a:focus,
.signatories__tabs > a:hover {
  background-color: var(--ap-color-hover);
}

.signatories__tabs > *:first-child {
  margin-inline-end: auto;
  padding-block-end: 4px;
}

.signatories__tab-active {
  background-color: var(--ap-bg-surface1);
  box-shadow: inset 0px -2px 0px var(--ap-color-ui-green-primary);
}

.signatories-draw {
  background-color: var(--ap-bg-surface1);
  display: flex;
  justify-content: center;
}

.signatories-draw canvas {
  width: var(--canvasW);
  height: var(--canvasH);
}

.signatories-subtle-text {
  color: var(--ap-color-ui-grey-dark);
}

.signatories-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: var(--ap-font-size-s);
  line-height: 1.25;
  color: var(--ap-color-black);
  margin: 0;
}

.signatories-text {
  font-weight: 400;
  font-size: var(--ap-font-size-xxs);
  line-height: 1.09;
  color: var(--ap-color-grey8);
}
