.single-entity__primary-doc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #333;
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5em;
  align-items: center;
}
a.single-entity__primary-doc {
  color: var(--ap-color-ui-green-primary);
}

.single-entity__primary-doc > .ap-loader-default {
  flex: 0;
}

.single-entity__primary-doc > .ap-loader-default > div {
  width: 0.9em;
  height: 0.9em;
}
