.drive-file-anchor {
  cursor: pointer;
  color: var(--ap-color-ui-green-primary);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.drive-file-anchor > svg {
  margin-inline-end: 0.3em;
}

.drive-file-anchor:focus,
.drive-file-anchor:hover {
  text-decoration: underline;
}

