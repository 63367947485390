button.button-main-loader {
  text-transform: none;
  position: relative;
}

.button-main-loader > span {
  display: flex;
  gap: 1em;
  align-items: center;
}
