.sofa-export-progress {
  display: flex;
  gap: 1rem;
  align-items: stretch;
  padding: 16px;
  justify-content: space-between;

  border: 2px solid var(--ap-color-ui-grey-dark);
  border-radius: 4px;
  background-color: var(--ap-color-ui-white);
}

.sofa-export-progress__body {
  flex: 1;
  font-size: var(--ap-font-size-xs);
  align-self: top;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.sofa-export-progress__icon,
.sofa-export-progress__footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sofa-export-progress__progress {
  padding-bottom: 2px;
}
