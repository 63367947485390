.modulepicker-page {
  min-height: calc(100vh - 52px);
  background: url(../../img/wizard_background.jpg) no-repeat center fixed;
  background-size: cover;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.modulepicker {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modulepicker__column {
  flex: 1;
  max-width: 85rem;
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: 3fr 19.75rem;
  column-gap: 2rem;
}

.modulepicker__modules {
  display: grid;
  align-content: flex-start;
  justify-items: stretch;
  align-items: stretch;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-auto-rows: minmax(10.25rem, auto);
}

.modulepicker__tools {
  align-content: flex-start;
  align-self: flex-start;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.2);
}

.modulepicker__tools > h4 {
  color: var(--ap-color-ui-white);
  text-transform: uppercase;
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-s);
  line-height: 1.5;
  margin: 0;
}

.modulepicker__tools-grid {
  align-content: flex-start;
  align-self: flex-start;
  display: grid;
  gap: 2.55rem;
  grid-auto-rows: minmax(7.75rem, auto);
}

.module-wrapper {
  display: grid;
}

.module-wrapper.label-locked {
  display: grid;
}

.module-wrapper-tool > .modulecard {
  --_pad: 1rem;
}
