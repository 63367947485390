.action-status-cell {
  border-radius: 16px;
  height: 20px;
  display: flex;
  padding: 4px 12px;
  border-radius: 16px;
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;

  gap: 8px;
  justify-content: center;
  align-items: center;

  overflow-x: clip;
  flex: 1;
}

.action-status-cell.submatch {
  background: none;
}

/**
all the colours can be found here https://www.figma.com/file/Lo8IUG925ds0nuYnRQhrUS/Claims-Tool-MVP-UI-v1?node-id=150%3A56865
*/
.action-status-cell {
  background: #0696a6;
}
.action-status-cell.scheduled-claim {
  background: #006176;
}
.action-status-cell.filed-claim {
  background: #0696a6;
}
.action-status-cell.matches-found {
  background: #ff600f;
}
.action-status-cell.matched {
  background: #008f0a;
}
.action-status-cell.categorized-claim {
  background: #595959;
}
.action-status-cell.assigned-claim {
  background: #15723b;
}
.action-status-cell.claim-withdrawn {
  background: #ee3a36;
}
.action-status-cell.client-reconciled-claim {
  background: #032e45;
}
.action-status-cell.alix-reconciled-claim {
  background: #5cb335;
}
.action-status-cell.matching-complete {
  background: #008f0a;
}
.action-status-cell.objection {
  background: #f9a12a;
}
.action-status-cell.order-vacated {
  background: #bc1310;
}
.action-status-cell.settlment-pending-claim {
  background: #77b0be;
}
.action-status-cell.settlment {
  background: #77b0be;
}
.action-status-cell.voided-claim {
  background: #bc1310;
}
.action-status-cell.trumped-schedule {
  background: #ff600f;
}
.action-status-cell.zero-value-scheduled-only {
  background: #e6e6e6;
}
.action-status-cell.allowable-allowed {
  background: #e6e6e6;
}

.action-status__icon-container {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-status .label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #1a1a1a;
}

.action-status-cell .match {
  color: #5cb335;
}
.action-status-cell .not-match {
  color: #ee3a36;
}

.action-status-cell.matching div {
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.action-status-cell__value {
  text-overflow: ellipsis;
  overflow: hidden;
}
