.sofas-export-content {
  color: black;
  display: flex;
  gap: 1rem;
}

.sofas-export-type {
  display: flex;
  flex: 0;
  flex-basis: 190px;
}

.sofas-export-type .sofas-export-panel__input-group {
  flex: 1;
}

.sofas-export-type .sofas-export-panel__radio-group {
  flex-direction: column;
  margin: 0 0 32px;
}

.sofas-export-options {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sofas-schedules-export__button {
  margin-top: 1rem;
}

.sofas-export-panel__input-group {
  background-color: var(--ap-color-ui-grey-bg);
  padding: 1rem;
}
.sofas-export-panel__input-group h5 {
  margin: 0;
  margin-block-end: 1rem;
  font-size: var(--ap-font-size-s);
  text-transform: uppercase;
}
.sofas-export-panel__input-group .combobox {
  max-width: 34rem;
  margin-block-start: 1rem;
}
.sofas-export-panel__input-group .combobox__textinput__icon {
  width: 20px;
}

.sofas-export-panel__radio-group {
  display: flex;
  gap: 0.5rem;
}
.sofas-export-panel__radio-group .inputcheckbox {
  padding: 0;
  padding-inline-end: 0.5rem;
  display: inline-block;
}
.sofas-export-panel__radio-group svg.box {
  width: 32px;
  height: 32px;
}
.sofas-export-panel__radio-group .inputcheckbox .box {
  padding-inline: 0 0.25rem;
}

.sofas-schedules-icon-fix {
  position: relative;
  top: 0.2rem;
}

.report-part-select .options-list__menu-item:not(.isSelectable) {
  padding-inline-start: 12px;
}

.report-part-select
  .options-list__menu-item:not(.isSelectable)
  .checkbox-option-item
  > label {
  display: none;
}
