.counterparty-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counterparty-header-subtitle h4 {
  font-size: var(--ap-font-size-l);
  font-weight: var(--ap-font-weight-s);
  line-height: 1.22;
  margin-block-start: 0;
  margin-block-end: 0;
}

.counterparty-header__title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  margin-top: 18px;
}
.counterparty-header__left-pane {
  flex: 2;
}

.counterparty-header__right-pane {
  display: grid;
  row-gap: 1rem;
  column-gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 3;
}

.counterparty-header__right-pane__row {
  display: flex;
  column-gap: 1.5rem;
  row-gap: inherit;
  flex-wrap: wrap;
  justify-content: flex-end;
}
