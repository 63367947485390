button.ap-link-button-secondary {
  color: #488929;
  border: 1px solid #5cb335;
  text-transform: unset;
}

button.ap-link-button-text {
  text-transform: unset;
}

button.ap-link-button-text:hover {
  background: unset;
}
