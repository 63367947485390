.loader-block {
  position: relative;
  width: auto;
  min-height: 64px;
}

.loader-block__shadow {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: #ffffff66;
}

.loader-block__shadow > .ap-loader-default {
  height: min(100%, 100vh);
}
