.slip-sheet-modal .ap-modal-footer {
  /* padding: 32px; */
  max-height: 3em;
  padding-top: 16px;
  padding-top: 0px;
  border: none;
  margin-top: auto;
  display: flex;
}

.slip-sheet-modal .labeled-multiselect {
  padding-inline: 1rem;
  padding-block: 1rem 0;
  background-color: var(--ap-color-ui-grey-bg);
}

.slip-sheet-modal .labeled-multiselect-pad-bump {
  padding-block-end: 1rem;
}

.slip-sheet-modal .file-display {
  padding-block-end: 1rem;
  padding-inline-start: 0rem;
}
