.bookmarks-page__card {
  background-color: #fff;
  width: 100%;
  width: 300px;
  height: 300px;
  border-bottom: 3px solid var(--ap-color-ui-green-primary);
  position: relative;
  display: flex;
  align-items: center;
  transition: all 300ms ease-in;
}

.bookmarks-page__card:hover {
  background-color: var(--ap-color-ui-grey-darker);
  color: #fff;
}

.bookmarks-page__card .text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
  width: 100%;
}

.bookmarks-page__card-title {
  margin: 10px 0;
  color: #fff;
  background-color: var(--ap-color-grey6);
  font-size: var(--ap-font-size-xs);
  padding: 5px;
}

.bookmarks-page__card p {
  font-weight: var(--ap-font-weight-m);
  color: var(--ap-color-ui-grey-dark);
  font-size: var(--ap-font-size-xs);
  line-height: var(--ap-font-size-s);
}
.bookmarks-page__card .summary {
  height: 150px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.bookmarks-page__card .summary h3 {
  font-weight: var(--ap-font-weight-s);
  font-size: var(--ap-font-size-m);
}
.bookmarks-page__card .summary p {
  margin-top: auto;
  margin-bottom: 0;
}
.bookmarks-page__card .buttons-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 32px;
}

.bookmarks-page__card .buttons-wrapper .ap-button-group {
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.bookmarks-page__card .ap-button-group .ap-button-icon {
  position: absolute;
  left: 32px;
  background-color: var(--ap-color-ui-grey-dark);
}
.bookmarks-page__card .ap-button-group .ap-button-icon:hover {
  background-color: var(--ap-color-ui-grey-light);
}
.bookmarks-page__card .ap-button-group .ap-button-icon svg {
  fill: #fff;
}
.bookmarks-page__card .ap-button-group .ap-button-icon + .ap-button-icon {
  right: 32px;
  left: auto;
}
