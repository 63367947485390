.label-locked {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
}

.label-locked > *:first-child {
  opacity: 0.5;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.label-locked__text {
  text-transform: uppercase;
  padding-block-start: 0.75em;
  line-height: 1.46;
  width: calc(100% * 1.414213562 + 2px);
  display: block;
  display: flex;
  font-weight: var(--ap-font-weight-xl);
  justify-content: center;
  color: var(--ap-color-white);
  background-color: var(--ap-color-ui-green-primary);
  transform-origin: 100% 100%;
  transform-origin: 0 0;
  transform: rotate(-45deg) translate(10px, 100%);
  transform: translate(0px, 100%) rotate(-45deg);
}
