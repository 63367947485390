.dropdown-add-category {
  width: 100%;
  position: relative;
}
.dropdown-add-category__options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 40vh;
  overflow-y: auto;
  position: absolute;
  top: 41px;
  background-color: white;
  z-index: 999999;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
}
.dropdown-add-category button,
.dropdown-add-category button span {
  width: 100%;
  text-align: left;
  color: var(--ap-color-grey6);
  text-transform: unset;
}
.dropdown-add-category__options button.selected span {
  display: flex;
  align-items: center;
}
.dropdown-add-category__options button.selected svg {
  margin-left: auto;
}
.dropdown-add-category__options button:hover,
.dropdown-add-category__options button.selected {
  background: var(--ap-color-ui-green-hover);
}
.dropdown-add-category__options-content {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.dropdown-add-category button.dropdown-add-category__selected:hover {
  background: transparent;
}
.dropdown-add-category .dropdown-add-category_add-btn {
  border: none;
  border-radius: 0;
  background: none;
  width: 100%;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
}
.dropdown-add-category .dropdown-add-category_add-btn span {
  width: auto;
  text-align: left;
}
.dropdown-add-category_add-input {
  border: 1px solid transparent;
  width: calc(100% - 22px);
  padding: 12px 10px;
  margin: 1px 0;
}
.dropdown-add-category_add-input:focus,
.dropdown-add-category_add-input:hover {
  outline: none;
  border-color: var(--ap-color-ui-green-primary);
}
