.settlement-single-documents {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.settlement-single-documents__upload {
  padding-bottom: 16px;
}

.settlement-single-documents-grid .table-header-row > th:nth-child(6) {
  text-align: right;
}

.settlement-single-documents-grid__actions {
  display: flex;
  justify-content: flex-end;
}

.icon-loading.settlement-single-documents-grid__item-loading {
  width: 36px;
  height: 36px;
}
