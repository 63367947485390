.sofas-schedules-page {
  --_sofas-schedules-page-h: calc(100vh - 52px - 4rem);
  min-height: var(--_sofas-schedules-page-h);
  flex: 1;

  display: grid;
  grid-template-columns: minmax(15rem, auto) 1fr;
  background: url(../../img/sofa_background.png) no-repeat center fixed;
  background-size: cover;
  color: white;
}

.sofas-schedules__menu {
  background-color: var(--ap-color-grey6);
  overflow-y: auto;
}

.sofas-schedules__heading {
  margin: 0;
  font-weight: 500;
  font-size: var(--ap-font-size-m);
  line-height: 1.5;
}

/* Panel rules */
.sofas-schedules-panel {
  box-sizing: border-box;
  padding: 1.5rem;
  width: clamp(40%, 100%, 1240px);
  margin: 0 auto;
}

.sofas-schedules-panel h4 {
  margin: 0;
  margin-block-end: 2rem;
  font-size: var(--ap-font-size-xl);
}

.sofas-schedules-panel__inner-panel {
  padding: 1rem;
  background-color: var(--ap-bg-surface1);
}

.sofas-schedules-panel__inner-panel .claim-attachments__disappear {
  display: none;
}
