.single-claim-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: var(--_single-claim-row-space);
}

.single-claim-inputs .left-column,
.single-claim-inputs .right-column {
  width: calc(50% - 12px);
  padding-inline: 0;
  display: flex;
  flex-flow: column;
  gap: 8px;
}
.single-claim-inputs .textfields {
  width: 100%;
  padding-block: 0;
  padding-inline: 0;
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.single-claim-inputs.horizontal .single-claim-overview__input-label {
  width: calc(50% - 20px);
}
.single-claim-inputs .single-claim-overview__textfield-label {
  width: 100%;
}

.single-claim-overview__textfield-label textarea {
  font-family: inherit;
}
.single-claim-inputs .single-claim-overview__textfield-label {
  align-items: flex-start;
}

.single-claim-inputs .single-claim-overview__input-label {
  margin-left: 0;
  padding-block: 0;
  padding-inline: 8px 0;
  height: 32px;
}
.single-claim-overview__section .single-claim-inputs a.scp-link-field {
  position: absolute;
  right: 20px;
  width: 20px;
}
.single-claim-overview__section .single-claim-inputs a.scp-link-field:hover {
  outline: none;
}
.single-claim-overview__section
  .single-claim-inputs
  a.scp-link-field:not(.disabled):hover {
  font-weight: 500;
}
a.scp-link-field.disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.single-claim-overview__section .scp-input.withLink {
  max-width: calc(100% - 40px);
}
