.action-list {
  background-color: var(--ap-color-grey2);
  margin-block: 1rem 0rem;
  padding: 1rem;
  list-style: none;
  display: grid;
  row-gap: 1rem;
}

.action-list .claim-attachments__progress {
  outline-offset: -2px;
}

.action-list-item {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background-color: var(--ap-bg-surface1);
}

.action-list-item-newly-added {
  animation: 500ms ease-in anim-fade-green-2;
}

.action-list-item__delete-btn {
  margin-inline: 0.25rem;
}

.action-list-item__actions {
  display: flex;
  flex-wrap: nowrap;
}

.action-list-item__actions svg {
  fill: currentColor;
  color: var(--ap-color-grey7);
}

.action-list-loading button svg {
  color: transparent;
}

.action-list-item__download-btn svg {
  /* fill: var(--ap-color-grey7); */
  width: calc(1.3rem + 1px);
  height: auto;
  margin-inline-start: 0.505rem;
  margin-block-start: 0.5rem;
}

.action-list-item__download-btn {
  margin-inline: 0.25rem;
}

.action-list-item__edit-btn svg {
  width: 22px;
  height: auto;
}

.action-list-item__edit-btn {
  margin-inline: 0.25rem;
}

.action-list-item__actions__divider {
  margin-block: 0.4em;
  border-inline-end: 1px solid var(--ap-color-grey2);
}

.file-display {
  padding-inline-start: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--ap-color-ui-green-primary);
}

.file-display__name {
  font-size: var(--ap-font-size-s);
  font-weight: var(--ap-font-weight-m);
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-display__icon {
  min-width: 18px;
}

.action-list-loading .file-display {
  color: transparent;
}

.action-list-empty-panel {
  font-size: var(--ap-font-size-xs);
  background-color: var(--ap-bg-surface1);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10em;
}
