.anim-appear {
  animation: 300ms ease-in anim-appear;
}

@keyframes anim-appear {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.anim-pulse {
  animation: 1000ms ease-in anim-pulse;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes anim-pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.anim-pulse-075 {
  animation: 1000ms ease-in anim-pulse;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes anim-pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.75;
  }
}

.anim-bg-pulse {
  animation: 1000ms ease-in anim-bg-pulse;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes anim-bg-pulse {
  to {
    background-color: rgb(255, 255, 255, 0.5);
  }
}

.anim-outline-pulse {
  animation: 1000ms ease-in anim-outline-pulse;
  animation-iteration-count: infinite;
}

@keyframes anim-outline-pulse {
  50% {
    outline-color: var(--ap-color-green0);
  }
}

.anim-slide-down {
  animation: 1000ms ease-in anim-slide-down;
  animation-fill-mode: forwards;
}

@keyframes anim-slide-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(20px);
  }
}

.anim-slide-down-off {
  animation: 1000ms ease-in anim-slide-down-off;
  animation-fill-mode: forwards;
}

@keyframes anim-slide-down-off {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
}

.transition-squeeze {
  transition: padding-inline 300ms ease-in-out, margin-inline 300ms ease-in-out;
}

.transition-squeeze.trigger {
  padding-inline: 4px;
  margin-inline: 4px;
}

.transition-expand {
  min-height: 1em;
  margin-block: 0;
  padding-inline: 0px;

  transition: min-height 300ms ease-in-out, margin-block 300ms ease-in-out,
    padding-inline 300ms ease-in-out;
}

.transition-expand.trigger {
  min-height: 4em;
  margin-block: 1.5em;
  padding-inline: 8px;
}

.anim-slide-slightly {
  animation: 300ms ease-in anim-slide-slightly;
  animation-fill-mode: forwards;
}

@keyframes anim-slide-slightly {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-8px);
  }
}

.anim-slide-slightly-undo {
  animation: 300ms ease-in anim-slide-slightly-undo;
  animation-fill-mode: forwards;
}

@keyframes anim-slide-slightly-undo {
  from {
    transform: translateX(-8px);
  }
  to {
    transform: translateX(0);
  }
}

.anim-shrink-slightly {
  animation: 300ms ease-in anim-shrink-slightly;
  animation-fill-mode: forwards;
}

@keyframes anim-shrink-slightly {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1);
  }
}

.anim-fade-green {
  animation: 300ms ease-in anim-fade-green;
  /* animation-fill-mode: forwards; */
}

@keyframes anim-fade-green {
  from {
    background-color: var(--ap-color-ui-green-primary);
  }
  to {
    background-color: var(--ap-bg-surface1);
  }
}

.anim-fade-green-2 {
  animation: 500ms ease-in anim-fade-green-2;
}

@keyframes anim-fade-green-2 {
  from {
    background-color: var(--ap-color-ui-green-primary);
    opacity: 0.5;
  }
  to {
    background-color: var(--ap-bg-surface1);
  }
}

.anim-spin {
  transform-origin: center;
  animation: anim-spin 750ms infinite linear;
}
@keyframes anim-spin {
  100% {
    transform: rotate(360deg);
  }
}
