.claims-reports__generate-button {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.claims-reports .claims-reports__generate-button > .ap-button-main {
  height: 2rem;
  min-width: 9rem;
  text-transform: capitalize;
}
.claims-reports__generate-button > .ap-button-main > span {
  display: flex;
  align-items: center;
}
.claims-reports__generate-button > .ap-button-main .ap-loader-default {
  margin-left: 10px;
}
.claims-reports__generate-button > .ap-button-main .ap-loader-default > div {
  border: 2px solid var(--ap-color-green2);
  border-top-color: white;
}
