.settings-panel-help {
  font-size: var(--ap-font-size-xs);
  line-height: 24px;
  box-shadow: 0px 16px 25px -5px #0000004a;

  background-color: var(--ap-color-ui-grey-bg);
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.settings-panel-help__title {
  line-height: 1.5rem;
  display: flex;
  font-size: var(--ap-font-size-m);
  margin-block: 0 1.5rem;
  color: var(--ap-color-grey8);
  margin-bottom: unset;
}

.settings-panel-help__body {
  flex: 1;
}
