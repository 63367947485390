.settlement-amount-grid__row.settlement-total-row {
  font-weight: var(--ap-font-weight-xl);
}

.settlement-amount-grid__row > td:first-child {
  vertical-align: top;
  font-weight: var(--ap-font-weight-xl);
}

.settlement-amount-grid__cell-double-value {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.settlement-amount-grid__cell-double-value > span {
  min-height: 1em;
}

.settlement-amount-grid.claim-document-table {
  min-height: 6em;
}

.settlement-amount-grid.claim-document-table > table {
  border: unset;
}

.settlement-amount-grid .table-header-row > th:nth-child(3),
.settlement-amount-grid .table-header-row > th:nth-child(4),
.settlement-amount-grid .table-header-row > th:nth-child(5),
.settlement-amount-grid .table-header-row > th:nth-child(6),
.settlement-amount-grid .table-header-row > th:nth-child(7) {
  text-align: right;
}

.settlement-amount-grid__cell-right-aligned > span {
  text-align: right;
}
