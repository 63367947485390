.single-input-select {
  color: var(--ap-color-green2);
  text-indent: 0;
  font-weight: 400;
  font-size: 13px;
  border: 0;

  outline-style: solid;
  outline-width: 1px;
  outline-offset: -1px;
  outline-color: transparent;
  transition: outline-color 200ms ease-in-out;
}

select.single-input-select:hover,
select.single-input-select:focus {
  outline-color: var(--ap-color-ui-green-primary);
}

select.single-input-select:disabled {
  outline-color: transparent;
  color: var(--ap-color-grey8);
  opacity: 1;
}

.single-input-select:active > option {
  color: var(--ap-color-ui-green-primary);
  background-color: white;
}
