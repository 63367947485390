.singlepage-base {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.singlepage-base__content {
  flex: 1;
  background: url(../../img/wizard_background.jpg) no-repeat center fixed;
  background-size: cover;
  padding: 16px 32px 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 52px);
}

.singlepage-base__bg-overlay {
  position: absolute;
  opacity: 0.15;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: var(--client-secondary-brand-color);
}

.singlepage-base__background,
.singlepage-base__footer,
.singlepage-base__nav {
  z-index: 10;
}
